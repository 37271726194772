import React, { useState, useEffect, Fragment} from 'react';
import { useNavigate, useLocation, Navigate, useOutletContext   } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faPlusCircle, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import Prompt from "../components/Prompt";
import Loader from "../components/Loader";
import SupplierOrderFormFields from "../components/SupplierOrderFormFields";
import SubjectActivities from "../components/SubjectActivities";
import FormControlHelper from "../components/FormControlHelper";

import RegisterSupplierOrderExpenseTransactionModal from "../components/RegisterSupplierOrderExpenseTransactionModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ShowDialogModal from "../components/ShowDialogModal";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import SupplierOrderConfig from "../config/SupplierOrderConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveSupplierOrderValidationSchema = Yup.object().shape({
	...SupplierOrderConfig.FormValidationRules
});



const SaveSupplierOrder = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [supplierOrder, setSupplierOrder] = useState(null);
	const [showConfirmExpenseUnregistrationModal, setShowConfirmExpenseUnregistrationModal] = useState(false);
	const [expenseTransaction, setExpenseTransaction] = useState(null);	
	const [showExpenseTransactionModal, setShowExpenseTransactionModal] = useState(false);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	
	const { t } = useTranslation();
	const location = useLocation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
	
	const config = useConfig();
	
	
	const lookupSupplierOrder = (id) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
			
		API.lookupSupplierOrder(id).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	

	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/supplier-orders/update' && location.state && location.state.item) {
			lookupSupplierOrder(location.state.item.id)
			.then(response => {
					setSupplierOrder(response);
			})
			.catch(error => { 
				log.error("Error Loading Supplier Order: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		
		return () => { isMounted = false };
		  
	}, [location.state]);

	if (location.pathname === '/supplier-orders/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate to="/supplier-orders" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!supplierOrder) {
			return null;
		}
	}
	
			
	let initialValues = {}, readOnlyFields = {}, action, actionIcon, validationSchema; //, registered = false;
	
	let defaultValues = saveSupplierOrderValidationSchema.default();
	
	validationSchema = saveSupplierOrderValidationSchema;
	
	if (location.pathname === '/supplier-orders/update') {
		Object.keys(SupplierOrderConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (supplierOrder[key]) ? supplierOrder[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//registered = !Object.is(supplierOrder.transactionId, null);
		
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
		initialValues.freightCharge = "0.00";
		initialValues.otherCharges = "0.00";
		initialValues.totalAmount = "0.00";
	}
	
	
	const saveSupplierOrder = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateSupplierOrder(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createSupplierOrder(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	const confirmSupplierOrderExpenseUnregistration = () => {
	  setShowConfirmExpenseUnregistrationModal(true);
  	}
  
		
	const unregisterSupplierOrderExpenseTransaction = () => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setIsLoading(true);
		
		API.deleteLedgerTransaction(supplierOrder.transactionId, config.csrfToken).then(response => {
			
			//Now, let´s refresh supplier order
	  		lookupSupplierOrder(supplierOrder.id)
				.then(response => {
					setSupplierOrder(response);
					context.onSuccess(t('supplier-orders.unregister-expense-confirmation-modal.success'));
			})
			.catch(error => { 
				log.error("Error Refreshing Supplier Order Expense Transaction: ", error.message);
		 		context.onError(error);
			});
		 	
		}).catch(error => { 	
			log.error("Error Unregistering Supplier Order Expense Transaction: ", error.message);
			context.onError(error);		
		}).finally(() => {
			setIsLoading(false);
			setShowConfirmExpenseUnregistrationModal(false);
		});
  }

  const registerSupplierOrderExpenseTransaction = (isDirty) => {
	  
	  //Check first if form has been changed
	  if (!isDirty) {
		  
		   let transaction = {};
	  	   transaction.supplierOrderId = supplierOrder.id;
	  	   transaction.eventDate = supplierOrder.eventDate;
	  	   transaction.supplierDisplayName = supplierOrder.supplierDisplayName;
	  	   
	  	   let totalAmount = parseFloat(supplierOrder.totalAmount);
	  	   if (totalAmount >= 0) {
	  	   		transaction.ledgerNo = "700";//CASH - ASSET Decrease = CREDIT
	  	   		transaction.ledgerDrNo = "500"; //EXPENSE Increase = DEBIT
	  	   		transaction.amount = totalAmount.toFixed(2);
	  	   } else {
				//If totalAmount is negative, we must invert ledgers
				transaction.ledgerNo = "500"; //EXPENSE Decrease = CREDIT
				transaction.ledgerDrNo = "700";//CASH - ASSET Increase = DEBIT
	  	   		transaction.amount = Math.abs(totalAmount).toFixed(2);
		   }
		   
	       
	       setExpenseTransaction(transaction);
	       setShowExpenseTransactionModal(true);
	   } else {
		  setShowUnsavedChangesModal(true);
	   }
  }
  
   const handleExpenseTransactionRegistered = (success) => {
	  setShowExpenseTransactionModal(false);
	  
	  //Now, let´s refresh supplier order
	  lookupSupplierOrder(supplierOrder.id)
		.then(response => {
			setSupplierOrder(response);
			 context.onSuccess(success);
		})
		.catch(error => { 
			log.error("Error Refreshing Supplier Order Expense Transaction: ", error.message);
		 		context.onError(error);
			});
   }
   
   const isRegistered = () => {
	   return (supplierOrder != null && !Object.is(supplierOrder.transactionId, null))
   }
   	
	
	return (
		<Container>
			{(showConfirmExpenseUnregistrationModal) && <ConfirmationDialogModal
				item={supplierOrder}
				show={setShowConfirmExpenseUnregistrationModal}
        		onHide={() => setShowConfirmExpenseUnregistrationModal(false)}
				size="lg"
				title={t("supplier-orders.unregister-expense-confirmation-modal.title")}
				bodyText={t("supplier-orders.unregister-expense-confirmation-modal.body", {item: supplierOrder})}
				confirmText={t("supplier-orders.unregister-expense-confirmation-modal.confirm")}
				cancelText={t("supplier-orders.unregister-expense-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={unregisterSupplierOrderExpenseTransaction}
			/>}
			
			{(showExpenseTransactionModal) &&
				<RegisterSupplierOrderExpenseTransactionModal 
					show={showExpenseTransactionModal} 
					onHide={() => {setShowExpenseTransactionModal(false);}}
					size={"lg"}
					origin="save-supplier-orders"
					item={expenseTransaction}
					onError={context.onError}
					onItemSaved={handleExpenseTransactionRegistered}
				/>
			}
			
			{(showUnsavedChangesModal) && <ShowDialogModal
				item={supplierOrder}
				show={showUnsavedChangesModal}
        		onHide={()=>{ setShowUnsavedChangesModal(false);}}
				size="lg"
				title={t("supplier-orders.unsaved-changes-modal.title")}
				bodyText={t("supplier-orders.unsaved-changes-modal.body", {item: supplierOrder})}
				closeText={t("supplier-orders.unsaved-changes-modal.close")}
				variant="warning"
			/>}
			
			<Row className="mb-3">
        		<Col md="auto">
        			<h3>
        				<Trans i18nKey={"supplier-orders.save.header-"+action}>Save Suplier Order</Trans>
        			</h3>
        			
        		</Col>
        		{ supplierOrder ?
        				<Col>
        					<h5><Badge pill variant={isRegistered() ? "info" : "danger"}><Trans i18nKey={"supplier-orders.save.form.status.options."+ isRegistered()}>Status</Trans></Badge> {isRegistered() ? <FormControlHelper text={"supplier-orders.save.form.status.helper"}/> : null }</h5>
        				</Col>
        			: null
        		}
        	</Row>
        	<Row className="mb-3">
    			<Col>	      
    				<Formik
						initialValues={initialValues}
		      			validationSchema={validationSchema}
		      			validateOnChange={false}
		      			validateOnBlur={false}     
		      			onSubmit={(values, actions) => {
		      			
		      				//Clear Error
		      				_setError(null);

							saveSupplierOrder(values)
    						.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
									navigate("/supplier-orders", { state: { success: t("supplier-orders.save.success")}});
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof ValidationError) {		
          						
				 					log.info("Save Supplier Order Attempt Failed: ", error.message);
          						
				 					context.onError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Supplier Order: ", error.message);
          	 					}
          	 					
          	 					context.onError(error);
    								
    						})
    			   		}}
		      		>
		      		{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, dirty}) => (	
		      		
		      			<Form className="form-save-supplier-order-modal" noValidate>		
		  				
  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>
		      				
		      				

							<SupplierOrderFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={context.onError}
									onSuccess={context.onSuccess}
									onWarning={context.onWarning}
									onInfo={context.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={action}
									readOnlyFields={readOnlyFields}
									i18nPrefix="supplier-orders.save."
									registered={isRegistered()}
							/>

							<Row className="mt-3">    					   
    							<Col>
    								<Button variant="success" type="submit" disabled={isSubmitting || isRegistered() }> 
    									{isSubmitting ? <Trans i18nKey="supplier-orders.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"supplier-orders.save.form.submit-"+action}>Submit</Trans></span>} 
    								</Button>
    								
    								{(location.pathname === '/supplier-orders/update') ?
    									(isRegistered()) ?
    										<Button variant="danger" className="ml-3" onClick={confirmSupplierOrderExpenseUnregistration}><FontAwesomeIcon icon={faFileInvoiceDollar} /> <Trans i18nKey="supplier-orders.save.unregister-expense">Unregister Expense</Trans></Button> 	
    										:
    										<Button variant="info" className="ml-3" onClick={() => { registerSupplierOrderExpenseTransaction(dirty);}}><FontAwesomeIcon icon={faFileInvoiceDollar} /> <Trans i18nKey="supplier-orders.save.register-expense">Register Expense</Trans></Button> 	
    									: null
    								}

    								<Button variant="secondary" className="float-right" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="supplier-orders.save.cancel">Cancel</Trans></Button>
    					   		</Col>
    			    		</Row>   
	    			    
		      			</Form>	
						)}
					</Formik>
		        </Col>
	        </Row>
	        
			{(location.pathname === '/supplier-orders/update') && (
				<SubjectActivities 
		 			subject="SUPPLIER_ORDER" 
		 			subjectId={location.state.item.id} 
		 			onError={context.onError}
		 		/>
			)}
	     </Container>

	);
}

export default SaveSupplierOrder;
