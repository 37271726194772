import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

import AccountInputSearch from "../components/AccountInputSearch";

import { useConfig } from "../services/use-config";

import { currencySymbol} from "../helpers/commons";


const ProductSupplierFormFields = props => {

	const { t } = useTranslation();
	const config = useConfig();

	const handleSupplierChange = (item) => {
		
        if (!item) {
			props.setFieldValue("accountNo", "");
        	return;
        } 

		props.setFieldValue("accountNo", item.accountNo);

    }
    
	return(
		<Fragment>	
			<Row>
				<FormGroup as={Col} controlId="formGridAccount">
				   <FormLabel><Trans i18nKey={props.i18nPrefix+"form.accountNo.label"}>Select Supplier</Trans> *</FormLabel>
					{props.readOnlyFields["accountNo"] ? 
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl type={'text'} readOnly name="supplierDisplayName" value={props.values.supplierDisplayName} />
						</InputGroup>
					:	
						<AccountInputSearch 
				    		i18nPrefix={props.i18nPrefix+"form."}
				    		onError={props.onError}
				    		isInvalid={!(props.errors.accountNo == null)}
				    		onChange={handleSupplierChange}
							accountNo={props.values.accountNo}
							disabled={props.readOnlyFields["accountNo"]}
				    	/>
					}
					<FormControlErrors block={true} errors={props.errors.accountNo} />
				</FormGroup>
			</Row>
							
			<Row>
				<FormGroup as={Col} md={4} controlId="formGridUnitPrice">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.unitPrice.label"}>Unit Price</Trans> * <FormControlHelper text={props.i18nPrefix+"form.unitPrice.helper"}/></FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					</InputGroup.Prepend>
						<FormControl type={'text'} name="unitPrice" isInvalid={!(props.errors.unitPrice == null)} value={props.values.unitPrice} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.unitPrice} />
					</InputGroup>
				</FormGroup>
			</Row>
		</Fragment>
		
	) 
}


export default ProductSupplierFormFields;
