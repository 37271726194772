import React,  { Fragment, useState, useEffect, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek, faSpinner, faHourglassHalf} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
//import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
//import Popover from 'react-bootstrap/Popover'

//import Tooltip from 'react-bootstrap/Tooltip'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
import moment from "moment";

import InventoryCountsRangePickerModal from "../components/InventoryCountsRangePickerModal";

import { useConfig } from "../services/use-config";

/*import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';*/


const toolbarDisplayModes = [
	{
        key: 'periodic',
        icon: faHourglassHalf
      },
		{
        key: 'continuous',
        icon: faSpinner
      }
];

const InventoryCountsSummaryToolbar = props => {
	
	const [showInventoryCountsRangePickerModal, setShowInventoryCountsRangePickerModal] = useState(false);
	
	const config = useConfig();
	const { t } = useTranslation();
	
	const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );
    
    
    const handleInventoryCountsRangePickerChange = (initialCount, finalCount) => {
    	setShowInventoryCountsRangePickerModal(false);
    	props.onChange(initialCount, finalCount);
  	};

	const handleInventoryCountsRangePickerModalHide = (e) => {
    	setShowInventoryCountsRangePickerModal(false);
  	};
	
	return(
		<Fragment>
			{ (showInventoryCountsRangePickerModal) ?
				<InventoryCountsRangePickerModal
					show={showInventoryCountsRangePickerModal}
        			onHide={handleInventoryCountsRangePickerModalHide}
					format="DD/MM/YYYY"
					inventoryCounts={props.inventoryCounts}
					initialCount={props.initialCount} 
					finalCount={props.finalCount}
					onChange={handleInventoryCountsRangePickerChange} 
					locale={config.preferredLanguage}
					i18nPrefix={props.i18nPrefix+"inventory-count-range-picker."}
					size="lg"
				/>
				: null
			}
			<Row className="mb-3">
				<Col className="text-center">
					{(props.displayMode === "periodic") ?
					<Button variant={props.variant} onClick={()=> {setShowInventoryCountsRangePickerModal(!showInventoryCountsRangePickerModal); }}>
						<Trans i18nKey={props.i18nPrefix+"inventory-count-range"} >Inventory Counts Range</Trans> <Moment local format="DD/MMM/YYYY">{props.initialCount.eventDate}</Moment> [<Trans i18nKey={props.i18nPrefix+"eventTime." + props.initialCount.eventTime} />] <Trans i18nKey={props.i18nPrefix+"to"} >to</Trans> <Moment local format="DD/MMM/YYYY">{props.finalCount.eventDate}</Moment>  [<Trans i18nKey={props.i18nPrefix+"eventTime." + props.finalCount.eventTime} />]
					</Button>
					:
					<Button variant={props.variant} disabled>
						<Trans i18nKey={props.i18nPrefix+"inventory-count-range"} >Inventory Counts Range</Trans> <Moment local format="DD/MMM/YYYY">{props.initialCount.eventDate}</Moment>  [<Trans i18nKey={props.i18nPrefix+"eventTime." + props.initialCount.eventTime} />] <Trans i18nKey={props.i18nPrefix+"to"} >to</Trans> <Moment local format="DD/MMM/YYYY HH:mm">{moment()}</Moment>
					</Button>
					}
				</Col>
				<Col md={"auto"}>
					<ButtonToolbar className="float-right">
						<ToggleButtonGroup className="mr-2" type="radio" name="displayMode" value={props.displayMode} onChange={props.onDisplayModeChange}>
							{toolbarDisplayModes.map((item, index) => (
          						<ToggleButton
            							key={index}
										variant={props.variant}  
            							value={item.key}
          							>
            							<OverlayTrigger overlay={renderTooltip(t(props.i18nPrefix+item.key+"-tooltip"))}>
											<FontAwesomeIcon icon={item.icon} />
										</OverlayTrigger>
         				 			</ToggleButton>
        					))}	
						</ToggleButtonGroup>
					</ButtonToolbar>
				</Col>
			</Row>
		</Fragment>	
	);

}

export default InventoryCountsSummaryToolbar;
