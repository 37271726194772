import * as Yup from "yup";
import moment from "moment";
import { Regex } from '../helpers/commons'

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

const OrderTypes = [
	"COUNTER",
	"SHEET",
	"OTHER"
]

const FormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	accountNo: Yup.string()
		.required()
		.default(() => ""),
	customerDisplayName: Yup.string()
		.default(() => ""),
	productId: Yup.string()
		.required()
		.default(() => ""),
	orderType: Yup.string()
		.oneOf([""].concat(OrderTypes), 'form.validation.string.enum')
		.required()
   		.default(() => "SHEET"),
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'orders.save.form.eventDate.validation.format'})
		.default(() => ""),
	//eventDate: Yup.date()
	//	.typeError('orders.save.form.eventDate.validation.format')
	//	.max(new Date(), "orders.save.form.eventDate.validation.future")
	//	.required()
	//	.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	unitPrice: Yup.string()
		.matches(Regex.CURRENCY, "orders.save.form.unitPrice.validation.format")
   		.default(() => ""),
	quantity: Yup.number()
			.typeError('orders.save.form.quantity.validation.format')
			.min(1, "orders.save.form.quantity.validation.min")
			.required()
			.default(() => 1),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
			
}


const FilterFormValidationRules = {
	accountNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "eventDate"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	products: Yup.array()
		.default(() => []),
	
}

const AdvancedFilterFormFields = {
	filter: true,
	products : true
}

const ExportHeaders =[
	"id", "eventDate", "accountNo", "customerDisplayName", "product", "unitPrice", "quantity", "subTotal", "status", "whenModified", "whenCreated"
]

const OrderConfig = { OrderTypes, FormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields, 
				ExportHeaders
		};

export default OrderConfig;