import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import { useLocation, useNavigate } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import API from "../services/backend-api";
import LedgerConfig from "../config/LedgerConfig";
import * as Yup from "yup";


import InputMask from "react-input-mask";


import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import { useConfig } from "../services/use-config";



const validationSchema = Yup.object().shape(LedgerConfig.FormValidationRules);

const LedgerFormFields = props => {
	
	const { t } = useTranslation();
	const target = useRef(null);
	const [ledgerNoVerified, setLedgerNoVerified] = useState(false);
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		 return () => { isMounted = false };
		  
	}, []);

	const renderTooltip = (text) => (
   	 	<Tooltip id="button-tooltip" >
      		<Trans i18nKey={text} />
    	</Tooltip>
  	);

	const handleAccountTypeChange = (e) => {
        
        props.setFieldValue("accountType", e.target.value);
               
        //TODO: Add explanation
    }
    
	return(
		<Fragment>
			
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridLedgerNo">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.ledgerNo.label"}>Ledger Number</Trans> *</FormLabel>
				    {(props.action === "update") ?
						<FormControl type={'text'} readOnly disabled name="ledgerNo" value={props.values.ledgerNo}/>
					:
					<InputGroup>
						<FormControl 
							as={InputMask} 
							mask='999' 
							type={'text'} 
							name="ledgerNo" 
							isInvalid={!(props.errors.ledgerNo == null)} 
							isValid={ledgerNoVerified && !props.errors.ledgerNo}
							value={props.values.ledgerNo} 
							onChange={props.onChange} 
							placeholder={t(props.i18nPrefix+"form.ledgerNo.placeholder")} 
						/>
				    	<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip(props.i18nPrefix+"form.ledgerNo.validate-tooltip")}
    							>
									<Button variant="secondary" onClick={() => {
										
										setLedgerNoVerified(false);
										props.setFieldError("ledgerNo",null);
										Yup.reach(validationSchema, 'ledgerNo').validate(props.values.ledgerNo)
											.then((e) => {
												API.lookupLedger(props.values.ledgerNo)
													.then(response => {
														props.setFieldError("ledgerNo", t(props.i18nPrefix+"form.ledgerNo.validation.already-registered"));
													}).catch(error => {
														
														if (error.code === "ledgers-02") {
															setLedgerNoVerified(true);
														} else {
															log.error("Error Looking up Ledger: ", error.message);
															props.onError(error);
														}
												});	
											})
											.catch(error => {
												props.setFieldError("ledgerNo", error.errors);
											});	
										
									 }}> 
										<FontAwesomeIcon icon={faCheckCircle} />
									</Button>	
							</OverlayTrigger>
						</InputGroup.Append>
						<FormControlErrors errors={props.errors.ledgerNo} />
						<FormControl.Feedback><Trans i18nKey={props.i18nPrefix+"form.ledgerNo.feedback.available"}>Ledger Number Available</Trans></FormControl.Feedback>
					</InputGroup>
				}
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridAccountType">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.accountType.label"}>Account Type</Trans> *</FormLabel>
				    <FormControl as="select" name="accountType" isInvalid={!(props.errors.accountType == null)} value={props.values.accountType} onChange={handleAccountTypeChange} >
		    			<option value="">{t(props.i18nPrefix+"form.accountType.options.blank")}</option>
		    			{ LedgerConfig.AccountTypes.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.accountType.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.accountType} />
				</FormGroup>
				<FormGroup as={Col} md={6} controlId="formGridName">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.name.label"}>Name</Trans> *</FormLabel>
				    <FormControl type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.name.placeholder")} />
				    <FormControlErrors errors={props.errors.name} />
				</FormGroup>
			</Row>
			
			
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>

   				<FormGroup as={Col} md={{ span: 1, offset: 1 }} controlId="formGridActive">
	    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.active.label"}>Active</Trans></FormLabel>
	    				<Switch 
	    					name="active"
	    					value={props.values.active}
	    					checked={JSON.parse(props.values.active)}
	    					onChange={props.onChange}
	    				/>
	    		</FormGroup>
	    	</Row>
				
		</Fragment>
		
	) 
}


export default LedgerFormFields;
