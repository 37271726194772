import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import { useLocation, useNavigate } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import API from "../services/backend-api";
import AccountConfig from "../config/AccountConfig";
import * as Yup from "yup";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import InputMask from "react-input-mask";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";


import { parseInputDate } from "../helpers/commons";


const validationSchema = Yup.object().shape(AccountConfig.FormValidationRules);

const AccountFormFields = props => {
	
	const { t } = useTranslation();
	const target = useRef(null);
	const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
	const [accountNoVerified, setAccountNoVerified] = useState(false);
	
	const config = useConfig();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const buildAccountPageUrl = (token) => {

		return window.location.protocol + "//" + window.location.host + "/account/?token=" + token

  	};

	const renderTooltip = (text) => (
   	 	<Tooltip id="button-tooltip" >
      		<Trans i18nKey={text} />
    	</Tooltip>
  	);

	const handleAccessTokenCopied = () => {
		setShowCopiedTooltip(true); 
		setTimeout(() => {setShowCopiedTooltip(false);}, 3000);		
	} 
	
	const handleEntityTypeChange = (e) => {
        
        props.setFieldValue("entityType", e.target.value);
               
        //Reset documentId
        props.setFieldValue("documentId", "");
    }
    
	return(
		<Fragment>
			
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridAccountNo">
				    <FormLabel><Trans i18nKey="accounts.save.form.accountNo.label">Account Number</Trans> *</FormLabel>
				    {(props.action === "update") ?
						<FormControl type={'text'} readOnly disabled name="accountNo" value={props.values.accountNo}/>
					:
					<InputGroup>
						<FormControl 
							as={InputMask} 
							mask='9999' 
							type={'text'} 
							name="accountNo" 
							isInvalid={!(props.errors.accountNo == null)} 
							isValid={accountNoVerified && !props.errors.accountNo}
							value={props.values.accountNo} 
							onChange={props.onChange} 
							placeholder={t('accounts.save.form.accountNo.placeholder')} 
						/>
				    	<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip("accounts.save.form.accountNo.validate-tooltip")}
    							>
									<Button variant="secondary" onClick={() => {
										
										setAccountNoVerified(false);
										props.setFieldError("accountNo",null);
										Yup.reach(validationSchema, 'accountNo').validate(props.values.accountNo)
											.then((e) => {
												API.lookupAccountView(props.values.accountNo)
													.then(response => {
														props.setFieldError("accountNo", t("accounts.save.form.accountNo.validation.already-registered"));
													}).catch(error => {
														
														if (error.code === "accounts-02") {
															setAccountNoVerified(true);
														} else {
															log.error("Error Looking up Account: ", error.message);
															props.onError(error);
														}
												});	
											})
											.catch(error => {
												props.setFieldError("accountNo", error.errors);
											});	
										
									 }}> 
										<FontAwesomeIcon icon={faCheckCircle} />
									</Button>	
							</OverlayTrigger>
						</InputGroup.Append>
						<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip("accounts.save.form.accountNo.generate-tooltip")}
    							>
									<Button variant="secondary" onClick={() => {
										
										setAccountNoVerified(false);
										props.setFieldError("accountNo",null);
										
										API.getNextAccountNo(config.csrfToken)
											.then(response => {
												props.setFieldValue("accountNo", response.accountNo);
											}).catch(error => {
												log.error("Error Looking up Next Account No. Available: ", error.message);
												props.onError(error);
											});	
										
									 }}> 
										<FontAwesomeIcon icon={faSearch} />
									</Button>	
							</OverlayTrigger>
						</InputGroup.Append>
						<FormControlErrors errors={props.errors.accountNo} />
						<FormControl.Feedback><Trans i18nKey="accounts.save.form.accountNo.feedback.available">Account Number Available</Trans></FormControl.Feedback>
					</InputGroup>
				}
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridEntityType">
					<FormLabel><Trans i18nKey="accounts.save.form.entityType.label">Entity Type</Trans> *</FormLabel>
				    <FormControl as="select" name="entityType" isInvalid={!(props.errors.entityType == null)} value={props.values.entityType} onChange={handleEntityTypeChange} >
		    			{ AccountConfig.EntityTypes.map(item =>
		    				<option key={item} value={item}>{t("accounts.save.form.entityType.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.entityType} />
				</FormGroup>
				<FormGroup as={Col} md={6} controlId="formGridDisplayName">
					<FormLabel><Trans i18nKey="accounts.save.form.displayName.label">Display Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="displayName" isInvalid={!(props.errors.displayName == null)} value={props.values.displayName} onChange={props.onChange} placeholder={t('accounts.save.form.displayName.placeholder')} />
				    <FormControlErrors errors={props.errors.displayName} />
				</FormGroup>
			</Row>
			
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridFirstName">
					<FormLabel><Trans i18nKey="accounts.save.form.firstName.label">First Name</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="firstName" isInvalid={!(props.errors.firstName == null)} value={props.values.firstName} onChange={props.onChange} placeholder={t('accounts.save.form.firstName.placeholder')} />
				    <FormControlErrors errors={props.errors.firstName} />
				</FormGroup>
				<FormGroup as={Col} md={5} controlId="formGridLastName">
				    <FormLabel><Trans i18nKey="accounts.save.form.lastName.label">Last Name</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="lastName" isInvalid={!(props.errors.lastName == null)} value={props.values.lastName} onChange={props.onChange} placeholder={t('accounts.save.form.lastName.placeholder')} />
				    <FormControlErrors errors={props.errors.lastName} />
				</FormGroup>
				<FormGroup as={Col} md={4} controlId="formGridMobilePhone">
			    	<FormLabel ><Trans i18nKey="accounts.save.form.mobilePhone.label">Mobile Phone</Trans></FormLabel>
					<PhoneInput
  						placeholder={t('accounts.save.form.mobilePhone.placeholder')}
  						value={props.values.mobilePhone}
  						onChange={(value) => {props.setFieldValue("mobilePhone", value);}}
  						isInvalid={!(props.errors.mobilePhone == null)}
  						defaultCountry="BR"
  						international
  						countryCallingCodeEditable={false}
  						name="mobilePhone"
  						inputComponent={FormControl}
  					/>
			    	{!(props.errors.mobilePhone == null) 
		          		? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.mobilePhone} /></div>
						: null
					}	
			    </FormGroup>
			</Row> 		
								
			<Row>
				<FormGroup as={Col} md={6} controlId="formGrid-XPTO-1">
				    <FormLabel><Trans i18nKey="accounts.save.form.email.label">Email</Trans></FormLabel>
				    <FormControl className="text-lowercase" type={'text'} name="email" isInvalid={!(props.errors.email == null)} value={props.values.email} onChange={props.onChange} placeholder={t('accounts.save.form.email.placeholder')} />
				    <FormControlErrors errors={props.errors.email} />
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridDocumentId">
				    <FormLabel><Trans i18nKey="accounts.save.form.documentId.label">DocumentId</Trans></FormLabel>
				    <FormControl as={InputMask} disabled={!props.values.entityType} mask={props.values.entityType ? AccountConfig.DocumentIdMasks[props.values.entityType] : ""} type={'text'} name="documentId"  isInvalid={!(props.errors.documentId == null)} value={props.values.documentId} onChange={props.onChange} placeholder={t('accounts.save.form.documentId.placeholder')} />
				    <FormControlErrors errors={props.errors.documentId} />
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridBirthDate">
					<FormLabel><Trans i18nKey={"accounts.save.form.birthDate.label"}>Birth Date</Trans> </FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.birthDate == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("birthDate", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("birthDate", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("birthDate", "");
      						}
      					}}
      					date={props.values.birthDate}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.birthDate} />
				</FormGroup>			
			</Row>	
			
			<Row>
				
				<FormGroup as={Col} md={3} controlId="formGridCustomerSince">
					<FormLabel><Trans i18nKey={"accounts.save.form.customerSince.label"}>Customer Since</Trans> </FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.customerSince == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("customerSince", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("customerSince", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("customerSince", "");
      						}
      					}}
      					date={props.values.customerSince}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.customerSince} />
				</FormGroup>
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey="accounts.save.form.remarks.label">Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t('accounts.save.form.remarks.placeholder')} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>
			
			{(props.action === 'update' && auth.isAdmin()) ?
			<Row>
				<FormGroup as={Col} controlId="formGridAccountPageUrl">
	    			<FormLabel ><Trans i18nKey="accounts.save.form.accountPageUrl.label">Account Page URL</Trans></FormLabel>
	    			<InputGroup>
						<FormControl type={'text'} readOnly disabled name="accountPageUrl" value={buildAccountPageUrl(props.values.accessToken)} />
						<InputGroup.Append>
							<OverlayTrigger
									show={showCopiedTooltip}
									overlay={renderTooltip("accounts.save.form.accountPageUrl.tooltip.copied")}
    							>
								<CopyToClipboard 
									text={buildAccountPageUrl(props.values.accessToken)} 
									onCopy={handleAccessTokenCopied}
								>
									<Button variant="secondary" ref={target} > 
										<FontAwesomeIcon icon={faCopy} /> <Trans i18nKey={"accounts.save.form.copy-account-page-url"}>Copy URL</Trans>
									</Button>	
								</CopyToClipboard>
							</OverlayTrigger>
						</InputGroup.Append>
					</InputGroup>
				</FormGroup>
						
   				<FormGroup as={Col} md={{ span: 1, offset: 1 }} controlId="formGridActive">
	    				<FormLabel ><Trans i18nKey="accounts.save.form.active.label">Active</Trans></FormLabel>
	    				<Switch 
	    					name="active"
	    					value={props.values.active}
	    					checked={JSON.parse(props.values.active)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
				: null
			 }
				
				
		
		</Fragment>
		
	) 
}


export default AccountFormFields;
