import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import SearchUserModal from "../components/SearchUserModal";
import FormControlErrors from "../components/FormControlErrors";

import moment from "moment";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import { ValidationError } from "../helpers/custom-errors";

import ActivityConfig from "../config/ActivityConfig";

import { useConfig } from "../services/use-config";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape(ActivityConfig.FilterFormValidationRules);

const ActivityFilterForm = props => {
	
	const [searchUserModalShow, setSearchUserModalShow] = useState(false);
	const [userName, setUserName] = useState('');
	const [subjectActionOptions, setSubjectActionOptions] = useState([]);
		
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const handleSubjectChange = (e, setFieldValueFunction) => {
        
        setFieldValueFunction("subject", e.target.value);
        
        if (!e.target.value) {
        	setSubjectActionOptions([]);
        	setFieldValueFunction("action", "");
        	return;
        }

		// Filter SubjectActions Options
		setSubjectActionOptions(ActivityConfig.SubjectActions[e.target.value]);
        
        //Reset SubjectActions Status on every change
        setFieldValueFunction("action", "");
         
    }
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	Object.keys(ActivityConfig.FilterFormValidationRules)
		.forEach((key) => {
				initialValues[key] = (props.parameters[key]) ? props.parameters[key] : defaultValues[key];
		});
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
				
					if (error instanceof ValidationError) {		
    									
    						log.info("Filtering Items Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Filtering Items: ", error.message);
    					}
    								
    					props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
	
			<Form className="form-filter-data" noValidate>
				<fieldset>
					<div className={"border rounded p-4"}>
						<SearchUserModal
							show={searchUserModalShow}
	        				onHide={() => setSearchUserModalShow(false)}
							size="lg"
							onChange={(user) => { setFieldValue("filter", user.id); setUserName(user.displayName + " (" +user.fullName+")"); setSearchUserModalShow(false); } }
		      		    />
						<FormGroup as={Row} controlId="formGridUser">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"user.label"}>User</Trans>
    						</FormLabel>
						    <Col sm="8">
								<InputGroup >
								   <FormControl onClick={() => {setSearchUserModalShow(true);}} className="text-uppercase" type={'text'} isInvalid={!(errors.filter == null)} value={userName} style={{backgroundColor: "#ffffff"}} readOnly />
								   <InputGroup.Append >
									  {(!values.filter) ?
										<InputGroup.Text ><FontAwesomeIcon icon={faSearch} onClick={() => {setSearchUserModalShow(true);}} /></InputGroup.Text> 
									  	:
										<InputGroup.Text ><FontAwesomeIcon icon={faTimesCircle} onClick={() => {setFieldValue("filter", ""); setUserName("");}} /></InputGroup.Text> 
								   	  }
									</InputGroup.Append>
					               <FormControlErrors errors={errors.filter} />
				               </InputGroup>
   							</Col>
						</FormGroup>
						
						<FormGroup as={Row} controlId="formGridAction">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"action.label"}>Subject/Action</Trans>
    						</FormLabel>
							<Col sm="4">
								<FormControl as="select" name="subject" isInvalid={!(errors.subject == null)} value={values.subject} onChange={(e) => handleSubjectChange(e, setFieldValue)} >
		    						<option  value="">{t(props.i18nPrefix+"subject.options.blank")}</option>
		    						{ ActivityConfig.Subjects.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{item}</option>
		    						)}
		    					</FormControl>
								<FormControlErrors errors={errors.subject} />
							</Col>
							<Col sm="4">
								<FormControl as="select" name="action" disabled={!(values.subject)} isInvalid={!(errors.action == null)} value={values.action} onChange={handleChange} >
		    						<option  value="">{t(props.i18nPrefix+"action.options.blank")}</option>
		    						{ subjectActionOptions.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{item}</option>
		    						)}
		    					</FormControl>
								<FormControlErrors errors={errors.action} />
							</Col>	
						</FormGroup>
						
						<FormGroup as={Row} controlId="formGridDateRange" className="mb-1">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"dateRange.label"}>Date Range</Trans>
    						</FormLabel>
							<Col sm="4">
								<DateTimePicker
									locale={config.preferredLanguage}
      								className={!(errors.start == null) ? "is-invalid border border-danger rounded" : ""}
      								onChange={(e) => { 
      									if (e.date) { 
      										if (!e.date.isInvalid) 
												setFieldValue("start", moment(e.date).format());
											else
      											setFieldValue("start", e.date.parsingFlags().inputDate);
      									} else if (e.date === null) { // reset if null (which is !== from undefined)
      										setFieldValue("start", "");
      									}
      								}}
      								date={values.start}
      								keepInvalid={true}
    							 />
								<FormControlErrors errors={errors.start} />
							</Col>
							<Col sm="4">
								<DateTimePicker
									locale={config.preferredLanguage}
      								className={!(errors.end == null) ? "is-invalid border border-danger rounded" : ""}
      								onChange={(e) => { 
      									if (e.date) { 
      										if (!e.date.isInvalid) 
												setFieldValue("end", moment(e.date).format());
											else
      											setFieldValue("end", e.date.parsingFlags().inputDate);
      									} else if (e.date === null) { // reset if null (which is !== from undefined)
      										setFieldValue("end", "");
      									}
      								}}
      								date={values.end}
      								keepInvalid={true}
    							 />
								<FormControlErrors errors={errors.end} />
							</Col>
							
							<Col sm="2">
								<Button type="submit" disabled={isSubmitting} onClick={() => {}}> 
									{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
								</Button>
							</Col>
						</FormGroup>
				
					</div>
				</fieldset>
			</Form>	
		)}
	</Formik>
	)

}

export default ActivityFilterForm;
