import React, { Fragment, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

import Header from "../components/Header";
import DismissableFeedback from "../components/DismissableFeedback";

import { ValidationError } from "../helpers/custom-errors";

const NotFoundPage = props => {
   const [_error, _setError] = useState(null);
   const [_success, _setSuccess] = useState(null);
   const [_warning, _setWarning] = useState(null);
   const [_info, _setInfo] = useState(null);
  
   let location = useLocation();
   const { t } = useTranslation();

  const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }
	 
	 if (error instanceof ValidationError) {		
		 _setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };

  return (
	<Fragment>
		<Header 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>
		
		<Container>
			<DismissableFeedback feedback={_error} onClose={() => onError(null)} type="danger" />
			<DismissableFeedback feedback={_success} onClose={() => onSuccess(null)} type="success" />
			<DismissableFeedback feedback={_warning} onClose={() => onWarning(null)} type="warning" />
			<DismissableFeedback feedback={_info} onClose={() => onInfo(null)} type="info" />


			<Alert variant={"danger"}><Trans i18nKey="app.path-not-found.alert">No match for</Trans> <code>{location.pathname}</code></Alert>
		</Container>
		
	</Fragment>
  );
}

export default NotFoundPage;