import * as Yup from "yup";
import moment from "moment";
import { Regex, isArrayWithLength } from '../helpers/commons'

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

Yup.addMethod(Yup.array, "hasDistinctProducts", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasDistinctProducts", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		const uniques = new Set(value.map(item => item["productId"]));   
    	return [...uniques].length === value.length
    			|| createError({ path, message: message }); 
    
    });
});

const ExpenseRegistrationStatus = [
	"true",
	"false",
]

const FormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	supplierNo: Yup.string()
		.required()
		.default(() => ""),
	supplierDisplayName: Yup.string()
		.default(() => ""),
	eventDate: Yup.string()
		.required()
		.isValidLocalDate({ message: 'supplier-orders.save.form.eventDate.validation.format'})
		.default(() => ""),
	totalAmount: Yup.string()
		.required()
		.matches(Regex.NEGATIVE_CURRENCY, "supplier-orders.save.form.totalAmount.validation.format")
   		.default(() => ""),
   	freightCharge: Yup.string()
   		.required()
		.matches(Regex.CURRENCY, "supplier-orders.save.form.freightCharge.validation.format")
   		.default(() => ""),
   	otherCharges: Yup.string()
		.required()
		.matches(Regex.CURRENCY, "supplier-orders.save.form.otherCharges.validation.format")
   		.default(() => ""),
   	remarks: Yup.string()
   		.max(255, 'supplier-orders.save.form.remarks.validation.maximumlength')
   		.default(""),
	items: Yup.array()
		.min(1, 'supplier-orders.save.form.items.validation.required')
		.hasDistinctProducts({ message: 'supplier-orders.save.form.items.validation.distinct-products'})
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const PurchaseTypes = [
	"PURCHASE",
	"PURCHASE_RETURN"
]

const LineItemFormValidationRules = {
	productId: Yup.string()
		.required()
		.default(() => ""),
	productName: Yup.string()
		.default(() => ""),
	purchaseType: Yup.string()
		.oneOf([""].concat(PurchaseTypes), 'form.validation.string.enum')
		.required()
   		.default(() => "PURCHASE"),
	unitPrice: Yup.string()
		.required()
		.matches(Regex.CURRENCY, "supplier-orders.save-item-modal.form.unitPrice.validation.format")
   		.default(() => ""),
	quantity: Yup.number()
			.typeError('supplier-orders.save-item-modal.form.quantity.validation.format')
			.min(1, "supplier-orders.save-line-item.form.quantity.validation.min")
			.required()
			.default(() => 1),
	discountAmount: Yup.string()
		.required()
		.matches(Regex.CURRENCY, "supplier-orders.save-item-modal.form.discountAmount.validation.format")
   		.default(() => "0.00"),
	totalAmount: Yup.string()
		.required()
		.matches(Regex.CURRENCY, "supplier-orders.save-item-modal.form.totalAmount.validation.format")
   		.default(() => ""),
   	remarks: Yup.string()
   		.max(255, 'supplier-orders.save-item-modal.form.remarks.validation.maximumlength')
   		.default(""),
			
}

const FilterFormValidationRules = {
	supplierNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "eventDate"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	products: Yup.array()
		.default(() => []), 
	registered: Yup.string()
		.oneOf([""].concat(ExpenseRegistrationStatus), 'form.validation.string.enum')
   		.default(() => ""),
   	grouped: Yup.boolean()
   		.default(() => true),
}

const AdvancedFilterFormFields = {
	filter: true,
	products : true,
	status : true,
	grouped: true,
}

const ExportHeaders =[
	"id", "eventDate", "supplierNo", "supplierDisplayName", "totalItems", "products", "freightCharge", "otherCharges", "totalAmount", "status", "whenModified", "whenCreated"
]

const ExportItemsHeaders =[
	"id", "eventDate", "supplierNo", "supplierDisplayName", "productId", "productName", "purchaseType", "quantity", "unitPrice", "discountAmount", "totalAmount", "remarks", "status", "whenModified", "whenCreated"
]

const SupplierOrderConfig = { PurchaseTypes, FormValidationRules, LineItemFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields, ExpenseRegistrationStatus,
		ExportHeaders, ExportItemsHeaders
};

export default SupplierOrderConfig;