import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext   } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import CustomContainer from "../components/CustomContainer";

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SupplierFilterForm from "../components/SupplierFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import API from "../services/backend-api";

import SupplierConfig from "../config/SupplierConfig";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";

const dataTableActionModals = [
	 {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: Filler,
		size: 'lg',
		multiple : true
      }
    ];


const Suppliers = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedSuppliers, setPagedSuppliers] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	let navigate = useNavigate();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageSuppliers({})
			.catch(error => { 
		 		log.error("Error Loading Initial Suppliers List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
  
  const confirmSupplierAccessTokenReset = (item, index) => {

	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }

  const confirmMultipleSupplierAccessTokensReset = (items, index) => {

	  setSelectedDataTableItems(items);
	  showActionModal("action-"+index)
  }

  const updateSupplier = (item) => {
	  navigate("/suppliers/update", { state: { item: item}});
  }
  
  const addSupplier = () => {
	  navigate("/suppliers/create");
  }
    
  const updatePage = (values) => {
	  pageSuppliers(values)
		.catch(error => { 
			log.error("Error Paginating Suppliers List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageSuppliers(values)
		.catch(error => { 
			log.error("Error Sorting Suppliers List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.mobilePhone = currentPage.mobilePhone; 
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Suppliers with no new values
	  pageSuppliers({})
		.catch(error => { 
	 		log.error("Error Refreshing Suppliers List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageSuppliers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageSuppliers(mergeQueryParams(pagedSuppliers, values)).then(response => {
			setPagedSuppliers(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	const exportSuppliers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedSuppliers, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findSuppliers(exportFilters, auth.isAdmin()).then(response => {

			let columns = SupplierConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.active = t("suppliers.list.export.enums.active."+row.active);
				row.entityType = t("suppliers.list.export.enums.entityType."+row.entityType);
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("suppliers.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	const resetSupplierAccessToken = (item) => {
		
		//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.resetSupplierAccessToken(item.id, config.csrfToken)
			.then(response => {
				context.onSuccess(t("suppliers.save.success"));
			}).catch(error => {
				
				log.error("Error Reseting Supplier Access Token: ", error.message);
				
				context.onError(error);
				
			}).finally(() => {
				handleActionModalHide();
			});	
	}
	
	/*const resetMultipleSupplierAccessTokens = (items) => {
		
	}*/
	
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedSuppliers) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="suppliers"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="suppliers"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("suppliers.reset-access-token-confirmation-modal.title")}
				bodyText={t("suppliers.reset-access-token-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("suppliers.reset-access-token-confirmation-modal.confirm")}
				cancelText={t("suppliers.reset-access-token-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={resetSupplierAccessToken}
			/>}
			
			{/*(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("suppliers.reset-multiple-access-tokens-confirmation-modal.title")}
				bodyText={t("suppliers.reset-multiple-access-tokens-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("suppliers.reset-multiple-access-tokens-confirmation-modal.confirm")}
				cancelText={t("suppliers.reset-multiple-access-tokens-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={resetMultipleSupplierAccessTokens}
			/>*/}
		<PagedList
			i18nPrefix="suppliers.list."
			page={pagedSuppliers}
			pageSizeOptions={[10,25,50,100]}
			//filterFormComponent={SingleFilterForm}
			filterFormComponent={SupplierFilterForm}
			filterParameters={{ filter: pagedSuppliers.filter, filteredColumn: pagedSuppliers.filteredColumn }}
			onFilter={pageSuppliers}
			onExport={auth.isAdmin() ? exportSuppliers : null}
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addSupplier}
			onPage={updatePage}
			dataTableColumns={["supplierNo", "displayName", "lastOrderDate"]} 
			dataTableSortableColumns={["supplierNo", "displayName"]}
			dataTableCustomDisplayColumns={[["lastOrderDate", "LocalDate"]]}
			dataTableActions={[updateSupplier, confirmSupplierAccessTokenReset]}
			dataTableDefaultAction={updateSupplier}
			dataTableCustomRowStyle={displayInactive}		
		/>
		</CustomContainer>
	);
}

export default Suppliers;
