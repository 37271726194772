import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AuthError, ValidationError } from "../helpers/custom-errors";


setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   name: Yup.string()
		.required()
		.default(() => "")
	});

const SaveCustomTagModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const auth = useAuth();
	
	let defaultValues = validationSchema.default();
		
	let initialValues = defaultValues;
	let title = "customtags.save-modal.title-new";
	
	//If property tag is not null, then this is as update
	if (props.tag) {
		title = "customtags.save-modal.title-update";
		initialValues.name = props.tag.name;
	}
	
	const saveCustomTag = (values) =>
		new Promise((resolve, reject) => {
			if (props.tag) {
				API.updateCustomTag(props.tag.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createCustomTag(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
	});
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-custom-tag"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-custom-tag">
  					<Trans i18nKey={title}>Save Custom Tag</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
		      			saveCustomTag(values, config.csrfToken)
		      			.then(response => {
							actions.setSubmitting(false);
							props.onTagSaved(t('customtags.save-modal.success'));
						})
		      			.catch(error => { 
		      				
		      				actions.setSubmitting(false);
		      				
		      				if (error instanceof AuthError) {		
		    					auth.onUnathorized(error);
		    				} else if (error instanceof ValidationError) {		
          						
					 			log.info("Save custom Tag Attempt Failed: ", error.message);
          						
					 			_setError(error.message);
          						
					 			if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("Save custom Tag Error: ", error.message);
          						_setError(t('error.api.general'));
          					}

					 	})
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-save-custom-tag" noValidate>		
		      			<Modal.Body className="mb-3">
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	
							<Row>
								<FormGroup as={Col} controlId="formGridName">
			    					<FormLabel ><Trans i18nKey="customtags.save-modal.form.name.label">Name</Trans> * <FormControlHelper text="customtags.save-modal.form.name.helper"/></FormLabel>
			    					<FormControl type={'text'} name="name" isInvalid={!(errors.name == null)} value={values.name} onChange={handleChange} placeholder={t('customtags.save-modal.form.name.placeholder')} />
			    					<FormControlErrors errors={errors.name} />
			    				</FormGroup>
							</Row>
		
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="customtags.save-modal.form.submitting">Please wait...</Trans> : <Trans i18nKey="customtags.save-modal.form.submit">Save</Trans>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><Trans i18nKey="customtags.save-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	

		      )}
		      </Formik>
		   </Modal>
	);
}

export default SaveCustomTagModal;
