import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faReceipt, faSignOutAlt,  faInfoCircle, faArrowDown, faPlus, faInbox, faReply } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
//import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'

import Loader from "../components/Loader";

import API from "../services/backend-api";

import { isArrayWithLength } from "../helpers/commons";

import { useConfig } from "../services/use-config";

//import AccountTransactionConfig from "../config/AccountTransactionConfig";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
import moment from "moment";
import MessageDetailModal from "../components/MessageDetailModal";
import AccountActivityDetailModal from "../components/AccountActivityDetailModal";
import SendMessageModal from './SendMessageModal';

const timelineActionModals = [
	{
        key: 'action-detail-message',
        component: MessageDetailModal,
		size: 'lg',
		multiple : false
     },
     {
        key: 'action-detail-others',
        component: AccountActivityDetailModal,
		size: 'lg',
		multiple : false
      }      
];

const accountActivityConfig = {
	
	"MESSAGE_INBOUND" : {
        //contentStyle: { background: 'rgb(28, 164, 185)', color: '#fff' },
        contentStyle: { background: 'rgb(53, 214, 239)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(53, 214, 239)' },
		iconStyle: { background: 'rgb(53, 214, 239)', color: '#fff' },
		icon: faInbox
      },
	"MESSAGE_OUTBOUND" : {
        contentStyle: { background: 'rgb(220, 255, 202)', color: 'rgb(38, 44, 34)' },
        contentArrowStyle: { borderRight: '7px solid  rgb(220, 255, 202)' },
		iconStyle: { background: 'rgb(220, 255, 202)', color: 'rgb(38, 44, 34)' },
		icon: faInbox
      },
	"ACCOUNT_STATEMENT" : {
        //contentStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
        contentStyle: { background: 'rgb(0,0,0)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(0,0,0)' },
		iconStyle: { background: 'rgb(0,0,0)', color: '#fff' },
		icon: faReceipt
      },
     "LOAD_MORE" : {
        contentStyle: { background: 'rgb(63, 81, 181)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(63, 81, 181)' },
		iconStyle: { background: 'rgb(63, 81, 181)', color: '#fff' },
		icon: faPlus
      }
};


const AccountActivitiesModal = props => {
	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	//const [accountActivities, setAccountActivities] = useState(null);
	const [isPaginating, setIsPaginating] = useState(false);
	const [pagedAccountActivities, setPagedAccountActivities] = useState(null);
	const [loadedAccountActivities, setLoadedAccountActivities] = useState([]);
	const [selectedTimelineItem, setSelectedTimelineItem] = useState(null);
	const [timelineActionModalsShowMap, setTimelineActionModalsShowMap] = useState(new Map());
	const [sendMessageModalShow, setSendMessageModalShow] = useState(false);
	
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.accountNo) {
			
			let filter = {};
			filter.pageSize = 10;
			filter.sortBy = "eventTime";
			filter.order = "desc";
			filter.filteredColumn = "accountNo";
			filter.filter = props.item.accountNo;
			filter.actions = ["MESSAGE_INBOUND.CREATE", "MESSAGE_OUTBOUND.CREATE", "ACCOUNT_STATEMENT.OPEN"];
			
			pageAccountActivities(filter)
				.then(response => {

					const activities = Array(response.totalCount).fill().map((e,index)=> {
			  		if (response.list[index])
			  			return (response.list[index])
			  		else
			   			return ({})
			     });  	 
				
				if (isMounted) {
					setPagedAccountActivities(response);
					setLoadedAccountActivities(activities);
				}
				
			})
				.catch(error => { 
					log.error("Error Loading Initial Account Activities List: ", error.message);
					props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});

		}
		
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	timelineActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setTimelineActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedTimelineItem(null); 
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = timelineActionModalsShowMap;
	  tempMap.set(action, true);
	  setTimelineActionModalsShowMap(tempMap);
   }
   
   const detailAccountActivity = (item) => {
	  setSelectedTimelineItem(item);
	  
	  if (item.subject === "MESSAGE_INBOUND" || item.subject === "MESSAGE_OUTBOUND") {
		setSelectedTimelineItem(Object.assign({}, {messageSid: item.subjectId }));
		showActionModal("action-detail-message")
	  } else {
	  	setSelectedTimelineItem(item);
	  	showActionModal("action-detail-others")
	  }
  }
  
  /*const sortTimeline = (values) => {
	  values.pageIndex = 0;
	  pageAccountActivities(values)
		.catch(error => { 
			log.error("Error Sorting Account Activities List: ", error.message);
	 		props.onError(error);
		})
		
  }*/
  
  const updatePage = (values) => {
	  pageAccountActivities(values)
		.catch(error => { 
			log.error("Error Paginating Account Activities List: ", error.message);
	 		context.onError(error);
		})
		
  }
    
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		 
		  queryParams.actions = currentPage.actions; 
		  
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Account Activities with no new values
	  pageAccountActivities({pageIndex: 0})
	    .then(response => {
			const activities = Array(response.totalCount).fill().map((e,index)=> {
			  	if (response.list[index])
			  		return (response.list[index])
			  	else
			   		return ({})
			     });  	 
				
				setPagedAccountActivities(response);
				setLoadedAccountActivities(activities);
				
		})		
		.catch(error => { 
	 		log.error("Error Refreshing Account Activities List: ", error.message);
	 		props.onError(error);
		})	
  }  

  const pageAccountActivities = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		props.onError(null);
		
		API.pageAccountActivities(mergeQueryParams(pagedAccountActivities, values)).then(response => {
			//setAccountActivities(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
  	
  	const renderSubject = (item) => {
	  
	  let variant, icon;
	  
	  switch (item) {
		  
		case "MESSAGE_INBOUND":
			variant = "warning";
			icon = faInbox;
			break;
		case "MESSAGE_OUTBOUND":
			variant = "success";
			icon = faSignOutAlt;
			break;
		case "ACCOUNT_STATEMENT":
		default:
			variant = "info";
			icon = faReceipt;
			break;
	 }
	  
	  return <Badge pill variant={variant}><Trans i18nKey={"account-statements.list-account-activities-modal.datatable.enums.subject."+item}/>{/*<FontAwesomeIcon icon={icon}/>*/}</Badge>
   }
	

	if  (!props.item) {
		return null
	} else if (props.item.accountNo) {
		if (!isLoading && !pagedAccountActivities) {
			return null;
		} 
	}	
		
	if (isLoading) 
		return <Loader />
		
		
	const handlePagination = (values) => {
		
		//const pageIndex = Math.ceil(number/pageSize) - 1;
	
		setIsPaginating(true);
		
		pageAccountActivities(values)
	      .then(response => {
			  
			  let updatedActivities = loadedAccountActivities;
			  
			  response.list.forEach((item, index) => {
			  		updatedActivities[response.pageSize*response.pageIndex + index] = item;
			  });
			  
			  setPagedAccountActivities(response);
			  setLoadedAccountActivities(updatedActivities);
	    	  
	       })
		  .catch(error => { 
		 		log.error("Error Paginating Account Activities: ", error.message);
		 		props.onError(error);
			})
	      .finally(() => {
		 		setIsPaginating(false);
		  });
		
	}
	
	 const handleSendMessageModalHide = () => { 
	  setSendMessageModalShow(false);
  }
  
  const handleSendMessage = () => { 
	  setSendMessageModalShow(true);
  }
  
   const handleMessageSent = (success) => {
	  handleSendMessageModalHide();
	  refreshPage();
	  //_setSuccess(success);
   }

	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-account-activities-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-account-activities-modal">
  					<Trans i18nKey={"account-statements.list-account-activities-modal.title"} values={{account: props.item}}>Account Transactons</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="mb-3">
 			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>
  				{ timelineActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (timelineActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(!item.multiple && selectedTimelineItem) ?
								<Component 
									show={timelineActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="account-activity"
									item={selectedTimelineItem}
									onError={props.onError}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}	
    		
    			{(sendMessageModalShow) && <SendMessageModal
					show={sendMessageModalShow}
					onHide={handleSendMessageModalHide}
					size="md"
					accountNo={props.item.accountNo}
					onMessageSent={handleMessageSent}
					onError={_setError}
				/>} 	
        		<Row><Col>
        			{isArrayWithLength(pagedAccountActivities.list) ?
						<Fragment>
						<VerticalTimeline lineColor="#cccccc">
						 {loadedAccountActivities.map((item, index) => {
							if (item.subject) 
								return (<VerticalTimelineElement
    								key={index}
									className="vertical-timeline-element--work"
									position={(item.subject === "MESSAGE_OUTBOUND") ? "right" : "left"}
									date={moment(item.eventTime).format("L LT")}
									dateClassName="text-secondary"
									onTimelineElementClick={() => { detailAccountActivity(item)}}
    								contentStyle={accountActivityConfig[item.subject].contentStyle}
    								contentArrowStyle={accountActivityConfig[item.subject].contentArrowStyle}
    								iconStyle={accountActivityConfig[item.subject].iconStyle}
    								icon={<FontAwesomeIcon size="lg" icon={accountActivityConfig[item.subject].icon} /> }
  								>
									<h6 className="vertical-timeline-element-title"><Trans i18nKey={"account-statements.list-account-activities-modal.timeline.subject.options."+item.subject} /></h6>
  								</VerticalTimelineElement>)
  							else return null;
						})}
						{(pagedAccountActivities.hasNext) ?
							<VerticalTimelineElement
									iconClassName="vertical-timeline-element-icon--button"
									iconOnClick={() => { handlePagination({pageIndex: pagedAccountActivities.pageIndex+1})}}
    								iconStyle={accountActivityConfig["LOAD_MORE"].iconStyle}
    								icon={<FontAwesomeIcon size="2x" icon={accountActivityConfig["LOAD_MORE"].icon} />}	
  								>
								</VerticalTimelineElement>
							: null
						}
						</VerticalTimeline>
						</Fragment>      				
						:<Trans i18nKey={"account-statements.list-account-activities-modal.timeline.no-items-disclaimer"} />
					}
        				
        		  </Col></Row>
				</Fragment>
			)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={handleSendMessage} >
					<span><FontAwesomeIcon icon={faReply} /> <Trans i18nKey={"account-statements.list-account-activities-modal.send-message"}>Send Message</Trans></span>
          		</Button>		 
          		<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="products.list-supplier-orders-modal.close">Close</Trans></Button>	
          	</Modal.Footer>
		</Modal>
	);

}

export default AccountActivitiesModal;
