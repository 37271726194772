import React,  { Fragment, useState, useEffect, useRef } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import NumericInput from 'react-numeric-input';
import BarcodeReader from 'react-barcode-reader'

import ProductConfig from "../config/ProductConfig";

import FormControlHelper from "../components/FormControlHelper";
import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import InputMask from "react-input-mask";

import { useConfig } from "../services/use-config";

import { currencySymbol } from "../helpers/commons";

import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

//import CurrencyInput from 'react-currency-input-field';

//import Slider, { Range } from 'rc-slider';
//import 'rc-slider/assets/index.css';

const ProductFormFields = props => {
	
	const [isScanning, setIsScanning] = useState(false);
	
	const isScanningRef = useRef(isScanning);
	isScanningRef.current = isScanning;
	const scanningTimer = useRef(null);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	
	/*const handleSellingPriceChange = (value, name, values) => {
		       
		console.log(value, name, values);
        props.setFieldValue(name, values.float);

		//if (Math.floor(value) !== value) {
    	//	return value.split(",")[1].length || 0;
  		//}

		props.setFieldValue(name, values.float ? values.float.toFixed(2) : "0.00");
    }*/
    

	const renderTooltip = (text) => (
   	 	<Tooltip id="button-tooltip" >
      		<Trans i18nKey={text} />
    	</Tooltip>
  	);
	
    
	return(
		<Fragment>
			
			{(isScanning) && (
				  <BarcodeReader
				  	onError={(error) => { clearInterval(scanningTimer.current); props.setFieldError("productId", props.i18nPrefix+"form.productId.validation.barcode-scanning-error"); setIsScanning(false); }}
				    onScan={(data) => { clearInterval(scanningTimer.current); props.setFieldValue("productId", data);  setIsScanning(false); }}
				   />
			)}
		
			<Row>
				<FormGroup as={Col} md={6} controlId="formGridProductId">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.productId.label"}>Product Id</Trans> * <FormControlHelper text={props.i18nPrefix+"form.productId.helper"}/></FormLabel>
				    {(props.action === "update") ?
						<FormControl type={'text'} readOnly disabled name="productId" value={props.values.productId}/>
					:
					<InputGroup>
						<FormControl 
							as={InputMask} 
							mask='9999999999999' 
							type={'text'} 
							name="productId" 
							isInvalid={!(props.errors.productId == null)} 
							value={props.values.productId} 
							onChange={props.onChange} 
							placeholder={t(props.i18nPrefix+"form.productId.placeholder")} 
						/>
				    	<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip(props.i18nPrefix+"form.productId.barcode-reader.tooltip")}
    							>
									<Button 
				    				 	variant="secondary" 
				    				 	onClick={() => { 
				    				 		if (!isScanning) {
				    				 				setIsScanning(true); 
				    				 				scanningTimer.current = setTimeout(() => { setIsScanning(false); props.setFieldError("productId", props.i18nPrefix+"form.productId.validation.barcode-scanning-timeout") }, 10*1000);
				    				 			} else{
				    				 				setIsScanning(false); 
				    				 				if (scanningTimer && scanningTimer.current) clearInterval(scanningTimer.current);
				    				 			}
				    				 	}}>
				    				 	{(isScanning) ? (
				    				 		<Fragment>
				    				 			<Spinner animation="border" size="sm" />
				    				 		</Fragment>
				    				 	) : (
				    				 		<Fragment>
				    				 			<FontAwesomeIcon icon={faBarcode} />
				    				 		</Fragment>
				    				 	)}
				    				 </Button>
									
							</OverlayTrigger>
						</InputGroup.Append>
						<FormControlErrors errors={props.errors.productId} />
					</InputGroup>
				}
				</FormGroup>
				
				<FormGroup as={Col} md={6} controlId="formGridName">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.name.label"}>Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.name.placeholder")} />
				    <FormControlErrors errors={props.errors.name} />
				</FormGroup>
								
			</Row>
			
			<Row>
				
				<FormGroup as={Col} md={8} controlId="formGridDescription">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.description.label"}>Description</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="description" isInvalid={!(props.errors.description == null)} value={props.values.description} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.description.placeholder")} />
				    <FormControlErrors errors={props.errors.description} />
				</FormGroup>
				
				<FormGroup as={Col} md={4} controlId="formGridSellingPrice">
					<FormLabel><Trans i18nKey="products.save.form.sellingPrice.label">Selling Price</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="sellingPrice" isInvalid={!(props.errors.sellingPrice == null)} value={props.values.sellingPrice} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.sellingPrice} />
					</InputGroup>

				</FormGroup>
				
				{/*<FormGroup as={Col} md={4} controlId="formGridSellingPrice">
					<FormLabel><Trans i18nKey="products.save.form.sellingPrice.label">Selling Price</Trans> *</FormLabel>
						
						 <CurrencyInput 
						 	className="form-control"
						 	name="sellingPrice" 
						 	//intlConfig={{ locale: config.preferredLanguage, currency: config.numberFormat.currency }}
						 	prefix={currencySymbol(config.preferredLanguage, config.numberFormat.currency)}
						 	fixedDecimalLength={2}
						 	decimalSeparator=','
						 	disableGroupSeparators={true}
						 	isInvalid={!(props.errors.sellingPrice == null)} 
						 	value={props.values.sellingPrice} 
						 	onValueChange={handleSellingPriceChange}
						 />

						<FormControlErrors errors={props.errors.sellingPrice} />

				</FormGroup>*/}
				
			</Row>
			
			<Row>
				<FormGroup as={Col} md={4} controlId="formGridCategory">
					<FormLabel><Trans i18nKey="products.save.form.category.label">Category</Trans> *</FormLabel>
				    <FormControl as="select" name="category" isInvalid={!(props.errors.category == null)} value={props.values.category} onChange={props.onChange} >
		    			<option value="">{t("products.save.form.category.options.blank")}</option>
		    			{ ProductConfig.Categories.map(item =>
		    				<option key={item} value={item}>{t("products.save.form.category.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.category} />
				</FormGroup>
				
				<FormGroup as={Col} md={3} controlId="formGridShelfDisplayOrder">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.shelfDisplayOrder.label"}>Shelf Display Order</Trans> * <FormControlHelper text={props.i18nPrefix+"form.shelfDisplayOrder.helper"}/></FormLabel>
					{/*<FormControl type={'number'} name="shelfDisplayOrder" isInvalid={!(props.errors.shelfDisplayOrder == null)} value={props.values.shelfDisplayOrder} onChange={props.onChange} />*/}
					<NumericInput className="form-control" strict={true} min={0} name="shelfDisplayOrder" isInvalid={!(props.errors.shelfDisplayOrder == null)} value={props.values.shelfDisplayOrder} onChange={(valueAsNumber) => props.setFieldValue("shelfDisplayOrder",valueAsNumber)} />
					<FormControlErrors errors={props.errors.shelfDisplayOrder} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridBrand">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.brand.label"}>Brand</Trans> </FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="brand" isInvalid={!(props.errors.brand == null)} value={props.values.brand} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.brand.placeholder")} />
					<FormControlErrors errors={props.errors.brand} />
				</FormGroup>

			</Row> 		
			
			<Row>
				<FormGroup as={Col} controlId="formGridInventoryLevels">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.inventoryLevels.label"}>Inventory Levels</Trans> * <FormControlHelper text={props.i18nPrefix+"form.inventoryLevels.helper"}/></FormLabel>
					<fieldset>
						<div className={(props.errors.minInventoryLevel == null && props.errors.optimalInventoryLevel == null && props.errors.maxInventoryLevel == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							
										<Row className="align-items-center">
				<Col md={"auto"}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.minInventoryLevel.label"}>Min Inventory Level</Trans></FormLabel>
				</Col>
				
					
				<FormGroup as={Col} md={2} controlId="formGridMinInventoryLevel">
					<NumericInput className="form-control" strict={true} min={0} max={props.values.maxInventoryLevel} name="minInventoryLevel" isInvalid={!(props.errors.minInventoryLevel == null)} value={props.values.minInventoryLevel} onChange={(valueAsNumber) => props.setFieldValue("minInventoryLevel",valueAsNumber)} />
					<FormControlErrors errors={props.errors.minInventoryLevel} />
				</FormGroup>
				
				{/*<FormGroup as={Col} controlId="formGridOptimalInventoryLevel">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.optimalInventoryLevel.label"}>Optimal Inventory Level</Trans> </FormLabel>
				    <FormControl type={'range'} min={props.values.minInventoryLevel} max={props.values.maxInventoryLevel} name="optimalInventoryLevel" isInvalid={!(props.errors.optimalInventoryLevel == null)} value={props.values.optimalInventoryLevel} onChange={props.onChange} />
					<p>{props.values.optimalInventoryLevel}</p>
					<FormControlErrors errors={props.errors.optimalInventoryLevel} />
				</FormGroup>*/}
				
				<FormGroup as={Col} controlId="formGridOptimalInventoryLevel">
					<RangeSlider 
				    	min={props.values.minInventoryLevel} 
				    	max={props.values.maxInventoryLevel} 
				    	tooltip={"on"} 
				    	name="optimalInventoryLevel"
				    	tooltipLabel={currentValue => `Estoque Ideal = ${currentValue}`}
				    	value={props.values.optimalInventoryLevel}
				    	disabled={props.values.minInventoryLevel == props.values.maxInventoryLevel }
				    	onChange={props.onChange}
				    	variant={!(props.errors.optimalInventoryLevel == null) ? "danger" : "primary"} />
					<FormControlErrors errors={props.errors.optimalInventoryLevel} />
				</FormGroup>
				
				
				<FormGroup as={Col} md={"2"} controlId="formGridMaxInventoryLevel">
					<NumericInput className="form-control" strict={true} min={props.values.minInventoryLevel} name="maxInventoryLevel" isInvalid={!(props.errors.maxInventoryLevel == null)} value={props.values.maxInventoryLevel} onChange={(valueAsNumber) => props.setFieldValue("maxInventoryLevel",valueAsNumber)} />
					<FormControlErrors errors={props.errors.maxInventoryLevel} />
				</FormGroup>
				
				<Col md={"auto"}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.maxInventoryLevel.label"}>Max Inventory Level</Trans></FormLabel>
				</Col>

			</Row> 	
							
							
						</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.minInventoryLevel} />
				<FormControlErrors block={true} errors={props.errors.optimalInventoryLevel} />
				<FormControlErrors block={true} errors={props.errors.maxInventoryLevel} />
				</FormGroup>
		   </Row>	
			
			
			{/*<Row>
				<FormGroup as={Col} md={6} controlId="formGridSellingPrice">
					<FormLabel><Trans i18nKey="products.save.form.sellingPrice.label">Selling Price</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="sellingPrice" isInvalid={!(props.errors.sellingPrice == null)} value={props.values.sellingPrice} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.sellingPrice} />
					</InputGroup>*/}
					
					{/*<CurrencyInput 
						name="sellingPrice"
						className={!(props.errors.sellingPrice == null) ? "form-control is-invalid" :  "form-control"} 
						value={props.values.sellingPrice} 
						intlConfig={{ locale: config.preferredLanguage, currency: 'BRL' }} 
						onValueChange={handleOnCurrencyChange}
						fixedDecimalLength={2}
						decimalScale={2}
						step={1}
						maxLength={10}
					/>
					<FormControlErrors errors={props.errors.sellingPrice} />*/}
				{/*</FormGroup>
				
				<FormGroup as={Col} controlId="formGridSupplierCost">
					<FormLabel><Trans i18nKey="products.save.form.supplierCost.label">Supplier Cost</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend> 
						<FormControl type={'text'} name="supplierCost" isInvalid={!(props.errors.supplierCost == null)} value={props.values.supplierCost} onChange={props.onChange} placeholder={t('products.save.form.supplierCost.placeholder')} />
						<FormControlErrors errors={props.errors.supplierCost} />
  					</InputGroup>
				</FormGroup>
			</Row> */}
				
			
			{(props.action === 'update')&&
			<Row>		
   				<FormGroup as={Col} controlId="formGridAvailable">
	    				<FormLabel ><Trans i18nKey="products.save.form.available.label">Available</Trans></FormLabel>
	    				<Switch 
	    					name="available"
	    					value={props.values.available}
	    					checked={JSON.parse(props.values.available)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
			 }
				
				
		
		</Fragment>
		
	) 
}


export default ProductFormFields;
