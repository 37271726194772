import * as Yup from "yup";
import moment from "moment";
import { Regex, isArrayWithLength } from '../helpers/commons'

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

Yup.addMethod(Yup.array, "hasDistinctProducts", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("hasDistinctProducts", message, function(value) {
        const { path, createError } = this;
		
		if (!isArrayWithLength(value)) return true;
		
		const uniques = new Set(value.map(item => item["productId"]));   
    	return [...uniques].length === value.length
    			|| createError({ path, message: message }); 
    
    });
});

const InventoryCountTimeOptions = [
	"PRE_OPENING",
	"POST_CLOSING",
]

const FormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	eventDate: Yup.string()
		.required()
		.isValidLocalDate({ message: 'inventory-counts.save.form.eventDate.validation.format'})
		.default(() => ""),
	eventTime: Yup.string()
		.oneOf([""].concat(InventoryCountTimeOptions), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
   	remarks: Yup.string()
   		.max(255, 'inventory-counts.save.form.remarks.validation.maximumlength')
   		.default(""),
	items: Yup.array()
		.min(1, 'inventory-counts.save.form.items.validation.required')
		.hasDistinctProducts({ message: 'inventory-counts.save.form.items.validation.distinct-products'})
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const LineItemFormValidationRules = {
	productId: Yup.string()
		.required()
		.default(() => ""),
	productName: Yup.string()
		.default(() => ""),
	quantity: Yup.number()
			.typeError('inventory-counts.save-item-modal.form.quantity.validation.format')
			.min(0, "inventory-counts.save-line-item.form.quantity.validation.min")
			.required()
			.default(() => 0),
   	remarks: Yup.string()
   		.max(255, 'inventory-counts.save-item-modal.form.remarks.validation.maximumlength')
   		.default(""),		
}

const FilterFormValidationRules = {
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "eventDate"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	products: Yup.array()
		.default(() => []), 
}

const AdvancedFilterFormFields = {
	filter: true,
	products : true
}

const InventoryCountConfig = { FormValidationRules, LineItemFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields, InventoryCountTimeOptions};

export default InventoryCountConfig;