import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import AccountTransactionFilterForm from "../components/AccountTransactionFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import SaveAccountTransactionModal from "../components/SaveAccountTransactionModal";
import SaveMultipleAccountTransactionsModal from "../components/SaveMultipleAccountTransactionsModal";

import SaveMultipleAccountTransactionsTagsModal from "../components/SaveMultipleAccountTransactionsTagsModal";

import AccountTransactionConfig from "../config/AccountTransactionConfig";

import { formatCurrency, formatNumber, isArrayWithLength } from "../helpers/commons";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";

import { jsonToCSV } from "react-papaparse";
import CustomContainer from './CustomContainer';


const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveAccountTransactionModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-2',
        component: SaveMultipleAccountTransactionsModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleAccountTransactionsTagsModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-1',
        component: Filler,
		size: 'lg',
		multiple : true
      },
];

const AccountTransactions = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedAccountTransactions, setPagedAccountTransactions] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageAccountTransactions({})
			.catch(error => { 
		 		log.error("Error Loading Initial AccountTransactions List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const updateAccountTransaction = (item, index) => {
	  //history.push({ pathname: "/orders/update", item: item});
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }
  
  const addMultipleAccountTransactions = () => {
	  //history.push({ pathname: "/orders/create"});
	  let item = {};
	  setSelectedDataTableItem(item);
	  //By default, it will open form allowing multiple accounts to be selected
	  showActionModal("action-2")
  }

    
  const updatePage = (values) => {
	  pageAccountTransactions(values)
		.catch(error => { 
			log.error("Error Paginating AccountTransactions List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageAccountTransactions(values)
		.catch(error => { 
			log.error("Error Sorting AccountTransactions List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.accountNo = currentPage.accountNo;
		  queryParams.ledgerNo = currentPage.ledgerNo;
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
		  queryParams.types = currentPage.types;
		  queryParams.tags = currentPage.tags.map(t => Object.assign({},{id: t}));
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List AccountTransactions with no new values
	  pageAccountTransactions({})
		.catch(error => { 
	 		log.error("Error Refreshing AccountTransactions List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageAccountTransactions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageAccountTransactions(mergeQueryParams(pagedAccountTransactions, values)).then(response => {
			setPagedAccountTransactions(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
const exportAccountTransactions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedAccountTransactions, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findAccountTransactions(exportFilters).then(response => {

			let columns = AccountTransactionConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.xactTypeCodeExt = t("account-transactions.list.export.enums.xactTypeCodeExt."+row.xactTypeCodeExt);
				row.xactTypeCode = t("account-transactions.list.export.enums.xactTypeCode."+row.xactTypeCode);
				
				row.amount = formatNumber(r.amount, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("account-transactions.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	
	const confirmAccountTransactionDeletion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
   }

    const confirmMultipleAccountTransactionsDeletion = (items, actionIndex) => {

	  setSelectedDataTableItems(items);
	  showActionModal("multiple-action-"+actionIndex)
    }

	const updateMultipleAccountTransactionsTag = (items, actionIndex) => {
	  setSelectedDataTableItems(items);
	  showActionModal("multiple-action-"+actionIndex)
  	}

	const displayCreditOrDebit = (item) => { 
		
		let found = AccountTransactionConfig.DEATransactionTreatments.find( i => i.key === item.xactTypeCodeExt);
		
		return (found && found.isCredit) ? "credit-row" : "debit-row";
  	}
	
   const deleteAccountTransaction = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteAccountTransaction(item.id, config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing AccountTransaction: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleAccountTransactions = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleAccountTransactions(listOfItemsId,  config.csrfToken).then(response => {
			context.onSuccess(t('account-transactions.delete-multiple-transactions-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Account Transactions: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
  
   const handleDisplayTags = (items) => {
	  
	  return (isArrayWithLength(items) ? items.map(i => <Badge pill variant="info">{i}</Badge>) : "-")
  	}

   	if (isLoading) 
		return <Loader />
	
	if (!pagedAccountTransactions) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("account-transactions.delete-transaction-confirmation-modal.title")}
				bodyText={t("account-transactions.delete-transaction-confirmation-modal.body", {item: Object.assign({},selectedDataTableItem,{amount: formatCurrency(selectedDataTableItem.amount, config.preferredLanguage, config.numberFormat.currency)})})}
				confirmText={t("account-transactions.delete-transaction-confirmation-modal.confirm")}
				cancelText={t("account-transactions.delete-transaction-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteAccountTransaction}
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("account-transactions.delete-multiple-transactions-confirmation-modal.title")}
				bodyText={t("account-transactions.delete-multiple-transactions-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("account-transactions.delete-multiple-transactions-confirmation-modal.confirm")}
				cancelText={t("account-transactions.delete-multiple-transactions-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteMultipleAccountTransactions}
			/>}
			
		<PagedList
			i18nPrefix="account-transactions.list."
			page={pagedAccountTransactions}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={AccountTransactionFilterForm}
			filterParameters={{ filter: pagedAccountTransactions.filter, filteredColumn: pagedAccountTransactions.filteredColumn }}
			onFilter={pageAccountTransactions} 
			onExport={exportAccountTransactions} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addMultipleAccountTransactions}
			onPage={updatePage}
			dataTableColumns={["eventDate", "accountNo", "customerDisplayName", "xactTypeCodeExt", "amount", "ledgerName", "tags", "whenModified"]} 
			dataTableSortableColumns={["eventDate", "accountNo", "amount", "whenModified"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["xactTypeCodeExt", "Enum"],["tags", handleDisplayTags], ["amount", "Currency"],["whenModified", "ZonedDateTime"]]}
			dataTableActions={[updateAccountTransaction, confirmAccountTransactionDeletion]}	
			dataTableDefaultAction={updateAccountTransaction}
			dataTableMultipleSelectActions={[updateMultipleAccountTransactionsTag, confirmMultipleAccountTransactionsDeletion]}
			dataTableHandleTextOverflow={true}
			dataTableCustomRowStyle={displayCreditOrDebit}	
		/>
		</CustomContainer>
	);
}

export default AccountTransactions;
