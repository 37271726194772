import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faTimesCircle, faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DismissableFeedback from "../components/DismissableFeedback";

import { useUA } from 'use-ua-parser-js';
//import { useDeviceSelectors } from 'react-device-detect';

import Moment from 'react-moment';

import JSONPretty from 'react-json-pretty';

const activitySubjectRoutePaths = [
	  {
        subject: 'MESSAGE',
		actions: ['CREATE','UPDATE'],
        path: '/messages/update',
      }, 
    ];

const AccountActivityDetailModal = props => {

	const [displayUADetails, setDisplayUADetails] = useState(false);
	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	let history = useNavigate();

	const getActivitySubjectItemButton = (activity) => {	
		
		let found = activitySubjectRoutePaths.find(({ subject, actions }) => {
		 	return (subject === activity.subject && actions.includes(activity.action)) 
		});
		
		if (!found) return null;
		
		return (
			<Button onClick={() => { history.push({ pathname: found.path, item: {id: activity.subjectId }});}}>
				<FontAwesomeIcon icon={faExternalLinkAlt} /> <Trans i18nKey={"components.account-activity-detail-modal.push-to-subject-item"}>Push to Subject Item</Trans>
			</Button>
		)
    };


	const userAgentDetails = useUA(props.item.userAgent);
	
	//const [selectors, data] = useDeviceSelectors(props.item.userAgent)

	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-activity-detail"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >

		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-activity-detail">
  					<Trans i18nKey="components.account-activity-detail-modal.title">Activity Detail</Trans>
  				</Modal.Title>
  			</Modal.Header>

		    <Modal.Body className="mb-3">
		  		<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	
				<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.date">Date</Trans>:
					</Col>
					<Col md={10}>
						<Moment local format="L LTS Z">{props.item.eventTime}</Moment>
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.account">Account</Trans>:
					</Col>
					<Col md={10}>
						{props.item.account}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.userAgent">User Agent</Trans>:
					</Col>
					<Col md={10}>
      					{userAgentDetails.browser.name} {userAgentDetails.browser.major} ({userAgentDetails.os.name}) 
						<Button size="sm" className="pb-0 pt-0" variant="link" >
							<FontAwesomeIcon icon={(displayUADetails) ? faChevronCircleUp : faChevronCircleDown} color="green" onClick={() => { setDisplayUADetails(!displayUADetails);}}/>
						</Button>
    					{(displayUADetails) && (<JSONPretty data={userAgentDetails}/>)}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.action">Action</Trans>:
					</Col>
					<Col md={10}>
						{props.item.subject+"."+props.item.action}
					</Col>
				</Row>
				
				
				{/*<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.subjectId">Subject ID</Trans>:
					</Col>
					<Col md={10}>
						{props.item.subjectId} 
					</Col>
				</Row>*/}
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="components.account-activity-detail-modal.table.detail">Detail</Trans>:
					</Col>
					<Col md={10}>
						{(props.item.detail) && (
						<JSONPretty 
							data={props.item.detail}
							onJSONPrettyError={e => { _setError(t("components.account-activity-detail-modal.error.detail-json-parsing"));} }
						/>
						)}
					</Col>
				</Row>
				
          	</Modal.Body>
            
			<Modal.Footer>			      		
          		{ (props.displaySubjectItemButton) && getActivitySubjectItemButton(props.item)}
				<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="components.account-activity-detail-modal.close">Close</Trans></Button>	
          	</Modal.Footer>

		   </Modal>
	);
}

export default AccountActivityDetailModal;
