import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

//import Container from 'react-bootstrap/Container'

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SingleFilterForm from "../components/SingleFilterForm";

import ProductSupplierOrdersModal from "../components/ProductSupplierOrdersModal";


import { getAllFiles } from "../helpers/commons";

import API from "../services/backend-api";
import CustomContainer from './CustomContainer';


const dataTableActionModals = [
	{
        key: 'action-1',
        component: ProductSupplierOrdersModal,
		size: 'lg',
		multiple : false
      },
];


const Products = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedProducts, setPagedProducts] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	const [mockups, setMockups] = useState([]);
	
	const location = useLocation();
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
		
		//Import all Memes
		if (isMounted) {		
			let files = getAllFiles(require.context('../images/mockups', false, /\.(png|jpe?g|svg|webp)$/));
			let images = [];
			Object.keys(files).forEach((k) => { images.push(k.replace(/\.[^/.]+$/, ""))});
			setMockups(images);
		}
				
		pageProducts({})
			.catch(error => { 
		 		log.error("Error Loading Initial Products List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
  
  /*const confirmProductAccessTokenReset = (item, action) => {

	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

  const confirmMultipleProductAccessTokensReset = (items, action) => {

	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }*/

  const updateProduct = (item) => {
	  navigate( "/products/update", { state: { item: item}});
  }
  
  const listProductSupplierOrders = (item, index) => {
	  //navigate( "/products/suppliers", { state: { item: item}});
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }
  
  const addProduct = () => {
	  navigate("/products/create");
  }
    
  const updatePage = (values) => {
	  pageProducts(values)
		.catch(error => { 
			log.error("Error Paginating Products List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageProducts(values)
		.catch(error => { 
			log.error("Error Sorting Products List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayUnavailable = (item) => { 
	return (item.available) ? "" : "strikethrough"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Products with no new values
	  pageProducts({})
		.catch(error => { 
	 		log.error("Error Refreshing Products List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageProducts = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageProducts(mergeQueryParams(pagedProducts, values)).then(response => {
			setPagedProducts(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedProducts) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="products"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="products"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
		<PagedList
			i18nPrefix="products.list."
			page={pagedProducts}
			list={pagedProducts.list.map(item => Object.assign({},item,{ mockup: mockups.includes(item.productId)? "Y" : "N"}))}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={SingleFilterForm}
			filterParameters={{ filter: pagedProducts.filter, filteredColumn: pagedProducts.filteredColumn }}
			onFilter={pageProducts} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addProduct}
			onPage={updatePage}
			dataTableColumns={["productId", "name", "category", "sellingPrice", "shelfDisplayOrder", "mockup"]} 
			dataTableSortableColumns={["productId", "name", "shelfDisplayOrder"]}
			dataTableCustomDisplayColumns={[["category", "Enum"],["sellingPrice", "Currency"]]}
			dataTableActions={[updateProduct, listProductSupplierOrders]}
			dataTableDefaultAction={updateProduct}
			dataTableCustomRowStyle={displayUnavailable}		
		/>
		</CustomContainer>
	);
}

export default Products;
