import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';

import FormControlErrors from "../components/FormControlErrors";

import AccountInputSearch from "../components/AccountInputSearch";

import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import * as Yup from "yup";

import AccountStatementConfig from "../config/AccountStatementConfig";


const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(AccountStatementConfig.FilterFormValidationRules);

const AccountStatementFilterFormFields = props => {
	
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
		
	useEffect(() => {
		let isMounted = true; 		
		
		return () => { isMounted = false };
		  
	}, []);
	

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(AccountStatementConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 
	
	const handleAccountChange = (item) => {
		
        if (!item) {
			props.setFieldValue("filter", "");
        	return;
        } 

		props.setFieldValue("filter", item.accountNo);

    }

	
	return (
		<fieldset>
			<div style={displayFiltersButton}>  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-right"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>
			<div className={"border rounded p-4"}>
				 <FormGroup as={Row} controlId="formGridAccount">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"accountNo.label"}>Account</Trans>
    				</FormLabel>
					<Col sm="7">
						<AccountInputSearch 
				    			i18nPrefix={props.i18nPrefix}
				    			onError={props.onError}
				    			isInvalid={!(props.errors.filter == null)}
				    			onChange={handleAccountChange}
				    	/>
						<FormControlErrors errors={props.errors.filter} />
   					</Col>
					<Col sm="3">
						<Button type="submit" disabled={props.isSubmitting}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
				
						
			</div>
		</fieldset>
	)

}

export default AccountStatementFilterFormFields;
