import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//import { useLocation, useNavigate } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import API from "../services/backend-api";
import SupplierConfig from "../config/SupplierConfig";
import * as Yup from "yup";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import InputMask from "react-input-mask";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";


import { parseInputDate } from "../helpers/commons";


const validationSchema = Yup.object().shape(SupplierConfig.FormValidationRules);

const SupplierFormFields = props => {
	
	const { t } = useTranslation();
	const target = useRef(null);
	const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
	const [supplierNoVerified, setSupplierNoVerified] = useState(false);
	
	const config = useConfig();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const buildSupplierPageUrl = (token) => {

		return window.location.protocol + "//" + window.location.host + "/supplier/?token=" + token

  	};

	const renderTooltip = (text) => (
   	 	<Tooltip id="button-tooltip" >
      		<Trans i18nKey={text} />
    	</Tooltip>
  	);

	const handleAccessTokenCopied = () => {
		setShowCopiedTooltip(true); 
		setTimeout(() => {setShowCopiedTooltip(false);}, 3000);		
	} 
	
	const handleEntityTypeChange = (e) => {
        
        props.setFieldValue("entityType", e.target.value);
               
        //Reset documentId
        props.setFieldValue("documentId", "");
    }
    
	return(
		<Fragment>
			
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridSupplierNo">
				    <FormLabel><Trans i18nKey="suppliers.save.form.supplierNo.label">Supplier Number</Trans> *</FormLabel>
				    {(props.action === "update") ?
						<FormControl type={'text'} readOnly disabled name="supplierNo" value={props.values.supplierNo}/>
					:
					<InputGroup>
						<FormControl 
							as={InputMask} 
							mask='9999' 
							type={'text'} 
							name="supplierNo" 
							isInvalid={!(props.errors.supplierNo == null)} 
							isValid={supplierNoVerified && !props.errors.supplierNo}
							value={props.values.supplierNo} 
							onChange={props.onChange} 
							placeholder={t('suppliers.save.form.supplierNo.placeholder')} 
						/>
				    	<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip("suppliers.save.form.supplierNo.validate-tooltip")}
    							>
									<Button variant="secondary" onClick={() => {
										
										setSupplierNoVerified(false);
										props.setFieldError("supplierNo",null);
										Yup.reach(validationSchema, 'supplierNo').validate(props.values.supplierNo)
											.then((e) => {
												API.lookupSupplierView(props.values.supplierNo)
													.then(response => {
														props.setFieldError("supplierNo", t("suppliers.save.form.supplierNo.validation.already-registered"));
													}).catch(error => {
														
														if (error.code === "suppliers-02") {
															setSupplierNoVerified(true);
														} else {
															log.error("Error Looking up Supplier: ", error.message);
															props.onError(error);
														}
												});	
											})
											.catch(error => {
												props.setFieldError("supplierNo", error.errors);
											});	
										
									 }}> 
										<FontAwesomeIcon icon={faCheckCircle} />
									</Button>	
							</OverlayTrigger>
						</InputGroup.Append>
						<InputGroup.Append>
							<OverlayTrigger
									overlay={renderTooltip("suppliers.save.form.supplierNo.generate-tooltip")}
    							>
									<Button variant="secondary" onClick={() => {
										
										setSupplierNoVerified(false);
										props.setFieldError("supplierNo",null);
										
										API.getNextSupplierNo(config.csrfToken)
											.then(response => {
												props.setFieldValue("supplierNo", response.supplierNo);
											}).catch(error => {
												log.error("Error Looking up Next Supplier No. Available: ", error.message);
												props.onError(error);
											});	
										
									 }}> 
										<FontAwesomeIcon icon={faSearch} />
									</Button>	
							</OverlayTrigger>
						</InputGroup.Append>
						<FormControlErrors errors={props.errors.supplierNo} />
						<FormControl.Feedback><Trans i18nKey="suppliers.save.form.supplierNo.feedback.available">Supplier Number Available</Trans></FormControl.Feedback>
					</InputGroup>
				}
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridEntityType">
					<FormLabel><Trans i18nKey="suppliers.save.form.entityType.label">Entity Type</Trans> *</FormLabel>
				    <FormControl as="select" name="entityType" isInvalid={!(props.errors.entityType == null)} value={props.values.entityType} onChange={handleEntityTypeChange} >
		    			{ SupplierConfig.EntityTypes.map(item =>
		    				<option key={item} value={item}>{t("suppliers.save.form.entityType.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.entityType} />
				</FormGroup>
				<FormGroup as={Col} md={6} controlId="formGridDisplayName">
					<FormLabel><Trans i18nKey="suppliers.save.form.displayName.label">Display Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="displayName" isInvalid={!(props.errors.displayName == null)} value={props.values.displayName} onChange={props.onChange} placeholder={t('suppliers.save.form.displayName.placeholder')} />
				    <FormControlErrors errors={props.errors.displayName} />
				</FormGroup>
			</Row>
			
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridFirstName">
					<FormLabel><Trans i18nKey="suppliers.save.form.firstName.label">First Name</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="firstName" isInvalid={!(props.errors.firstName == null)} value={props.values.firstName} onChange={props.onChange} placeholder={t('suppliers.save.form.firstName.placeholder')} />
				    <FormControlErrors errors={props.errors.firstName} />
				</FormGroup>
				<FormGroup as={Col} md={5} controlId="formGridLastName">
				    <FormLabel><Trans i18nKey="suppliers.save.form.lastName.label">Last Name</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="lastName" isInvalid={!(props.errors.lastName == null)} value={props.values.lastName} onChange={props.onChange} placeholder={t('suppliers.save.form.lastName.placeholder')} />
				    <FormControlErrors errors={props.errors.lastName} />
				</FormGroup>
				<FormGroup as={Col} md={4} controlId="formGridMobilePhone">
			    	<FormLabel ><Trans i18nKey="suppliers.save.form.mobilePhone.label">Mobile Phone</Trans></FormLabel>
					<PhoneInput
  						placeholder={t('suppliers.save.form.mobilePhone.placeholder')}
  						value={props.values.mobilePhone}
  						onChange={(value) => {props.setFieldValue("mobilePhone", value);}}
  						isInvalid={!(props.errors.mobilePhone == null)}
  						defaultCountry="BR"
  						international
  						countryCallingCodeEditable={false}
  						name="mobilePhone"
  						inputComponent={FormControl}
  					/>
			    	{!(props.errors.mobilePhone == null) 
		          		? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.mobilePhone} /></div>
						: null
					}	
			    </FormGroup>
			</Row> 		
				
				
			<Row>
				<FormGroup as={Col} md={6} controlId="formGrid-XPTO-1">
				    <FormLabel><Trans i18nKey="suppliers.save.form.email.label">Email</Trans></FormLabel>
				    <FormControl className="text-lowercase" type={'text'} name="email" isInvalid={!(props.errors.email == null)} value={props.values.email} onChange={props.onChange} placeholder={t('suppliers.save.form.email.placeholder')} />
				    <FormControlErrors errors={props.errors.email} />
				</FormGroup>
				<FormGroup as={Col} md={3} controlId="formGridDocumentId">
				    <FormLabel><Trans i18nKey="suppliers.save.form.documentId.label">DocumentId</Trans></FormLabel>
				    <FormControl as={InputMask} disabled={!props.values.entityType} mask={props.values.entityType ? SupplierConfig.DocumentIdMasks[props.values.entityType] : ""} type={'text'} name="documentId"  isInvalid={!(props.errors.documentId == null)} value={props.values.documentId} onChange={props.onChange} placeholder={t('suppliers.save.form.documentId.placeholder')} />
				    <FormControlErrors errors={props.errors.documentId} />
				</FormGroup>		
				<FormGroup as={Col} md={3} controlId="formGridSupplierSince">
					<FormLabel><Trans i18nKey={"suppliers.save.form.supplierSince.label"}>Supplier Since</Trans> </FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.supplierSince == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("supplierSince", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("supplierSince", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("supplierSince", "");
      						}
      					}}
      					date={props.values.supplierSince}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.supplierSince} />
				</FormGroup>
			</Row>	
			
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey="suppliers.save.form.remarks.label">Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t('suppliers.save.form.remarks.placeholder')} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>
			
			{(props.action === 'update' && auth.isAdmin()) ?
			<Row>
				<FormGroup as={Col} controlId="formGridSupplierPageUrl">
	    			<FormLabel ><Trans i18nKey="suppliers.save.form.supplierPageUrl.label">Supplier Page URL</Trans></FormLabel>
	    			<InputGroup>
						<FormControl type={'text'} readOnly disabled name="supplierPageUrl" value={buildSupplierPageUrl(props.values.accessToken)} />
						<InputGroup.Append>
							<OverlayTrigger
									show={showCopiedTooltip}
									overlay={renderTooltip("suppliers.save.form.supplierPageUrl.tooltip.copied")}
    							>
								<CopyToClipboard 
									text={buildSupplierPageUrl(props.values.accessToken)} 
									onCopy={handleAccessTokenCopied}
								>
									<Button variant="secondary" ref={target} > 
										<FontAwesomeIcon icon={faCopy} /> <Trans i18nKey={"suppliers.save.form.copy-supplier-page-url"}>Copy URL</Trans>
									</Button>	
								</CopyToClipboard>
							</OverlayTrigger>
						</InputGroup.Append>
					</InputGroup>
				</FormGroup>
						
   				<FormGroup as={Col} md={{ span: 1, offset: 1 }} controlId="formGridActive">
	    				<FormLabel ><Trans i18nKey="suppliers.save.form.active.label">Active</Trans></FormLabel>
	    				<Switch 
	    					name="active"
	    					value={props.values.active}
	    					checked={JSON.parse(props.values.active)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
				: null
			 }
				
				
		
		</Fragment>
		
	) 
}


export default SupplierFormFields;
