import * as Yup from "yup";
import { Regex } from '../helpers/commons'
/*import drinkMockup from '../images/mockups/drink.png';
import foodMockup from '../images/mockups/food.png';
import otherMockup from '../images/mockups/other.png';
import tennisGoodsMockup from '../images/mockups/tennis-goods.png';*/

const Categories = [
	"BEER",
	"SODA",
	"WATER",
	"ISOTONIC",
	"SNACK",
	"TENNIS_GOODS",
	"OTHER_DRINK",
	"OTHER_FOOD",
	"OTHER"
]

const getAvailableMockups = () => {
	let mockups = [];
	
	try {		
		mockups = require.context('../images/mockups', false, /\.(png|jpe?g|svg)$/)
			.keys().map((item, index) => item.replace('./', ''));
	} catch (err) {

	}	
		
	return mockups;
}  


/*const CategoryMockups = [
	{
		key: "BEER",
		image: drinkMockup,
	},
	{
		key: "SODA",
		image: drinkMockup,
	},
	{
		key: "WATER",
		image: drinkMockup,
	},
	{
		key: "ISOTONIC",
		image: drinkMockup,
	},
	{
		key: "SNACK",
		image: foodMockup,
	},
	{
		key: "TENNIS_GOODS",
		image: tennisGoodsMockup,
	},
	{
		key: "OTHER_DRINK",
		image: drinkMockup,
	},
	{
		key: "OTHER_FOOD",
		image: foodMockup,
	},
	{
		key: "OTHER",
		image: otherMockup,
	}
]*/

const FormValidationRules = {
   productId: Yup.string()
   		.matches("^[0-9]{13}$", 'products.save.form.productId.validation.format')
   		.required()
   		.default(() => ""),
   name: Yup.string()
   		.max(100, 'products.save.form.name.validation.maximumlength')
   		.required()
   		.default(() => ""),
   description: Yup.string()
   		.max(255, 'products.save.form.description.validation.maximumlength')
   		.default(""),
   category: Yup.string()
		.oneOf([""].concat(Categories), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
   brand: Yup.string()
   		.max(100, 'products.save.form.brand.validation.maximumlength')
   		.default(() => ""),
   sellingPrice: Yup.string()
		.matches(Regex.CURRENCY, "products.save.form.sellingPrice.validation.format")
   		.default(() => ""),
   /*sellingPrice: Yup.number()
		.typeError('products.save.form.sellingPrice.validation.format')
   		.default(() => 0),*/
   /*supplierCost: Yup.string()
		.matches(Regex.CURRENCY, "products.save.form.supplierCost.validation.format")
   		.default(() => ""),*/
   shelfDisplayOrder: Yup.number()
		.typeError('products.save.form.shelfDisplayOrder.validation.format')
		.required()
		.default(() => 0),
   minInventoryLevel: Yup.number()
		.typeError('products.save.form.minInventoryLevel.validation.format')
		.required()
		.default(() => 0),
   optimalInventoryLevel: Yup.number()
		.typeError('products.save.form.optimalInventoryLevel.validation.format')
		.when(['minInventoryLevel', 'maxInventoryLevel'], ([minInventoryLevel, maxInventoryLevel], schema) => {
			if (!Number.isNaN(Number(minInventoryLevel)) && !Number.isNaN(Number(maxInventoryLevel)) ) {
				return Yup.number()
					.min(minInventoryLevel, 'products.save.form.optimalInventoryLevel.validation.min')	
					.max(maxInventoryLevel, 'products.save.form.optimalInventoryLevel.validation.max');	
			}
			return schema;
        })
		.required()
		.default(() => 10),
   maxInventoryLevel: Yup.number()
		.typeError('products.save.form.maxInventoryLevel.validation.format')
		.when(['minInventoryLevel'], ([minInventoryLevel], schema) => {
			if (!Number.isNaN(Number(minInventoryLevel)) ) {
				return Yup.number()
					.min(minInventoryLevel, 'products.save.form.maxInventoryLevel.validation.min')	
			}
			return schema;
        })
		.required()
		.default(() => 100),
   available: Yup.boolean()
   		.default(() => true)
}


const ProductConfig = { Categories, getAvailableMockups, /*CategoryMockups,*/ FormValidationRules };

export default ProductConfig;