import React,  { Fragment, useState, useEffect } from 'react';
import { useOutletContext  } from 'react-router-dom';

import log from 'loglevel';

//import Container from 'react-bootstrap/Container'

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import DateRangeToolbar from "../components/DateRangeToolbar";
import CustomContainer from './CustomContainer';

import API from "../services/backend-api";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
//import Tooltip from 'react-bootstrap/Tooltip'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
import moment from "moment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'

const Toolbar = props => {
	
	/*const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );*/
	
	return(
		<Fragment>
			<Row className="mb-3">
				<Col md={"auto"}>
					<ButtonToolbar>
  						<ButtonGroup className="mr-2" >
    						<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().subtract(1, 'months'))}}><FontAwesomeIcon icon={faAngleLeft} /></Button>
							<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().add(1, 'months'))}}><FontAwesomeIcon icon={faAngleRight} /></Button>
  						</ButtonGroup>
					</ButtonToolbar>
					
				</Col>
				<Col >
					<h3 className="d-flex justify-content-center"><Moment local format="MMMM/YYYY">{props.eventDate}</Moment></h3>
				</Col>
				<Col md={"auto"}>
				</Col>
			</Row>
		</Fragment>	
	);

}


const OrdersSummaryReport = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [summarizedOrders, setSummarizedOrders] = useState(null);
	//const [eventDate, setEventDate] = useState(moment(new Date()));
	
    //const [startDate, setStartDate] = useState(null);
	//const [endDate, setEndDate] = useState(null);

	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		let eventDate = moment(new Date());
		
		getOrdersSummary({start: eventDate.startOf('month').format('YYYY-MM-DD'), end: eventDate.endOf('month').format('YYYY-MM-DD')})
			.catch(error => { 
		 		log.error("Error Loading Initial Orders Summary: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);



/*const handleEventDateChange = (newEventDate) => {
		  
      let currentEventDate = eventDate;

	  setIsRefreshing(true);
	  setEventDate(newEventDate);

	  //If month has changed, we must update birthday
	  if (currentEventDate.month() !== newEventDate.month()) {
	  
	  	//Get Orders Summary with no new values
	  	getOrdersSummary({start: newEventDate.startOf('month').format('YYYY-MM-DD'), end: newEventDate.endOf('month').format('YYYY-MM-DD')})
			.catch(error => { 
	 			log.error("Error Refreshing Order Sheets List: ", error.message);
	 			context.onError(error);
			}).finally(() => {
		 		setIsRefreshing(false);
			});	
	  } else {
		setIsRefreshing(false);
	  }
  }  */
  
  const handleDateRangeChange = (newStartDate, newEndDate) => {
		  
      //let currentEventDate = eventDate;

	  setIsRefreshing(true);
	  //setEventDate(newEventDate);
  
	  	//Get Orders Summary with no new values
	  	getOrdersSummary({start: moment(newStartDate).format('YYYY-MM-DD'), end: moment(newEndDate).format('YYYY-MM-DD')})
			.catch(error => { 
	 			log.error("Error Refreshing Order Sheets List: ", error.message);
	 			context.onError(error);
			}).finally(() => {
		 		setIsRefreshing(false);
			});	
  } 
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.accountNo = currentPage.accountNo;
		  queryParams.products = currentPage.products.map(p => Object.assign({},{productId: p}));
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  

  const getOrdersSummary = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.getOrdersSummary(mergeQueryParams(summarizedOrders, values)).then(response => {
			setSummarizedOrders(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
   	if (isLoading) 
		return <Loader />
	
	if (!summarizedOrders) 
		return null;
		
	return (
		<CustomContainer>
			{isRefreshing ? <Loader show={isRefreshing}/> : null}

		<DateRangeToolbar
			i18nPrefix="orders.summary.toolbar."
			//eventDate={eventDate}
			startDate={summarizedOrders.start}
			endDate={summarizedOrders.end}
			variant="outline-primary"
			onDateRangeChange={handleDateRangeChange}
		/>
		
		<DataTable
			i18nPrefix="orders.summary.datatable."
			items={summarizedOrders.list}
			columns={["productName", "totalSalesVolume", "totalSalesRevenue"]} 
			showTotalRowColumns={["totalSalesVolume", "totalSalesRevenue"]} 
			customDisplayColumns={[["totalSalesRevenue", "Currency"]]}
			onError={context.onError}
			noItemsDisclaimer={true}
		/>
		
		</CustomContainer>
	);
}

export default OrdersSummaryReport;
