import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';


import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import OrderFilterForm from "../components/OrderFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import CustomContainer from '../components/CustomContainer';

import SaveOrderModal from "../components/SaveOrderModal";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";

import { formatCurrency, formatNumber  } from "../helpers/commons";

import OrderConfig from '../config/OrderConfig';

const dataTableActionModals = [
	{
        key: 'action-0',
        component: SaveOrderModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: Filler,
		size: 'lg',
		multiple : true
      }
];

const Orders = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedOrders, setPagedOrders] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageOrders({})
			.catch(error => { 
		 		log.error("Error Loading Initial Orders List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const updateOrder = (item, index) => {
	  //history.push({ pathname: "/orders/update", item: item});
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }
  
  const addOrder = () => {
	  //history.push({ pathname: "/orders/create"});
	  let item = {};
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

    
  const updatePage = (values) => {
	  pageOrders(values)
		.catch(error => { 
			log.error("Error Paginating Orders List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageOrders(values)
		.catch(error => { 
			log.error("Error Sorting Orders List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.accountNo = currentPage.accountNo;
		  queryParams.products = currentPage.products.map(p => Object.assign({},{productId: p}));
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
		  
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Orders with no new values
	  pageOrders({})
		.catch(error => { 
	 		log.error("Error Refreshing Orders List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageOrders(mergeQueryParams(pagedOrders, values)).then(response => {
			setPagedOrders(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
 const exportOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedOrders, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findOrders(exportFilters).then(response => {

			//let columns = ["id", "eventDate", "accountNo", "customerDisplayName", "product", "unitPrice", "quantity", "subTotal", "status", "whenModified", "whenCreated"];
			
			let columns = OrderConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.subTotal = formatNumber(row.unitPrice*row.quantity, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.unitPrice = formatNumber(r.unitPrice, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.status =  t("orders.list.export.enums.status." + !Object.is(row.transactionId,null));
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("orders.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	
	const confirmOrderDeletion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
   }

    const confirmMultipleOrdersDeletion = (items, actionIndex) => {

	  setSelectedDataTableItems(items);
	  showActionModal("multiple-action-"+actionIndex)
    }
	
   const deleteOrder = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteOrder(item.id, auth.isAdmin(),config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Order: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleOrders = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleOrders(listOfItemsId, config.csrfToken).then(response => {
			context.onSuccess(t('orders.delete-multiple-orders-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Orders: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
  
  const renderOrderStatus = (item) => {
	  return <Badge pill variant={item ? "info" : "danger"}><Trans i18nKey={"orders.list.datatable.enums.status."+item}/></Badge>
   }

   	if (isLoading) 
		return <Loader />
	
	if (!pagedOrders) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("orders.delete-order-confirmation-modal.title")}
				bodyText={t("orders.delete-order-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("orders.delete-order-confirmation-modal.confirm")}
				cancelText={t("orders.delete-order-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteOrder}
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-0")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("orders.delete-multiple-orders-confirmation-modal.title")}
				bodyText={t("orders.delete-multiple-orders-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("orders.delete-multiple-orders-confirmation-modal.confirm")}
				cancelText={t("orders.delete-multiple-orders-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteMultipleOrders}
			/>}
			
		<PagedList
			i18nPrefix="orders.list."
			page={pagedOrders}
			list={pagedOrders.list.map(i => 
					Object.assign({}, i, {status: !Object.is(i.transactionId,null)}))
			}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={OrderFilterForm}
			filterParameters={{ filter: pagedOrders.filter, filteredColumn: pagedOrders.filteredColumn }}
			onFilter={pageOrders} 
			onExport={exportOrders} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addOrder}
			onPage={updatePage}
			dataTableColumns={["eventDate", "customerDisplayName", "product", "unitPrice", "quantity", "status", "whenModified"]} 
			dataTableSortableColumns={["eventDate", "whenModified"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["unitPrice", "Currency"],["status", renderOrderStatus],["whenModified", "ZonedDateTime"]]}
			dataTableActions={[updateOrder, confirmOrderDeletion]}	
			dataTableDefaultAction={updateOrder}
			dataTableMultipleSelectActions={[confirmMultipleOrdersDeletion]}
			dataTableHandleTextOverflow={true}
		/>
		</CustomContainer>
	);
}

export default Orders;
