import * as Yup from "yup";
import { Regex } from '../helpers/commons'
import moment from "moment";
import ImportConfig from "../config/ImportConfig";

const EntityTypes = [
	"PERSON",
	"ORGANIZATION"
]

const ActiveOptions = [
	true,
	false
]

const DocumentIdMasks = {
	"PERSON" : "999.999.999-99",
	"ORGANIZATION" : "99.999.999/9999-99"
}

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

Yup.addMethod(Yup.array, 'unique', function(message, key) {
    return this.test('unique', message, function(list) {
		let keysMap = new Map();
		for (let i = 0; i < list.length; i++) {
			if (list[i][key]) {
				if (keysMap.get(list[i][key])) {
	 	    		return this.createError({
                		path: `${this.path}[${i}].${key}`,
                		message: message
					});		
	 	    	} else {
	 	    		keysMap.set(list[i][key], true);
	 	    	}
			}
		}
		
		return true;
	
	});
});

const FormValidationRules = {
   accountNo: Yup.string()
   		.matches("^[1-9]{1}\\d{3}$", 'accounts.save.form.accountNo.validation.format')
   		.required()
   		.default(() => ""),
   entityType: Yup.string()
		.oneOf([""].concat(EntityTypes), 'form.validation.string.enum')
		.required()
   		.default(() => "PERSON"),
   displayName: Yup.string()
   		.max(100, 'accounts.save.form.displayName.validation.maximumlength')
   		.required()
   		.default(() => ""),
   mobilePhone: Yup.string()
		.matches(Regex.MOBILE_PHONE, { message: 'accounts.save.form.mobilePhone.validation.format', excludeEmptyString: true })
   		.required()
   		.default(""),
   firstName: Yup.string()
   		.max(100, 'accounts.save.form.firstName.validation.maximumlength')
   		.default(""),
   lastName: Yup.string()
   		.max(100, 'accounts.save.form.lastName.validation.maximumlength')
   		.default(""),
   email: Yup.string()
   		.email()
   		.default(""),
   birthDate: Yup.string()
		.isValidLocalDate({ message: 'accounts.save.form.birthDate.validation.format'})
		//.typeError('accounts.save.form.birthDate.validation.format')
		//.max(new Date(), "accounts.save.form.birthDate.validation.future"),
		.default(() => ""),
   customerSince: Yup.string()
		.isValidLocalDate({ message: 'accounts.save.form.customerSince.validation.format'})
		//.typeError('accounts.save.form.customerSince.validation.format')
		//.max(new Date(), "accounts.save.form.customerSince.validation.future"),
		.default(() => ""),
   documentId: Yup.string()
		.when(['entityType'], (entityType, schema) => {			
			if (entityType === "PERSON") {
				return schema.matches(Regex.CPF, { message: 'accounts.save.form.documentId.validation.format-person', excludeEmptyString: true })	
	        } else if (entityType === "ORGANIZATION"){
				return schema.matches(Regex.CNPJ, { message: 'accounts.save.form.documentId.validation.format-organization', excludeEmptyString: true })
			}
			return schema;
        })
   		.default(() => ""),
   remarks: Yup.string()
   		.max(255, 'accounts.save.form.remarks.validation.maximumlength')
   		.default(""),
   active: Yup.boolean()
   		.default(() => true),
   accessToken: Yup.string()
   		.default(""),
}

const FilterFormValidationRules = {
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => "name"),
	   mobilePhone: Yup.string()
		.matches(Regex.MOBILE_PHONE, { message: 'accounts.save.form.mobilePhone.validation.format', excludeEmptyString: true })
   		.default(""),
};

const VerificationFormValidationRules = {
   	accessToken: Yup.string()
		.required()
   		.default(""),
	verificationCode: Yup.string()
		.matches("^\\d{4}$", 'accounts.verify.form.verificationCode.validation.format')
		.required()
   		.default(""),
}

const ExportHeaders =[
	"accountNo","displayName", "entityType", "firstName", "lastName", "mobilePhone", "email", "birthDate", "customerSince", "documentId", "remarks", "active", "whenCreated", "whenModified"
]

const ImportHeaders =[
	"accountNo","displayName", "entityType", "firstName", "lastName", "mobilePhone", "email", "birthDate", "customerSince", "documentId", "remarks", "active"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;
	
const ImportFormValidationRules ={
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(FormValidationRules))
		.unique('accounts.import.form.data.validation.duplicated-nickName', "nickName")
		.max(255, 'accounts.import.form.data.validation.maximum-size' )
		.default(() => []),
	}

const AccountConfig = { EntityTypes, ActiveOptions, DocumentIdMasks, FormValidationRules, FilterFormValidationRules, VerificationFormValidationRules,
					ExportHeaders, ImportHeaders, ImportFormValidationRules,
					ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions };

export default AccountConfig;