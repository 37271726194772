import * as Yup from "yup";

const Subjects = [
	"ACCOUNT_STATEMENT",
	"MESSAGE_INBOUND",
	"MESSAGE_OUTBOUND"
]

const SubjectActions = {
	"ACCOUNT_STATEMENT":	[
		"OPEN"
	],
	"MESSAGE_INBOUND":	[
		"READ",
	    "CREATE",
	    "UPDATE",
	    "DELETE",
		"QUEUED",
		"SENDING",
		"SENT",
		"FAILED",
		"DELIVERED",
		"UNDELIVERED",
		"RECEIVING",
		"RECEIVED",
		"ACCEPTED",
		"SCHEDULED",
		"PARTIALLY_DELIVERED",
		"CANCELED"
	],
	"MESSAGE_OUTBOUND":	[
		"READ",
	    "CREATE",
	    "UPDATE",
	    "DELETE",
		"QUEUED",
		"SENDING",
		"SENT",
		"FAILED",
		"DELIVERED",
		"UNDELIVERED",
		"RECEIVING",
		"RECEIVED",
		"ACCEPTED",
		"SCHEDULED",
		"PARTIALLY_DELIVERED",
		"CANCELED"
	]
}

const FilterFormValidationRules = {
	   subject: Yup.string()
			.oneOf([""].concat(Subjects), 'form.validation.string.enum')
			.default(() => ""),
	   action: Yup.string()
			.when('subject', (subject, schema) => {
			if (Object.keys(SubjectActions).includes(subject)) {
				return Yup.string()
					.oneOf(SubjectActions[subject], 'form.validation.string.enum');		
	        }
			return schema;
        })
			.default(() => ""),
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => ""),
	   start: Yup.string()
			.default(() => ""),
	   end: Yup.string()
			.default(() => ""),
	   actions: Yup.array()
		.default(() => []),
};

const AccountActivityConfig = { Subjects, SubjectActions, FilterFormValidationRules };

export default AccountActivityConfig;