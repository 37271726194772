import React, {useState, useEffect, Fragment} from "react";
import { HashRouter as Router, useNavigate, useLocation, Route, Navigate, Routes, Link, Outlet} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
//import rdd from 'react-device-detect';

//import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import CustomContainer from "../components/CustomContainer";

import PrivateHashRoute from "../components/PrivateHashRoute";
import { useOutletContext  } from 'react-router-dom';

import Header from "../components/Header";
import DismissableFeedback from "../components/DismissableFeedback";

import { useAuth } from "../services/use-auth";
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';
import { getAllowedRoutes, getHomeHashRoute, isArrayWithLength, isAllowedRoute } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

import UserConfirmation from "../components/UserConfirmation";

const MainPage = props => {
	
 const [_error, _setError] = useState(null);
 const [_success, _setSuccess] = useState(null);
 const [_warning, _setWarning] = useState(null);
 const [_info, _setInfo] = useState(null);
 
 const auth = useAuth();
 const { t } = useTranslation();
 const location = useLocation();
 
 let navigate = useNavigate();
 
 /*useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			//Clear Error/Success every time location changes
			_setError(null);
			_setSuccess(null);
			_setWarning(null);
			_setInfo(null);
		}
			
		 return () => { isMounted = false };
		  
	}, [location]);
 */

 const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }
	 
	 if (error instanceof AuthError) {		
		 auth.onUnathorized(error);
	 } else if (error instanceof ValidationError) {		
		 _setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
	
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };
 
 let routeFound = PrivateRoutesConfig.find(route => route.path === location.pathname);

  return (
	
	<Fragment>	
		<Header 
			isPrivate={true} 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>
     	
		<CustomContainer>
			<DismissableFeedback feedback={_error} onClose={() => onError(null)} type="danger" />
			<DismissableFeedback feedback={_success} onClose={() => onSuccess(null)} type="success" />
			<DismissableFeedback feedback={_warning} onClose={() => onWarning(null)} type="warning" />
			<DismissableFeedback feedback={_info} onClose={() => onInfo(null)} type="info" />
		</CustomContainer>
		
		<CustomContainer>
			 {(routeFound && isArrayWithLength(routeFound.hierarchy)) ?
				<Breadcrumb>
  					{ routeFound.hierarchy.map((item, index) => 
						<Breadcrumb.Item key={index} onClick={() => { navigate((item.path) ? item.path : "#")}} >
							<Trans i18nKey={item.title} >{item.title}</Trans>
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item active><Trans i18nKey={routeFound.title}>{routeFound.title}</Trans></Breadcrumb.Item>
			  	</Breadcrumb>
				:
				null
			 }	
		</CustomContainer>
		
		{(routeFound && isAllowedRoute(routeFound, auth.user)) ?
			<Outlet context={{
				onError: onError,
				onSuccess: onSuccess,
				onWarning: onWarning,
				onInfo: onInfo}}
			/>
			:
			<div><Trans i18nKey={"error.insuficient-permission"}>Insuficient Permission to Access Resource</Trans></div>
		}
		
		{/*<Routes>
		   {allowedRoutes.map((route) => {

		    const { 
		    	path, 
		    	component: Component, 
		    	title,
		    	roles,
		    	permissions,
				hierarchy,
		    	...rest 
		    } = route;
		    
			console.log(route);

		    return (
			<Route key={path} exact path={`${path}`} element={
		      	
				<Fragment>	
					<CustomContainer>
			   			{(isArrayWithLength(hierarchy)) ?
							<Breadcrumb>
  								{hierarchy.map((item, index) => 
									<Breadcrumb.Item key={index} href={(item.path) ? "#"+ item.path : "#"}>
										<Trans i18nKey={item.title}>{item.title}</Trans>
									</Breadcrumb.Item>
								)}
								<Breadcrumb.Item active><Trans i18nKey={title}>{title}</Trans></Breadcrumb.Item>
			  				</Breadcrumb>
							:
							null
			 			}	
					</CustomContainer>
		      		<Component 
		      			onError={onError}
						onSuccess={onSuccess}
						onWarning={onWarning}
						onInfo={onInfo}
		      		/>
		     	</Fragment>
				}
			 />
		    )
		   })}
		   <Route exact path="/" element={
  			<Navigate
  				to={{
  					pathname: getHomeHashRoute(PrivateRoutesConfig, auth.user),
  					state: { from: location }
  				}}
  			/>
		   } />
		   <Route path="*" element={
		   		<Alert variant={"danger"}><Trans i18nKey="app.path-not-found.alert">No match for</Trans> <code>{location.pathname}</code></Alert>
		   } />
		</Routes>*/}
		{/*</Router>*/}
	</Fragment>
	
  );
}

export default MainPage;
