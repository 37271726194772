import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import DataPagination from "../components/DataPagination";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";


const ProductSupplierOrdersModal = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [productSupplierOrders, setProductSupplierOrders] = useState(null);
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.productId) {
			
			let filter = {};
			filter.pageSize = 5;
			filter.sortBy = "eventDate";
			filter.order = "desc";
			filter.products = [{"productId": props.item.productId}];
			
			findProductSupplierOrders(filter)
				.catch(error => { 
					log.error("Error Loading Initial Product Supplier Orders List: ", error.message);
					props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});

		}
		
		 
		 return () => { isMounted = false };
		  
	}, []);


  
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  findProductSupplierOrders(values)
		.catch(error => { 
			log.error("Error Sorting Product Supplier Orders List: ", error.message);
	 		props.onError(error);
		})
		
  }
  
  const updatePage = (values) => {
	  findProductSupplierOrders(values)
		.catch(error => { 
			log.error("Error Paginating Orders List: ", error.message);
	 		context.onError(error);
		})
		
  }
    
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.products = currentPage.products.map( p => Object.assign({},{"productId": p}));
		  
		  
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Product Supplier Orders with no new values
	  findProductSupplierOrders({})
		.catch(error => { 
	 		log.error("Error Refreshing Product Supplier Orders List: ", error.message);
	 		props.onError(error);
		})	
  }  
 
  

  const findProductSupplierOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		props.onError(null);
		
		API.pageSupplierOrderItems(mergeQueryParams(productSupplierOrders, values)).then(response => {
			setProductSupplierOrders(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	

	if  (!props.item) {
		return null
	} else if (props.item.productId) {
		if (!isLoading && !productSupplierOrders) {
			return null;
		} 
	}	
		
	if (isLoading) 
		return <Loader />

	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-product-supplier-orders-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-product-supplier-orders-modal">
  					<Trans i18nKey={"products.list-supplier-orders-modal.title"} values={{product: props.item}}>Last Supplier Orders</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="mb-3">
 			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>	
        		{/*<Row className="mb-3">
    				<Col>
    					<h4>{t("products.list-supplier-orders-modal.header", {count: productSupplierOrders.list.length})}</h4>
    				</Col>
    			</Row>*/}
        		<Row>
        			<Col>
        				<DataTable 
        					items={productSupplierOrders.list} 
        					i18nPrefix="products.list-supplier-orders-modal.datatable." 
        					columns={["eventDate", "supplierDisplayName", "quantity", "unitPrice", "totalAmount"]} 
        					sortableColumns={["eventDate", "unitPrice", "quantity"]}
        					sortBy={productSupplierOrders.sortBy}
        					order={productSupplierOrders.order}
        					sortDataTable={sortDataTable}
        					customDisplayColumns={[["eventDate", "LocalDate"], ["unitPrice", "Currency"], ["totalAmount", "Currency"]]}
        					noItemsDisclaimer={true}
        				/>
        			</Col>
        		</Row>
        		<Row>
        		<Col>
        			<DataPagination className="justify-content-end"
        				totalCount={productSupplierOrders.totalCount}
        				pageIndex={productSupplierOrders.pageIndex}
        				pageSize={productSupplierOrders.pageSize}
						pageSizeOptions={[5,10,25]}
        				totalPageCount={productSupplierOrders.totalPageCount}
        				hasPrev={productSupplierOrders.hasPrev}
        				hasNext={productSupplierOrders.hasNext}
        				updatePage={updatePage} />
        		</Col>
        	</Row>
				</Fragment>
			)}
			</Modal.Body>
			<Modal.Footer>			 
          		<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="products.list-supplier-orders-modal.close">Close</Trans></Button>	
          	</Modal.Footer>
		</Modal>
	);

}

export default ProductSupplierOrdersModal;
