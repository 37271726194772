import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans } from 'react-i18next';

import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";

import PagedList from "../components/PagedList";
import AccountActivityDetailModal from "../components/AccountActivityDetailModal";
import AccountActivityFilterForm from "../components/AccountActivityFilterForm";

import API from "../services/backend-api";

const AccountActivities = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedAccountActivities, setPagedAccountActivities] = useState(null);
	const [activityDetailModalShow, setActivityDetailModalShow] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	
	const location = useLocation();
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted && location.success)
			context.onSuccess(location.success)
		
		let filter = {};
		filter.pageSize = 10;
		filter.sortBy = "eventTime";
		filter.order = "desc";
		filter.filteredColumn = "accountNo";
		filter.subject = "";
		filter.action = "";
		
		pageAccountActivities(filter)
			.catch(error => { 
		 		log.error("Error Loading Initial Account Activities List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  
  const detailActivity = (activity) => {
	  setSelectedActivity(activity);
	  setActivityDetailModalShow(true);
  }
  
  const handleActivityDetailModalHide = () => {
	  setSelectedActivity(null); 
	  setActivityDetailModalShow(false);
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageAccountActivities(values)
		.catch(error => { 
			log.error("Error Sorting AccountActivities List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.subject = currentPage.subject; 
		  queryParams.action = currentPage.action; 
		  
		  queryParams.start = currentPage.start; 
		  queryParams.end = currentPage.end; 
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
const updatePage = (values) => {
	  pageAccountActivities(values)
		.catch(error => { 
			log.error("Error Paginating Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const refreshPage = () => {
	  
	  let currentParams = {};
	  if (pagedAccountActivities) {
		  currentParams.pageIndex = pagedAccountActivities.pageIndex;
		  currentParams.pageSize = pagedAccountActivities.pageSize;
		  currentParams.sortBy = pagedAccountActivities.sortBy;
		  currentParams.order = pagedAccountActivities.order;
		  currentParams.filteredColumn = pagedAccountActivities.filteredColumn;
		  currentParams.filter = pagedAccountActivities.filter;  
		    
	  }
		  
	  pageAccountActivities(currentParams)
		.catch(error => { 
	 		log.error("Error Refreshing Account Activities List: ", error.message);
	 		context.onError(error);
		})	
  }  
 

  const pageAccountActivities = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageAccountActivities(mergeQueryParams(pagedAccountActivities, values)).then(response => {
			setPagedAccountActivities(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
 const renderSubject = (item) => {
	  
	  let variant, style, icon;
	  
	  switch (item) {
		  
		  
		case "MESSAGE_INBOUND":
			variant = "warning";
			style = { background: 'rgb(53, 214, 239)', color: '#fff'};
			break;
		case "MESSAGE_OUTBOUND":
			variant = "success";
			style = { background: 'rgb(220, 255, 202)', color: 'rgb(38, 44, 34)'};
			break;
		case "ACCOUNT_STATEMENT":
			variant = "info";
			style = { background: 'rgb(0,0,0)', color: '#fff' };
			break;
		default:
			variant = "info";
			break;
	 }
	  
	  return <Badge pill variant={variant} style={style}><Trans i18nKey={"account-activities.list.datatable.enums.subject."+item}/></Badge>
   }
	
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedAccountActivities) 
		return null;
		
	return (
		<Fragment>
			{(selectedActivity) && <AccountActivityDetailModal
				show={activityDetailModalShow}
				onHide={handleActivityDetailModalHide}
				size="lg"
				item={selectedActivity}
				onError={context.onError}
				displaySubjectItemButton={true}
			/>}
			<PagedList
				i18nPrefix="account-activities.list."
				page={pagedAccountActivities}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={AccountActivityFilterForm}
				filterParameters={{ filter: pagedAccountActivities.filter, filteredColumn: pagedAccountActivities.filteredColumn }}
				onFilter={pageAccountActivities} 
				onError={context.onError}
				onSort={sortDataTable}
				onPage={updatePage}
				dataTableColumns={["eventTime", "account", "subject", "action"]}
				dataTableSortableColumns={["eventTime"]}
				dataTableCustomDisplayColumns={[["eventTime", "ZonedDateTime"],["subject", renderSubject], ["action", "Enum"]]}
				dataTableActions={[detailActivity]}		
				dataTableDefaultAction={detailActivity}
			/>
		</Fragment>
	);
}

export default AccountActivities;
