import React,  { Fragment, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

//import { CSVReader } from 'react-papaparse';
import { useCSVReader, formatFileSize } from "react-papaparse"

import Loader from "../components/Loader";
import FormControlErrors from "../components/FormControlErrors";
import ImportValidationResults from "../components/ImportValidationResults";

import AccountTransactionConfig from "../config/AccountTransactionConfig";
import DropZoneStyles from "../styles/DropZoneStyles";

import { isArrayWithLength } from "../helpers/commons";

import * as Yup from "yup";

import API from "../services/backend-api";

const accountTransactionValidationRules = Object.assign({}, 
		AccountTransactionConfig.FormValidationRules
);

const accountTransactionValidationSchema = Yup.object().shape(accountTransactionValidationRules);

const AccountTransactionsImportFormFields = props => {

	const [delimiter, setDelimiter] = useState("");
	const [header, setHeader] = useState(true);
	const [columnMappingStrategy, setColumnMappingStrategy] = useState("position");
	const [fileLoaded, setFileLoaded] = useState(false);
	const [customTags, setCustomTags] = useState(null);
	const [ledgers, setLedgers] = useState([]);	
	
	const [isLoading, setIsLoading] = useState(true);
	const [transformationErrors, setTransformationErrors] = useState([]);
	
	const { CSVReader } = useCSVReader();
	
	const [zoneHover, setZoneHover] = useState(false)
  	const [removeHoverColor, setRemoveHoverColor] = useState(
    	DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR
  	)
	
	const { t } = useTranslation();
	
	let defaultAccountTransactionValues = accountTransactionValidationSchema.default();
	
	useEffect(() => {
		let isMounted = true; 	
		
		//Fetch Custom Tags
		API.findCustomTags({})
			.then(response => {
				if (isMounted) setCustomTags(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Custom Tags: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
			
		//Fetch Ledgers
		API.findLedgers()
			.then(response => {
					if (isMounted) setLedgers(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Ledgers: ", error.message);
		 		props.onError(error);
			}).finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	const getTagByName = (name) => {		
		return customTags.find(t => t.name === name)
	};
	
	const getLedgerById = (id) => {		
		return ledgers.find(l => l.ledgerNo === id)
	};
	
	const getLedgerByName = (name) => {		
		return ledgers.find(l => l.name === name)
	};
	
	if (isLoading) 
		return <Loader />		
  	
  	const handleOnUploadAccepted = (results) => {
    	log.info("Results: ", results);

		setZoneHover(false)
		
		//Set File Loaded
		setFileLoaded(true);

		//Clear Error/Success
		props.setFieldError("data", null);
    	props.onError(null);
    	props.onSuccess(null);
		
		if (isArrayWithLength(transformationErrors)) {
			props.setFieldError("data", transformationErrors[0]);
			setTransformationErrors([]);
			return;
		}
		
		//Remove unnecessary headers/columns here
		let parsedData = results.data.map((item) => {
			let d = item;
			
			Object.keys(d).forEach( k => {if (!AccountTransactionConfig.ImportHeaders.includes(k)) delete d[k];} )
			
			return d;
		});
		
		let parsingErrors = [];
		
		
		results.errors.forEach((errors) => {
			errors.forEach(e => {
   				log.error("Error Parsing CSV File: ", e);
				 parsingErrors.push(t(props.i18nPrefix+"form.csvreader.validation.parse-failed", {error: e}));
				
   			});
		});
		
		props.setFieldValue("data", parsedData);
		
		if (isArrayWithLength(parsingErrors))
			props.setFieldError("data", parsingErrors[0]);
		else if (!isArrayWithLength(parsedData))
			props.setFieldError("data", t(props.i18nPrefix+"form.csvreader.validation.no-data"));
			
  	};

	const handleOnRemoveFile = (data) => {
    	props.setFieldValue("data", []);

		props.setFieldError("data", null);
		
		setTransformationErrors([]);
		
		setFileLoaded(false);
		
		//Clear Error/Success
    	props.onError(null);
    	props.onSuccess(null);

  	};

	const handleTransformHeaderByPosition = (header, position) => {
		return (AccountTransactionConfig.ImportHeaders.length > position) ? AccountTransactionConfig.ImportHeaders[position] : header;
  	};


	const accountTransactionEnumsMap = new Map([
		["xactTypeCode", new Map(AccountTransactionConfig.TransactionTypes_DE.map((e) => [t("account-transactions.import.form.data.enums.xactTypeCode."+e), e]))],
		["xactTypeCodeExt", new Map(AccountTransactionConfig.TransactionTypes_Ext.map((e) => [t("account-transactions.import.form.data.enums.xactTypeCodeExt."+e), e]))],
	]);
	
	const handleTransform = (value, key) => {
		
		let errors = transformationErrors; 
		
		let result;
		
		let trimmedValue = (value) ? value.trim() : value;
		
		switch (key) {
			case 'ledgerNo':
				if(!trimmedValue) {
					result = defaultAccountTransactionValues[key];
				} else {
					let ledger = getLedgerById(trimmedValue);
					if (!ledger) {
						errors.push(t(props.i18nPrefix+"form.data.validation.ledger-not-found", {ledger: trimmedValue}));
						result = defaultAccountTransactionValues[key];
					} else {
						result = trimmedValue;
					}
				}
				
    			break;
    		case 'ledgerName':
				let ledger = getLedgerByName(trimmedValue);
				if (!ledger) {
					errors.push(t(props.i18nPrefix+"form.data.validation.ledger-not-found", {ledger: trimmedValue}));
				} 
				result = trimmedValue;
    			break;
    		case 'xactTypeCode':
			case 'xactTypeCodeExt':
				if(!trimmedValue) {
					result = defaultAccountTransactionValues[key];
				} else if (accountTransactionEnumsMap.get(key) && accountTransactionEnumsMap.get(key).get(trimmedValue) !== undefined) {
					result = accountTransactionEnumsMap.get(key).get(trimmedValue) 
    			} else {
					//errors.push(t("account-transactions.import.form.data.validation.enum-"+key, {item: trimmedValue}));
					result = trimmedValue;
				}
    			break;
    		case 'tags':
				let tags = [...defaultAccountTransactionValues[key]];
				
				if (trimmedValue) {
					trimmedValue.split(",")
						.forEach((e) => {
							
							let tag = getTagByName((e)? e.trim() : e);
							
							if (!tag) {
								errors.push(t(props.i18nPrefix+"form.data.validation.tag-not-found", {tag: e}));
								tags.push(e);
							} else {
								tags.push(Object.assign({},tag));
							}
						})
				}
				
				result = tags;
				
    			break;
    		case 'amount':
				result = (trimmedValue) ? trimmedValue.replace(',','.') : defaultAccountTransactionValues[key];
    			break;
  			default:
    			result = trimmedValue;
		}
		
		setTransformationErrors(errors);
		
		return result;
  	};

    
	return(
		<Fragment>
			<Row>			
				<FormGroup as={Col} md={6} controlId="formGridActions">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.action.label"}>Action</Trans> *</FormLabel>
					<FormControl as="select" name="action" disabled={fileLoaded} isInvalid={!(props.errors.action == null)} value={props.values.action} onChange={props.onChange} >
		    			{ AccountTransactionConfig.ImportActions.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.action.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.action} />
				</FormGroup>
			</Row>
					<Row>
						<FormGroup as={Col} md={5} controlId="formGridDelimiter">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.delimiter.label"}>Delimiter</Trans> </FormLabel>
							<FormControl as="select" name="delimiter" disabled={fileLoaded}  value={delimiter} onChange={(e) => {setDelimiter(e.target.value)}} >
		    				{ AccountTransactionConfig.ImportDelimiterOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.delimiter.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
								
						<FormGroup as={Col} md={3} controlId="formGridHeader">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.header.label"}>Header</Trans> </FormLabel>
							<FormControl as="select" name="header" disabled={fileLoaded} value={header} onChange={(e) => {setHeader(e.target.value)}} >
		    				{ AccountTransactionConfig.ImportFirstRowHeaderOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.header.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
								
						<FormGroup as={Col} md={4} controlId="formGridColumnMappingStrategy">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.columnMappingStrategy.label"}>Column Mapping Strategy</Trans> </FormLabel>
							<FormControl as="select" name="columnMappingStrategy" disabled={fileLoaded} value={columnMappingStrategy} onChange={(e) => {setColumnMappingStrategy(e.target.value)}} >
		    				{ AccountTransactionConfig.ImportColumnMappingStrategyOptions.map(item =>
		    					<option key={item.key} value={item.key}>{t(props.i18nPrefix+"form.columnMappingStrategy.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
	
					</Row>
							
					<Row>
						<Col>
							<CSVReader
          						onDragOver={event => {
        							event.preventDefault()
        							setZoneHover(true)
      							}}
      							onDragLeave={event => {
        							event.preventDefault()
        							setZoneHover(false)
      							}}
          						onUploadAccepted={handleOnUploadAccepted}
          						
          						config={{
									"delimiter" : delimiter, 
									"header" : JSON.parse(header),
									"transformHeader" : handleTransformHeaderByPosition,
									"transform" : handleTransform,
									"skipEmptyLines": true
								}}
        					>
        					  {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove
      }) => (
        <>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              DropZoneStyles.DropZone,
              zoneHover && DropZoneStyles.DropZoneHover, (!(props.errors.data == null) ? {borderColor: "red"}: {})
            )}
          >
            {acceptedFile ? (
              <>
                <div style={DropZoneStyles.DropFile}>
                  <div
                    {...getRemoveFileProps()}
                    style={DropZoneStyles.RemoveButton}
                    onMouseOver={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.REMOVE_HOVER_COLOR_LIGHT)
                    }}
                    onMouseOut={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR)
                    }}
                    onClick={event => {
                 		getRemoveFileProps().onClick(event);
                 		handleOnRemoveFile();
               }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                  <div style={DropZoneStyles.DropFileInfo}>
                    <span style={DropZoneStyles.FileSizeInfo}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={DropZoneStyles.FileNameInfo}>{acceptedFile.name}</span>
                  </div>
                  <div style={DropZoneStyles.ProgressBar}>
                    <ProgressBar style={DropZoneStyles.ProgressBarBackgroundColor} />
                  </div>
                  
                </div>
              </>
            ) : (
              <span><Trans i18nKey={props.i18nPrefix+"form.csvreader.placeholder"} /></span>
            )}
          </div>
        </>
      )}
       						</CSVReader>
						</Col>
					</Row>
					<Row><Col>
						<ImportValidationResults 
							i18nPrefix={props.i18nPrefix} 
							isSubmitting={props.isSubmitting} 
							errors={props.errors.data} 
							customDisplayColumns={[["tags","Json"]]}
							data={props.values.data}
							headers={AccountTransactionConfig.ImportHeaders}
						 />
					</Col></Row>

		
		</Fragment>
		
	) 
}


export default AccountTransactionsImportFormFields;
