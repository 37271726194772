import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faExchangeAlt} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';

import FormControlErrors from "../components/FormControlErrors";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { isArrayWithLength, currencySymbol, formatCurrency } from "../helpers/commons";

import NumericInput from 'react-numeric-input';

import SupplierOrderConfig from "../config/SupplierOrderConfig";

import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";


const SupplierOrderLineItemFormFields = props => {

	const [products, setProducts] = useState([]);	
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [unitPriceSourceCalculation, setUnitPriceSourceCalculation] = useState(true);
	
	const typeaheadRef = useRef(null);
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Products
		if (!isArrayWithLength(props.products)) {
			
			//Filter available products only for non-admin users
			let filter = {};
			
			API.findProducts(filter)
				.then(response => {
					if (isMounted) {
						setProducts(response.list);
						if (props.values.productId) {
							let found = response.list.find((item) => (item.productId === props.values.productId))
							if (found) setSelectedProducts(new Array(1).fill(found));
						}
					}
				})
				.catch(error => { 
					log.error("Error Loading Products: ", error.message);
		 			props.onError(error);
				})
		} else {
			setProducts(props.products);
			
			if (props.values.productId) {
				let found = props.products.find((item) => (item.productId === props.values.productId))
				if (found) setSelectedProducts(new Array(1).fill(found));
			}
		}
			
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleProductChange = (products) => {
			
		if(isArrayWithLength(products)) { 
			setSelectedProducts(products);
			props.setFieldValue("productId", products[0].productId);
			props.setFieldValue("productName", products[0].name);
			
			//Check if auto-fill for latest unitPrice
			if (props.supplierNo) {
			
				let filter = {};
				filter.pageSize = 1;
				filter.sortBy = "eventDate";
				filter.order = "desc";
				filter.supplierNo = props.supplierNo;
				filter.products = [{"productId": products[0].productId}];
			
				API.pageSupplierOrderItems(filter)
				.then(response => {
						if (isArrayWithLength(response.list)) {
							props.setFieldValue("unitPrice",response.list[0].unitPrice.toFixed(2));
							setUnitPriceSourceCalculation(true);
							calculatePricingTargetFieldValue(props.values.quantity, props.values.discountAmount, response.list[0].unitPrice); 
						}
				})
				.catch(error => { 
					log.error("Error Loading Supplier Order Items: ", error.message);
		 			props.onError(error);
				})
			
			}
		} else { 
			setSelectedProducts([]);
			props.setFieldValue("productId", "");
			props.setFieldValue("productName", "");
			props.setFieldValue("unitPrice", "");
			props.setFieldValue("totalAmount", "");
		}
    }
    
    const handlePricingSourceFieldChange = () => {
			
		setUnitPriceSourceCalculation(!unitPriceSourceCalculation);
    }
    
    
    const handlePricingSourceFieldValueChange = (e) => {
	
		props.onChange(e);
		
		calculatePricingTargetFieldValue(props.values.quantity, props.values.discountAmount, e.target.value); 
	}
		
    const calculatePricingTargetFieldValue = (quantity, discountAmount, pricingSourceFieldValue) => {
			
		if (unitPriceSourceCalculation) {
			
			let unitPrice = parseFloat((pricingSourceFieldValue) ? pricingSourceFieldValue : props.values.unitPrice);
			
			if (unitPrice && quantity) {
			
				let totalAmount = unitPrice * quantity - parseFloat(discountAmount);
				
				props.setFieldValue("totalAmount", totalAmount.toFixed(2));
			}
			
		} else {
			let totalAmount = parseFloat((pricingSourceFieldValue) ? pricingSourceFieldValue : props.values.totalAmount);
			
			if (totalAmount && quantity) {
			
				let unitPrice = (totalAmount+parseFloat(discountAmount)) / quantity;
				
				props.setFieldValue("unitPrice", unitPrice.toFixed(2));
			}
		}
    }
    
    const handleQuantityChange = (valueAsNumber) => {
	
		props.setFieldValue("quantity",valueAsNumber);
		
		calculatePricingTargetFieldValue(valueAsNumber, props.values.discountAmount ); // third argument is null
		
	}
	
	const handleDiscountAmountChange = (e) => {
	
		props.setFieldValue("discountAmount",e.target.value);
		//props.onChange(e);
		
		calculatePricingTargetFieldValue(props.values.quantity, e.target.value); // third argument is null
		
	}
	
	
     
    /*const unitPriceFormControls = (<FormGroup as={Col} md={5} controlId="formGridUnitPrice">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.unitPrice.label"}>Unit Price</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{(config.numberFormat && config.numberFormat.currency) ? currencySymbol(config.preferredLanguage, config.numberFormat.currency) : "$"}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} readOnly={props.readOnlyFields["unitPrice"]} name="unitPrice" isInvalid={!(props.errors.unitPrice == null)} value={props.values.unitPrice} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.unitPrice} />
					</InputGroup>
				</FormGroup>);
				
				
				
	const totalAmountFormControls = (<FormGroup as={Col} md={5} controlId="formGridTotalAmount">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.totalAmount.label"}>Total Amount</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{(config.numberFormat && config.numberFormat.currency) ? currencySymbol(config.preferredLanguage, config.numberFormat.currency) : "$"}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} readOnly={props.readOnlyFields["totalAmount"]} name="totalAmount" isInvalid={!(props.errors.totalAmount == null)} value={props.values.totalAmount} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.totalAmount} />
					</InputGroup>
				</FormGroup>
		
	)*/

	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Row>
				<Col>
				<Highlighter search={properties.text}>
            		{option.name}
         		</Highlighter>,
          		<div>
            		<small>
              			<Trans i18nKey={props.i18nPrefix+'form.productId.menu-item-details'} values={{item: Object.assign({}, option, { sellingPriceFormatted: formatCurrency(option.sellingPrice, config.preferredLanguage, config.numberFormat.currency)})}}/>
            		</small>
          		</div>
				</Col>
				{(props.mockups && props.mockups.get(option.productId)) ?
					<Col md={4} className="text-center"><Image src={props.mockups.get(option.productId)} height="50px"/></Col>
					: null
				}
				</Row>
        	</Fragment>
    	)
    }

	 let pricingSourceField = (unitPriceSourceCalculation) ? "unitPrice" : "totalAmount";
	 let pricingTargetField = (unitPriceSourceCalculation) ? "totalAmount": "unitPrice";
   
	return(
		<Fragment>	
			<Row>
				
				<FormGroup as={Col} md={9} controlId="formGridProduct">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.productId.label"}>Product</Trans> *</FormLabel>
					{/*<FormControl as="select" disabled={props.readOnlyFields["productId"]} name="productId" isInvalid={!(props.errors.productId == null)} value={props.values.productId} onChange={handleProductChange} >
						<option value="">{t(props.i18nPrefix+"form.productId.blank-option")}</option>
		    			{ products.map(item =>
		    				<option key={item.productId} value={item.productId+""}>{item.name}</option>
		    			)}
		    		</FormControl>*/}
					<Typeahead
      						id="productId"
							clearButton
							isInvalid={!(props.errors.productId == null)}
							labelKey={(option) => option.name }
      						onChange={handleProductChange}
      						options={products.filter(p => (!props.lineItems || !props.lineItems.some(li => li.productId === p.productId)))}
      						placeholder={t(props.i18nPrefix+"form.productId.placeholder")}
      						ref={typeaheadRef}
      						selected={selectedProducts}
							renderMenuItemChildren={renderMenuItemChildren}
							disabled={props.readOnly || props.readOnlyFields["productId"]} 
    					/>
					<FormControlErrors errors={props.errors.productId} />
				 </FormGroup>
			
				 <FormGroup as={Col} md={3} controlId="formGridQuantity">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.quantity.label"}>Quantity</Trans> *</FormLabel>
					<NumericInput disabled={props.readOnly} className={!(props.errors.quantity == null) ? "form-control is-invalid" : "form-control" } strict={true} min={1} name="quantity" value={props.values.quantity} onChange={handleQuantityChange} />
					<FormControlErrors block={true} errors={props.errors.quantity} />
				</FormGroup>
				
		</Row>
		
		
		<Row>						
			<FormGroup as={Col} md={7} controlId={"formGridPurchaseType"}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.purchaseType.label"}>Purchase Type</Trans> *</FormLabel>
				    <FormControl as="select" name="purchaseType" disabled={props.readOnly} isInvalid={!(props.errors.entityType == null)} value={props.values.purchaseType} onChange={props.onChange} >
		    			{ SupplierOrderConfig.PurchaseTypes.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.purchaseType.options."+item)}</option>
		    			)}
		    		</FormControl>
		    		<FormControlErrors errors={props.errors.purchaseType} />
				</FormGroup>
				   
				<FormGroup as={Col} md={5} controlId={"formGridDiscountAmount"}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.discountAmount.label"}>Discount Amount</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{(config.numberFormat && config.numberFormat.currency) ? currencySymbol(config.preferredLanguage, config.numberFormat.currency) : "$"}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="discountAmount" disabled={props.readOnly} isInvalid={!(props.errors.discountAmount == null)} value={props.values.discountAmount} onChange={handleDiscountAmountChange} />
						<FormControlErrors errors={props.errors.discountAmount} />
					</InputGroup>
				</FormGroup>
				
			</Row>
			
		<Row>						
				<FormGroup as={Col} md={5} controlId={"formGrid-"+pricingSourceField}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form."+pricingSourceField+".label"}>{pricingSourceField}</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{(config.numberFormat && config.numberFormat.currency) ? currencySymbol(config.preferredLanguage, config.numberFormat.currency) : "$"}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name={pricingSourceField} disabled={props.readOnly} onChange={handlePricingSourceFieldValueChange} isInvalid={!(props.errors[pricingSourceField] == null)} value={props.values[pricingSourceField]} />
						<FormControlErrors errors={props.errors[pricingSourceField]} />
					</InputGroup>
				</FormGroup>
				
				<FormGroup as={Col} md={2} className="text-center" controlId="formGridPriceCalculation">
					<FormLabel>&nbsp;</FormLabel>
					<div>
						<Button disabled={props.readOnly} onClick={handlePricingSourceFieldChange}><FontAwesomeIcon icon={faExchangeAlt} /></Button>
					</div>
				</FormGroup>	
				   
				<FormGroup as={Col} md={5} controlId={"formGrid-"+pricingTargetField}>
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form."+pricingTargetField+".label"}>{pricingTargetField}</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{(config.numberFormat && config.numberFormat.currency) ? currencySymbol(config.preferredLanguage, config.numberFormat.currency) : "$"}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} disabled={true} name={pricingTargetField} isInvalid={!(props.errors[pricingTargetField] == null)} value={props.values[pricingTargetField]} onChange={props.onChange} />
						<FormControlErrors errors={props.errors[pricingTargetField]} />
					</InputGroup>
				</FormGroup>
				
				
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" disabled={props.readOnly} isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>

		</Fragment>
		
	) 
}


export default SupplierOrderLineItemFormFields;
