import React,  { useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import SaveCustomTagModal from "../components/SaveCustomTagModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import SingleFilterForm from "../components/SingleFilterForm";


import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { isArrayWithLength } from "../helpers/commons";
import CustomContainer from './CustomContainer';


const tagToken = {
	backgroundColor: "#e7f4ff",
	color: '#007bff'
};

const CustomTags = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [customTags, setCustomTags] = useState(null);
	const [saveTagModalShow, setSaveTagModalShow] = useState(false);
	const [selectedTag, setSelectedTag] = useState(null);
		
	const [deleteTagModalShow, setDeleteTagModalShow] = useState(false);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	const location = useLocation();
	let context = useOutletContext();

	const mergeQueryParams = (currentValues, newValues) => {
	  
	  let queryParams = {};

	  if (currentValues) {
		  queryParams.sortBy = currentValues.sortBy;
		  queryParams.order = currentValues.order;
		  queryParams.filter = currentValues.filter;
		  queryParams.filteredColumn = currentValues.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	const findCustomTags = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
			context.onError(null);
		
			API.findCustomTags(mergeQueryParams(customTags, values)).then(response => {
				setCustomTags(response);
				resolve(response);
			}).catch(error => { 	
				reject(error);
			});
		});

	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		findCustomTags({})
			.catch(error => { 
				log.error("Error Loading Initial Custom Tag List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		
	}, []);

	if (isLoading) 
		return <Loader />
		
	if (!customTags) 
		return null;
		


  const refreshCustomTags = () => {
	  findCustomTags({})
		.catch(error => { 
			log.error("Error Refreshing Custom Tag List: ", error.message);
	 		context.onError(error);
		})
		
  }

  const handleCustomTagSaved = (success) => {
	  setSaveTagModalShow(false);
	  context.onSuccess(success);
	  refreshCustomTags();
  }
  
  const confirmCustomTagRemoval = (tag) => {
	  setSelectedTag(tag);
	  setDeleteTagModalShow(true);
  }

  const newCustomTag = () => {
	  setSelectedTag(null);
	  setSaveTagModalShow(true);
    }

 const updateCustomTag = (tag) => {
	  setSelectedTag(tag);
	  setSaveTagModalShow(true);
 }
		     
 const deleteCustomTag = (tag) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setDeleteTagModalShow(false);
		
		API.deleteCustomTag(tag.id, config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshCustomTags();
		}).catch(error => { 	
			log.error("Error Removing Custom Tag: ", error.message);
			context.onError(error);		
		});
  }
	

	
		
	return (
		<CustomContainer >
			{(saveTagModalShow) && <SaveCustomTagModal
				show={saveTagModalShow}
	        	onHide={() => setSaveTagModalShow(false)}
				size="sm"
				tag={selectedTag}
				onTagSaved={handleCustomTagSaved}
		      />
			}
			{(selectedTag) && <ConfirmationDialogModal
				item={selectedTag}
				show={deleteTagModalShow}
        		onHide={() => setDeleteTagModalShow(false)}
				size="lg"
				title={t("customtags.delete-tag-confirmation-modal.title")}
				bodyText={t("customtags.delete-tag-confirmation-modal.body", {tag: selectedTag})}
				confirmText={t("customtags.delete-tag-confirmation-modal.confirm")}
				cancelText={t("customtags.delete-tag-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteCustomTag}
			/>}


			<Row className="mb-3">
        		<Col sm={7} xl={8} >
        			<h3>
        				{t("customtags.list.header", {count: customTags.list.length})}
        			</h3>
        		</Col>
				<Col sm={5} xl={4}>
						<FormLabel> </FormLabel>
						<div className="d-flex justify-content-end">
							<Button variant="outline-success" disabled={false} onClick={() => {newCustomTag();}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey="customtags.list.add-button" /></Button>	
						</div>
				</Col>
				
        	</Row>
        	<Row className="mb-3">
    			<Col>
					<SingleFilterForm 
				   		i18nPrefix="customtags.list.form."
						parameters={{ filter: customTags.filter, filteredColumn: customTags.filteredColumn }}
    			    	onSubmit={findCustomTags} 
    			    	onError={context.onError}
					/>
    			</Col>
    		</Row>

			<Row>
        		<Col>
        			
					{ (!isArrayWithLength(customTags.list)) ?
							<span className="font-italic"><Trans i18nKey="customtags.list.no-items"></Trans></span>
						:
							<h4>
								{customTags.list.map((tag, index) => (
									<Badge className="m-1" style={tagToken} key={index} variant="light">
										<Button variant="link" onClick={() => { updateCustomTag(tag); } }>
				      						{tag.name}
				      					</Button>
										<Button variant="link" size="sm" style={{color: "red"}} onClick={() => { confirmCustomTagRemoval(tag);} }>
				      						<FontAwesomeIcon icon={faTimesCircle} />
				      					</Button>
									</Badge>
								 ))}
							</h4>
					 }
					
        		</Col>
        	</Row>


      </CustomContainer>
	);
}

export default CustomTags;
