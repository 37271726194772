import React,  { Fragment } from "react";
import { Trans, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";

import { parseInputDate} from "../helpers/commons";

//import { useConfig } from "../services/use-config";

import moment from "moment";

import '../styles/Loader.css';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	initialCount: Yup.string()
		.required()
		.default(() => ""),
	finalCount: Yup.string()
		.required()
		.default(() => "")
});

//loading component for suspense fallback
const InventoryCountRangePickerModal = props => {
	
	const { t } = useTranslation();
	
	//const config = useConfig();
	
	
	let initialValues = {};
	initialValues.initialCount = props.initialCount.id;
	initialValues.finalCount = props.finalCount.id;
	
	return (
		<Modal 
			show={props.show}
			aria-labelledby="contained-modal-inventory-count-range-picker"
		    centered
			size={props.size}
			animation={false}
			className="daterangepicker-modal"
			onHide={props.onHide}
			>
			<Modal.Header closeButton>
  				<Modal.Title id="contained-modal-inventory-count-range-picker">
  					<Trans i18nKey={props.i18nPrefix+"title"}>Select Inventory Count Range</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnChange={false}
				validateOnBlur={false}     
				onSubmit={(values, actions) => {
					
					props.onChange(Object.assign({}, {id: values.initialCount}), Object.assign({}, {id: values.finalCount}))
			 	
			 		actions.setSubmitting(false);
				}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, submitForm}) => (	
	
		<Form className="form-filter-data" noValidate>

			<Modal.Body >
				<FormGroup as={Row} controlId="formGridInitialCount" >
					<FormLabel column sm="3">
      					<Trans i18nKey={props.i18nPrefix+"inventory-count-range.initialCount"}>Initial Count</Trans>
    				</FormLabel>
					<Col sm="9">
						<FormControl as="select" name="initialCount" isInvalid={!(errors.initialCount == null)} value={values.initialCount} onChange={handleChange} >
		    				<option value=""></option>
		    				{ props.inventoryCounts.map(item =>
		    					<option key={item.id} value={item.id}>
		    						{moment(item.eventDate).format("DD/MMM/YYYY") + " [" + t(props.i18nPrefix+"inventory-count-range.eventTimeOptions."+item.eventTime) + "]"} 
		    					</option>
		    				)}
		    			</FormControl>
						<FormControlErrors errors={errors.initialCount} />
					</Col>
				</FormGroup>
				<FormGroup as={Row} controlId="formGridFinalCount" >
					<FormLabel column sm="3">
      					<Trans i18nKey={props.i18nPrefix+"inventory-count-range.finalCount"}>Final Count</Trans>
    				</FormLabel>
					<Col sm="9">
						<FormControl as="select" name="finalCount" isInvalid={!(errors.finalCount == null)} value={values.finalCount} onChange={handleChange} >
		    				<option value=""></option>
		    				{ props.inventoryCounts.map(item =>
		    					<option key={item.id} value={item.id}>
		    						{moment(item.eventDate).format("DD/MMM/YYYY") + " [" + t(props.i18nPrefix+"inventory-count-range.eventTimeOptions."+item.eventTime) + "]"} 
		    					</option>
		    				)}
		    			</FormControl>
						<FormControlErrors errors={errors.finalCount} />
					</Col>
					</FormGroup>
				</Modal.Body>
          		<Modal.Footer>			 
          			<Button variant="success" type="submit" disabled={isSubmitting}> 
          				{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"applying"}>Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={props.i18nPrefix+"apply"}>Apply</Trans></span>} 
          			</Button>
          			<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey={props.i18nPrefix+"cancel"}>Cancel</Trans></Button>	
          		</Modal.Footer>
			
		</Form>	
		)}
	</Formik>
			 
		</Modal>
	);
};

// Specifies the default values for props:
InventoryCountRangePickerModal.defaultProps = {
  size: "lg"
};


export default InventoryCountRangePickerModal;