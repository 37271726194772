import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext, useNavigate  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import InventoryCountFilterForm from "../components/InventoryCountFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ShowDialogModal from "../components/ShowDialogModal";

import { formatCurrency } from "../helpers/commons";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";
import CustomContainer from './CustomContainer';


const dataTableActionModals = [
	 {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      } 
];

const InventoryCounts = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedInventoryCounts, setPagedInventoryCounts] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
	let navigate = useNavigate();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
				
		pageInventoryCounts({})
			.catch(error => { 
		 		log.error("Error Loading Initial InventoryCounts List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const updateInventoryCount = (item, index) => {
	  navigate("/inventory-counts/update", {state : { item : item }});
  }
  
  const addInventoryCount = () => {
	  navigate("/inventory-counts/create");
  }
  
  const updatePage = (values) => {
	  pageInventoryCounts(values)
		.catch(error => { 
			log.error("Error Paginating InventoryCounts List: ", error.message);
	 		context.onError(error);
		})
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageInventoryCounts(values)
		.catch(error => { 
			log.error("Error Sorting InventoryCounts List: ", error.message);
	 		context.onError(error);
		})
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.products = currentPage.products.map(p => Object.assign({},{productId: p}));
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List InventoryCounts with no new values
	  pageInventoryCounts({})
		.catch(error => { 
	 		log.error("Error Refreshing InventoryCounts List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageInventoryCounts = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageInventoryCounts(mergeQueryParams(pagedInventoryCounts, values)).then(response => {
			setPagedInventoryCounts(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	
	const confirmInventoryCountDeletion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
   }
	
   const deleteInventoryCount = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteInventoryCount(item.id, config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing InventoryCount: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }


   	if (isLoading) 
		return <Loader />
	
	if (!pagedInventoryCounts) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("inventory-counts.delete-inventory-count-confirmation-modal.title")}
				bodyText={t("inventory-counts.delete-inventory-count-confirmation-modal.body", {item: Object.assign({},selectedDataTableItem,{ totalAmount: formatCurrency(selectedDataTableItem.totalAmount, config.preferredLanguage, config.numberFormat.currency)})})}
				confirmText={t("inventory-counts.delete-inventory-count-confirmation-modal.confirm")}
				cancelText={t("inventory-counts.delete-inventory-count-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteInventoryCount}
			/>}
			
			
		<PagedList
			i18nPrefix="inventory-counts.list."
			page={pagedInventoryCounts}
			list={pagedInventoryCounts.list}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={InventoryCountFilterForm}
			filterParameters={{ filter: pagedInventoryCounts.filter, filteredColumn: pagedInventoryCounts.filteredColumn }}
			onFilter={pageInventoryCounts} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addInventoryCount}
			onPage={updatePage}
			dataTableColumns={["eventDate", "eventTime", "totalItems", "products", "whenModified"]} 
			dataTableSortableColumns={["eventDate", "whenModified"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["eventTime", "Enum"],["whenModified", "ZonedDateTime"]]}
			dataTableActions={[updateInventoryCount, confirmInventoryCountDeletion]}	
			dataTableDefaultAction={updateInventoryCount}
			dataTableHandleTextOverflow={true}
		/>
		</CustomContainer>
	);
}

export default InventoryCounts;
