import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';

import FormControlErrors from "../components/FormControlErrors";

import AccountInputSearch from "../components/AccountInputSearch";

import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate, isArrayWithLength, currencySymbol, formatCurrency } from "../helpers/commons";

import NumericInput from 'react-numeric-input';

import { isDesktop } from 'react-device-detect';


import { useAuth } from "../services/use-auth";

import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";


const OrderFormFields = props => {

	const [products, setProducts] = useState([]);	
	const [selectedProducts, setSelectedProducts] = useState([]);	
	
	const typeaheadRef = useRef(null);
	const { t } = useTranslation();
	const config = useConfig();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Products
		if (!isArrayWithLength(props.products)) {
			
			//Filter available products only for non-admin users
			let filter = {};
			if (!auth.isAdmin()) filter = {available: true};
			
			API.findProducts(filter)
				.then(response => {
					if (isMounted) {
						setProducts(response.list);
						if (props.values.productId) {
							let found = response.list.find((item) => (item.productId === props.values.productId))
							if (found) setSelectedProducts(new Array(1).fill(found));
						}
					}
				})
				.catch(error => { 
					log.error("Error Loading Products: ", error.message);
		 			props.onError(error);
				})
		} else {
			setProducts(props.products);
			
			if (props.values.productId) {
				let found = props.products.find((item) => (item.productId === props.values.productId))
				if (found) setSelectedProducts(new Array(1).fill(found));
			}
						
			
		}
			
		 return () => { isMounted = false };
		  
	}, []);
	

    const handleAccountChange = (item) => {
		
        if (!item) {
			props.setFieldValue("accountNo", "");
        	return;
        } 

		props.setFieldValue("accountNo", item.accountNo);

    }

	/*const handleNewAccount = (displayName) => {
		
		let account = {};
		account.displayName = displayName;
		props.onNewAccount(account);
    }*/

	const handleProductChange = (products) => {
			
		if(isArrayWithLength(products)) { 
			setSelectedProducts(products);
			props.setFieldValue("productId", products[0].productId);
			props.setFieldValue("unitPrice", products[0].sellingPrice);
		} else { 
			setSelectedProducts([]);
			props.setFieldValue("productId", "");
			props.setFieldValue("unitPrice", "");
		}
    }

	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Row>
				<Col>
				<Highlighter search={properties.text}>
            		{option.name}
         		</Highlighter>,
          		<div>
            		<small>
              			<Trans i18nKey={props.i18nPrefix+'form.productId.menu-item-details'} values={{item: Object.assign({}, option, { sellingPriceFormatted: formatCurrency(option.sellingPrice, config.preferredLanguage, config.numberFormat.currency)})}}/>
            		</small>
          		</div>
				</Col>
				{(props.mockups && props.mockups.get(option.productId)) ?
					<Col md={4} className="text-center"><Image src={props.mockups.get(option.productId)} height="50px"/></Col>
					: null
				}
				</Row>
        	</Fragment>
    	)
    }

	
	return(
		<Fragment>	
			<Row>
				
				{(isDesktop) ?
				<FormGroup as={Col} md={4} controlId="formGridEventDate">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventDate.label"}>Event Date</Trans> *</FormLabel>
					{props.readOnlyFields["eventDate"] ? 
						<FormControl type={'text'} readOnly name="eventDate" value={moment(props.values.eventDate).format("L")} />
						: 
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
								if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("eventDate", "");
      							}
      						}}
      						date={props.values.eventDate}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
							//readOnly={props.readOnlyFields["eventDate"]}
      						keepInvalid={true}
    					/>
					}
					<FormControlErrors block={true} errors={props.errors.eventDate} />
				</FormGroup>
				: null
				}
				
				<FormGroup as={Col} controlId="formGridAccount">
				   <FormLabel><Trans i18nKey={props.i18nPrefix+"form.accountNo.label"}>Select Account</Trans> *</FormLabel>
					{props.readOnlyFields["accountNo"] ? 
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl type={'text'} readOnly name="customerDisplayName" value={props.values.customerDisplayName} />
						</InputGroup>
						:	
						<AccountInputSearch 
				    		i18nPrefix={props.i18nPrefix+"form."}
				    		onError={props.onError}
				    		isInvalid={!(props.errors.accountNo == null)}
				    		onChange={handleAccountChange}
							accountNo={props.values.accountNo}
							disabled={props.readOnlyFields["accountNo"]}
							filterActiveOnly={!auth.isAdmin()}
							//onNewAccount={handleNewAccount}
				    	/>
					}
					<FormControlErrors block={true} errors={props.errors.accountNo} />
				</FormGroup>
				
			</Row>
			
			<Row>
				
				<FormGroup as={Col} controlId="formGridProduct">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.productId.label"}>Product</Trans> *</FormLabel>
					{/*<FormControl as="select" disabled={props.readOnlyFields["productId"]} name="productId" isInvalid={!(props.errors.productId == null)} value={props.values.productId} onChange={handleProductChange} >
						<option value="">{t(props.i18nPrefix+"form.productId.blank-option")}</option>
		    			{ products.map(item =>
		    				<option key={item.productId} value={item.productId+""}>{item.name}</option>
		    			)}
		    		</FormControl>*/}
					<Typeahead
      						id="productId"
							clearButton
							isInvalid={!(props.errors.productId == null)}
							labelKey={(option) => option.name }
      						onChange={handleProductChange}
      						options={products}
      						placeholder={t(props.i18nPrefix+"form.productId.placeholder")}
      						ref={typeaheadRef}
      						selected={selectedProducts}
							renderMenuItemChildren={renderMenuItemChildren}
							disabled={props.readOnlyFields["productId"]} 
    					/>
					<FormControlErrors errors={props.errors.productId} />
				 </FormGroup>
			
				{(isDesktop) ?
				 <FormGroup as={Col} md={3} controlId="formGridUnitPrice">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.unitPrice.label"}>Unit Price</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} readOnly={props.readOnlyFields["unitPrice"]} name="unitPrice" isInvalid={!(props.errors.unitPrice == null)} value={props.values.unitPrice} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.unitPrice} />
					</InputGroup>
				</FormGroup>
				: null
				}
				
				
				 <FormGroup as={Col} md={3} controlId="formGridQuantity">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.quantity.label"}>Quantity</Trans> *</FormLabel>
					<NumericInput className={!(props.errors.quantity == null) ? "form-control is-invalid" : "form-control" } strict={true} min={1} name="quantity" value={props.values.quantity} onChange={(valueAsNumber) => props.setFieldValue("quantity",valueAsNumber)} />
					<FormControlErrors block={true} errors={props.errors.quantity} />
				</FormGroup>
				
			</Row>

		
		</Fragment>
		
	) 
}


export default OrderFormFields;
