import React, {Fragment} from "react";
import Container from 'react-bootstrap/Container'
import { BrowserView, MobileView} from 'react-device-detect';

const CustomContainer = ({ children, props, ...rest }) => {
	
	return (
		<Fragment>
			<BrowserView>
  				<Container >{children}</Container>
			</BrowserView>
			<MobileView>
  				<Container fluid>{children}</Container>
			</MobileView>
		</Fragment>
	);
};

export default CustomContainer;