import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import FormControlErrors from "../components/FormControlErrors";
import Prompt from "../components/Prompt";
import DismissableFeedback from "../components/DismissableFeedback";
import CustomTagsInputSearch from "../components/CustomTagsInputSearch";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LedgerTransactionConfig from "../config/LedgerTransactionConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveMultipleTransactionsTagsValidationSchema = Yup.object().shape({
	...LedgerTransactionConfig.MultipleTransactionsTagsFormValidationRules
});

const SaveMultipleLedgerTransactionsTagsModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		/*if (props.items) {
			
		}*/
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!props.items) {
		return null
	}
			
	let validationSchema = saveMultipleTransactionsTagsValidationSchema;
	
	let initialValues = validationSchema.default();

	initialValues.transactions = props.items;
	
	const saveMultipleLedgerTransactions = (values) =>
		new Promise((resolve, reject) => {
			API.saveMultipleLedgerTransactions(values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
		});
		
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-multiple-ledger-transactions-tags"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-multiple-ledger-transactions-tags">
  					<Trans i18nKey={"ledger-transactions.save-multiple-transactions-tags.title"}>Save Multiple Ledger Deposits</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						let ledgerTransactions = values.transactions.map((item, index) => {
							let transaction = {};
							transaction.id = item.id;
							transaction.tags = values.tags;
							transaction.utcOffset = values.utcOffset;
																
							return transaction;
						});

						saveMultipleLedgerTransactions(ledgerTransactions)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemsSaved(t('ledger-transactions.save-multiple-transactions-tags.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Multiple Ledger Transactions Tags Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Multiple Ledger Transactions Tags: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-multiple-transactions-tags-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

							<Row className="mb-3 pl-3 pr-3 align-items-center">
  								<Col md={1} sm={2} xs={2} className="text-center p-1">
  									<h1 className={"text-primary"}><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
								</Col>
		  						<Col className="ml-3 text-justified align-middle">
									<Trans i18nKey="ledger-transactions.save-multiple-transactions-tags.body" count={props.items.length}>Save Multiple Ledger Transactions Tags</Trans>
								</Col>
							</Row>
							
  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		      		           			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<Row>
								<FormGroup as={Col} controlId="formGridTags">
									<FormLabel><Trans i18nKey={"ledger-transactions.save-multiple-transactions-tags.form.tags.label"}>Tags</Trans></FormLabel>
									<CustomTagsInputSearch 
										isInvalid={!(errors.tags == null)}
										tags={values.tags} 
										onChange={(items) => setFieldValue("tags", items)}
										onError={props.onError}
										i18nPrefix="ledger-transactions.save-multiple-transactions-tags.form."
									/>
									<FormControlErrors block={true} errors={errors.tags} />
								</FormGroup>
			
							</Row>
								

          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="ledger-transactions.save-multiple-transactions-tags.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"ledger-transactions.save-multiple-transactions-tags.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="ledger-transactions.save-multiple-transactions-tags.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
		</Modal>
	);
}

export default SaveMultipleLedgerTransactionsTagsModal;
