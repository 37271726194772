import { useState, useEffect, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';

import { formatCurrency } from "../helpers/commons";
import API from "./backend-api";
import { HttpError } from "../helpers/custom-errors";

const configContext = createContext();

//Provider component that wraps your app and makes configuration object ...
//... available to any child component that calls useConfiguration().
export function ProvideConfig({ children }) {
	const config = useProvideConfig();
	return <configContext.Provider value={config}>{children}</configContext.Provider>;
}

//Hook for child components to get the configuration object ...
//... and re-render when it changes.
export const useConfig = () => {
	return useContext(configContext);
};

//Provider hook that creates configuration object and handles state
const useProvideConfig = () => {
	const [initialized, setInitialized] = useState(false);
	const [globalError, setGlobalError] = useState(null);
	/*const [config, setConfig] = useState(null);*/
	
	const [availableLanguages, setAvailableLanguages] = useState([]);
	const [preferredLanguage, setPreferredLanguage] = useState(null);
	const [csrfToken, setCsrfToken] = useState(null);
	const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(null);
	const [numberFormat, setNumberFormat] = useState(null);

	const { t, i18n } = useTranslation();
	
    useEffect(() => {
		let isMounted = true; 
		
		 API.getConfig()
			.then((response) => {
				if (isMounted) {
					setCsrfToken(response.csrfToken);
					setAvailableLanguages(response.language.available);
					setPreferredLanguage(response.language.preferred);
					setRecaptchaSiteKey(response.recaptcha.siteKey);
					setNumberFormat(response.numberformat);
				
					//Update with preferred Language
					i18n.changeLanguage(response.language.preferred);
				}
				
				log.info("Environment:", response.environment);
				
			})
			.catch(error => {		
				log.info(error);
				setGlobalError(new HttpError('GENERIC', 500, t('error.api.get-config')));
			})
		 	.finally(() => {
			   if (isMounted) setInitialized(true);
			});
		 
		 return () => { isMounted = false };
		  
	  }, []);
     
     const handleLanguageChange = (lng, cb) => {  
		  API.updateLanguage({language: lng, csrfToken:csrfToken })
			.then((response) => {
				
				log.info("Language Updated Successfully: ", lng);
				
				i18n.changeLanguage(lng);
				setPreferredLanguage(lng);

				cb("success", response.success);
			})
			.catch(error => {		
				
				log.error("Language Updated Failed: ", error);
				
				cb("error", error.message);
			})
	 };
    
    
//Return the config parameters
  return {
	  initialized,
	  globalError,
	  availableLanguages,
	  preferredLanguage,
	  handleLanguageChange,
	  csrfToken,
	  recaptchaSiteKey,
	  numberFormat
  };
  
}