import React from "react";
import Modal from 'react-bootstrap/Modal'
//import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
//import PropTypes from 'prop-types';
//import loaderImg from '../images/beer-loading.gif';

import { parseInputDate} from "../helpers/commons";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import '../styles/Loader.css';
import Container from "react-bootstrap/esm/Container";

//loading component for suspense fallback
const DatePickerModal = props => {

	return (
		<Modal 
			show={props.show}
			aria-labelledby="contained-modal-datepicker"
		    centered
			size={props.size}
			animation={false}
			className="datepicker-modal"
			onHide={props.onHide}
			>
			 <Row>
				<Col className="text-center">
					<DatePicker
      					format={props.format}
      					className={(props.invalid) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.onChange(moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.onChange(e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.onChange("");
      						}
      					}}
      					date={props.date}
      					parseInputDate={parseInputDate}
      					locale={props.locale}
      					keepInvalid={true}
						inline
    				/>
				</Col>
			</Row>
		</Modal>
	);
};

// Specifies the default values for props:
DatePickerModal.defaultProps = {
  size: "sm"
};


export default DatePickerModal;