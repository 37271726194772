import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faAddressBook } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge';

import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const AccountBarTabModal = props => {

	const [_error, _setError] = useState(null);
	const [_success, _setSuccess] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isRegistering, setIsRegistering] = useState(false);
	const [accountBarTab, setAccountBarTab] = useState(null);
	const [accountOrders, setAccountOrders] = useState(null);
	
	const config = useConfig();
	const { t } = useTranslation();
	
	let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.accountNo && props.item.eventDate) {
			
			findOrders({accountNo: props.item.accountNo, filteredColumn: "eventDate", filter: props.item.eventDate})
				.then(response => {
					setAccountOrders(response);
				}).catch(error => {			
					log.error("Error Loading Account Orders: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
			
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		return () => { isMounted = false };
		  
	}, []);
	
	const mergeQueryParams = (currentValues, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentValues) {
		  queryParams.sortBy = currentValues.sortBy;
		  queryParams.order = currentValues.order;
		  queryParams.filter = currentValues.filter;
		  queryParams.filteredColumn = currentValues.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
	  
	  let currentParams = {};
		  
	  findOrders(currentParams)
		.catch(error => { 
	 		log.error("Error Refreshing Account Orders: ", error.message);
	 		_setError(null);
		})	
  }  
	
   const findOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		_setError(null);
		
		API.findOrders(mergeQueryParams(accountOrders, values))
			.then(response => {
					
				let barTab = {};
				barTab.accountNo = props.item.accountNo;
				barTab.eventDate = props.item.eventDate;
				barTab.orders = [];
				barTab.aggregattedOrders = {};
				barTab.customerBill = 0;
				barTab.unregisteredCustomerBill = 0;
						
				response.list.forEach((order, index) => {
				   		
					barTab.orders.push(order);
					barTab.customerBill += order.quantity*order.unitPrice;
					barTab.unregisteredCustomerBill += (!order.transactionId) ? order.quantity*order.unitPrice : 0;
						
					if (!barTab.aggregattedOrders[order.product]) {
						let newProduct = {};
						newProduct.quantity = order.quantity;
						newProduct.description = order.product;
						newProduct.unitPrice = order.unitPrice;
						newProduct.total = order.quantity*order.unitPrice;
							
						//Unregistered orders = without transactionId
						newProduct.status = {};
						newProduct.status.registered =  (order.transactionId) ? order.quantity : 0;
						newProduct.status.unregistered = (!order.transactionId) ? order.quantity : 0;
							
						barTab.aggregattedOrders[order.product] = newProduct;
							
					} else {
							
						barTab.aggregattedOrders[order.product].quantity += order.quantity;
						barTab.aggregattedOrders[order.product].total += order.quantity*order.unitPrice;
							
						//Unregistered orders = without transactionId
						barTab.aggregattedOrders[order.product].status.registered += (order.transactionId) ? order.quantity : 0;
						barTab.aggregattedOrders[order.product].status.unregistered += (!order.transactionId) ? order.quantity : 0;
					}
						
				 });
	
				setAccountBarTab(barTab);
				
				resolve(response);
					
			}).catch(error => {			
				reject(error);
			});
	});
	
	const renderStatus = (item) => {
		
	 let variant = (item.unregistered == 0) ? "info" :  (item.registered == 0) ? "danger" : "warning";
	 let status = (item.unregistered == 0) ? "registered" :  (item.registered == 0) ? "unregistered" : "partially";
	  
	  return <Badge pill variant={variant}><Trans i18nKey={"ordersheets.account-bar-tab-modal.datatable.enums.status."+status} values={{count : item.unregistered}}/></Badge>
   }

	
	if  (!props.item) {
		return null
	} else if (props.item.accountNo) {
		if (!isLoading && !accountBarTab) {
			return null;
		}
	}	
	
	const buildFooterRow = (barTab) => {
		return (<tr className="font-weight-bold" style={{border: "1px"}}>
			<td colSpan="3" className="text-right"><Trans i18nKey="ordersheets.account-bar-tab-modal.datatable.columns.total" />=</td>
			<td>{currency.format(barTab.customerBill)}</td>
			<td>{(barTab.unregisteredCustomerBill != 0 && barTab.customerBill != barTab.unregisteredCustomerBill) ?
					<span className="bg-warning"><Trans i18nKey="ordersheets.account-bar-tab-modal.datatable.columns.totalUnregistered" values={{unregistered: currency.format(barTab.unregisteredCustomerBill)}} /></span> : null
			}</td>
		</tr>)	
	};
			
	const registerAccountOrders = () => {
	
		//Clear Error and Success
 		_setError(null);
 		_setSuccess(null);
 		
		setIsRegistering(true);
		
		API.registerOrderSheetItems({dates: [props.item.eventDate], accounts: [props.item.accountNo]}, config.csrfToken)
		   .then(response => {
				//props.onSuccess(response.success);
				_setSuccess(t('ordersheets.account-bar-tab-modal.register-orders-success'));
				refreshPage();
			}).catch(error => {			
				log.error("Error Registering Account Orders: ", error.message);
		 		_setError(error);
			})
		 	.finally(() => {
		 		setIsRegistering(false);
			}); 
	};
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-account-bar-tab-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-account-bar-tab-modal">
  					<Trans i18nKey={"ordersheets.account-bar-tab-modal.title"} values={{item: props.item}}>Account Bar Tab</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>
					<Modal.Body className="pt-2">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
		  				<DismissableFeedback feedback={_success} onClose={() => _setSuccess(null)} type="success" />	  
						
						<DataTable
							i18nPrefix="ordersheets.account-bar-tab-modal.datatable."
							items={Object.values(accountBarTab.aggregattedOrders)}
							columns={["quantity", "description", "unitPrice", "total", "status"]} 
							customDisplayColumns={[["unitPrice", "Currency"],["total", "Currency"], ["status", renderStatus]]}
							//showTotalRowColumns={["total"]}
							customFooterRow={buildFooterRow(accountBarTab)}
							//onError={props.onError}
						/>
						
						
						
							
          			</Modal.Body>
          			<Modal.Footer>			 
          				{(accountBarTab.unregisteredCustomerBill != 0) ?
          					<Button variant="success" onClick={registerAccountOrders} disabled={isRegistering}> 
          				     	{isRegistering ? <Trans i18nKey="ordersheets.account-bar-tab-modal.registering-orders">Please wait...</Trans> : <span><FontAwesomeIcon icon={faAddressBook} /> <Trans i18nKey={"ordersheets.account-bar-tab-modal.register-orders"}>Register Orders</Trans></span>} 
          					</Button>
          					: null
          				}
						{/*
						<Button variant="success" onClick="handlePayTheBill" disabled={isPaying}> 
          				     {isRegistering ? <Trans i18nKey="ordersheets.account-bar-tab-modal.paying-the-bill">Please wait...</Trans> : <span><FontAwesomeIcon icon={faDollarSign} /> <Trans i18nKey={"ordersheets.account-bar-tab-modal.pay-the-bill"}>Pay the Bill</Trans></span>} 
          				</Button>*/}
          				<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="ordersheets.account-bar-tab-modal.close">Close</Trans></Button>	
          			</Modal.Footer>	
				</Fragment>
			)}
		</Modal>
	);
}

export default AccountBarTabModal;
