import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext   } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

//import Container from 'react-bootstrap/Container'

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import AccountStatementFilterForm from "../components/AccountStatementFilterForm";
import Filler from "../components/Filler";

import SaveAccountTransactionModal from "../components/SaveAccountTransactionModal";
import SaveMultipleAccountDepositsModal from "../components/SaveMultipleAccountDepositsModal";
import AccountTransactionsModal from "../components/AccountTransactionsModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import SendAccountStatementModal from "../components/SendAccountStatementModal";
import AccountActivitiesModal from "../components/AccountActivitiesModal";

import AccountStatementConfig from "../config/AccountStatementConfig";
import MessageConfig from "../config/MessageConfig";

import { formatNumber } from "../helpers/commons";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";

import { jsonToCSV } from "react-papaparse";

import * as Yup from "yup";
import { setLocale } from 'yup';
import CustomContainer from './CustomContainer';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const dataTableActionModals = [
	  {
        key: 'action-0',
        component: AccountTransactionsModal,
		size: 'xl',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
      {
        key: 'action-2',
        component: SaveAccountTransactionModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-3',
        component: AccountActivitiesModal,
		size: 'xl',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleAccountDepositsModal,
		size: 'xl',
		multiple : true
      },
       {
        key: 'multiple-action-1',
        component: Filler,
		size: 'xl',
		multiple : true
      }
];

const AccountStatements = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isProcessingBackendTransaction, setIsProcessingBackendTransaction] = useState(false);
	const [pagedAccountStatements, setPagedAccountStatements] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let context = useOutletContext();
	
	let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageAccountStatements({sortBy: "currentBalance"})
			.catch(error => { 
		 		log.error("Error Loading Initial Account Statements List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

    const buildAccountPageUrl = (token) => {

		return window.location.protocol + "//" + window.location.host + "/account/?token=" + token

  	};
  	
  	const confirmSendingAccountStatement = (item, actionIndex) => {	
	  
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+actionIndex)
    } 
    
    const sendAccountStatement = (item, channel) => {
	
		handleActionModalHide();
		
		if (channel == "WHATSAPP_WEB") {
			sendAccountStatementWhatsappWeb(item);
		} else if (channel == "WHATSAPP_API") { 
			let statement = {};
	  		statement.accountNo = item.accountNo;
	  		statement.customerDisplayName = item.customerDisplayName;
	  		statement.currentBalance = currency.format(item.currentBalance);
	  		
	  		sendAccountStatementWhatsappAPI(statement);
	  		
		}
	}
	
	const sendAccountStatementWhatsappAPI = (item) => {
		
		//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setIsProcessingBackendTransaction(true);
	  
	  API.sendAccountStatement(item, config.csrfToken).then(response => {
		 context.onSuccess(response.success);
	  }).catch(error => {			
		log.error("Error Sending Account Statements Message: ", error.message);
	 	context.onError(error);
	  }).finally(() => {
			setIsProcessingBackendTransaction(false);
	  });	
				
    }

	const sendAccountStatementWhatsappWeb = (item) => {
	  
		API.getAccountStatementInfo(item.accountNo).then(response => {
			
			//console.log(item, response);
			
			if (!response.mobilePhone) {
				context.onError(new Error(t("account-statements.list.send-statement.missing-mobile-number")));
			} else {
			
				let number = response.mobilePhone.replace(/[^\w\s]/gi, "").replace(/ /g, "");

				let messageInfo = {};
				messageInfo.customerDisplayName = item.customerDisplayName;
				messageInfo.currentBalance = currency.format(item.currentBalance);
				messageInfo.pixPublicKey = response.pixPublicKey;
				messageInfo.accountPageUrl = buildAccountPageUrl(response.accessToken);
			
				const lineBreak = "%0a%0a";
				let statementMessage = encodeURI(t("account-statements.list.send-statement.message.header"));
				statementMessage += lineBreak + encodeURI(t("account-statements.list.send-statement.message.greeting",{item: messageInfo}));
				statementMessage += lineBreak + encodeURI(t("account-statements.list.send-statement.message.current-balance",{item: messageInfo}));
				statementMessage += lineBreak + encodeURI(t("account-statements.list.send-statement.message.payment-instructions",{item: messageInfo}));
				statementMessage += lineBreak + encodeURI(t("account-statements.list.send-statement.message.statement-details",{item: messageInfo}));
			
			
				// Appending the phone number to the URL
				let url = `https://api.whatsapp.com/send?phone=${number}`;
    			// Appending the message to the URL by encoding it
      			url += `&text=${statementMessage}`;

    			// Open our newly created URL in a new tab to send the message
      			setTimeout(() => {
   					 //window.open(url, '_blank');
					 window.open(url);
				})
				

			}

		}).catch(error => {			
			log.error("Error Looking Account: ", error.message);
	 		context.onError(error);
		});		
		
    }
    
    /*const sendAccountStatementWhatsappAPI = (item) => {
	  
		API.getAccountStatementInfo(item.accountNo).then(response => {
			
			
			if (!response.mobilePhone) {
				context.onError(new Error(t("account-statements.list.send-statement.missing-mobile-number")));
			} else {
			
				let message = messageValidationSchema.default();
				
				message.recipient = response.mobilePhone;
				
				message.contentType = "CONTENT_SID";
				
				message.content = "accountStatementNotification";

				message.contentVariables = {};
				message.contentVariables[1] = item.customerDisplayName;
				message.contentVariables[2] = currency.format(item.currentBalance);
				message.contentVariables[3] = response.pixPublicKey;
				message.contentVariables[4] = response.accessToken;
				
				
				API.createMessage(message, config.csrfToken).then(response => {
					context.onSuccess("account-statements.list.send-statement.success");
				}).catch(error => {			
					log.error("Error Creating Message: ", error.message);
	 				context.onError(error);
				});	
				
			}

		}).catch(error => {			
			log.error("Error Getting Account Statement Info: ", error.message);
	 		context.onError(error);
		});		
		
    }*/

	const createAccountDeposit = (accountBalance) => {
	  
		let item = {};
		item.accountNo = accountBalance.accountNo;
		item.customerDisplayName = accountBalance.customerDisplayName;
		item.amount = Math.abs(accountBalance.currentBalance);
		item.ledgerNo = "700"; // CASH (ASSET) Increase = DEBIT
		item.xactTypeCode = "DEBIT"; 
		item.xactTypeCodeExt = "DEPOSIT"; // Account (ASSET) Decrease = CREDIT
		item.remarks = t("account-statements.list.create-deposit.remarks.default");
		
		setSelectedDataTableItem(item);
	    showActionModal("action-2")
    }
  
  	const listAccountTransactions = (item) => {
	  	
		setSelectedDataTableItem(item);
	    showActionModal("action-0")
    }
    
    const listAccountActivities = (item) => {
	  			
		setSelectedDataTableItem(item);
	    showActionModal("action-3");
    }

	const createMultipleAccountDeposits = (items, actionIndex) => {
	  
		let deposits = items.map((item, index) => {
			let t = {};
			t.accountNo = item.accountNo;
			t.customerDisplayName = item.customerDisplayName;
			t.amount = Math.abs(item.currentBalance);
			t.ledgerNo = "700"; // CASH (ASSET) Increase = DEBIT
			t.xactTypeCode = "DEBIT"; 
			t.xactTypeCodeExt = "DEPOSIT"; 
			
			return t;
		});
		
	  	setSelectedDataTableItems(deposits);
	 	showActionModal("multiple-action-"+actionIndex)
    }
    
    const sendMultipleAccountStatements = (items) => {
		
		//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		handleActionModalHide();
		
		setIsProcessingBackendTransaction(true);
	  
	  API.sendMultipleAccountStatements(items, config.csrfToken).then(response => {
		if (response.errorCount == 0) {
			context.onSuccess(response.success);
		} else { 
			context.onWarning(t("account-statements.list.send-statement.success-with-errors", {successCount: response.successCount, totalCount: response.totalCount}));
		}	  
	  }).catch(error => {			
		log.error("Error Sending Multiple Account Statements Message: ", error.message);
	 	context.onError(error);
	  }).finally(() => {
			setIsProcessingBackendTransaction(false);
	  });	
				
    }
    
    const confirmSendingMultipleAccountStatements = (items, actionIndex) => {

	  let statements = items.map((item, index) => {
			let t = {};
			t.accountNo = item.accountNo;
			t.customerDisplayName = item.customerDisplayName;
			t.currentBalance = currency.format(item.currentBalance);			
			return t;
		});
		
	  setSelectedDataTableItems(statements);
	  showActionModal("multiple-action-"+actionIndex)
  }


  const updatePage = (values) => {
	  pageAccountStatements(values)
		.catch(error => { 
			log.error("Error Paginating Account Statements List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageAccountStatements(values)
		.catch(error => { 
			log.error("Error Sorting Account Statements List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.accountNo = currentPage.accountNo;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List AccountStatements with no new values
	  pageAccountStatements({})
		.catch(error => { 
	 		log.error("Error Refreshing AccountStatements List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageAccountStatements = (values) => {
	
	return new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageAccountStatements(mergeQueryParams(pagedAccountStatements, values)).then(response => {
			setPagedAccountStatements(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	}
	
	
	const exportAccountStatements = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedAccountStatements, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findAccountStatements(exportFilters).then(response => {

			let columns = AccountStatementConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
					
				row.totalCredit = formatNumber(r.totalCredit, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.totalDebit = formatNumber(r.totalDebit, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.currentBalance = formatNumber(r.currentBalance, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("account-statements.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});

	const displayCreditOrDebit = (item) => { 
		return (AccountStatementConfig.TransactionTypeOperationMap["DEBIT"].includes(item.xactTypeCodeExt)) ? "debit-row" : "credit-row";
  	}

   	if (isLoading) 
		return <Loader />
	
	if (!pagedAccountStatements) 
		return null;
		
	return (
		<CustomContainer>
			{(isProcessingBackendTransaction) && <Loader />}
			
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="account-statements"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="account-statements"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
    		
    		{(selectedDataTableItem) && <SendAccountStatementModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				onSend={sendAccountStatement}
			/>}
			
    		{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("account-statements.send-multiple-account-statements-confirmation-modal.title")}
				bodyText={t("account-statements.send-multiple-account-statements-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("account-statements.send-multiple-account-statements-confirmation-modal.confirm")}
				cancelText={t("account-statements.send-multiple-account-statements-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={sendMultipleAccountStatements}
			/>}
			
		<PagedList
			i18nPrefix="account-statements.list."
			page={pagedAccountStatements}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={AccountStatementFilterForm}
			filterParameters={{ filter: pagedAccountStatements.filter, filteredColumn: pagedAccountStatements.filteredColumn }}
			onFilter={pageAccountStatements} 
			onExport={exportAccountStatements} 
			onError={context.onError}
			onSort={sortDataTable}
			//onAdd={addAccountStatement}
			onPage={updatePage}
			dataTableColumns={["accountNo", "customerDisplayName", "totalCredit", "totalDebit", "currentBalance"]} 
			dataTableSortableColumns={["accountNo", "currentBalance"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"], ["totalCredit", "Currency"], ["totalDebit", "Currency"], ["currentBalance", "Currency"]]}
			dataTableActions={[listAccountTransactions, confirmSendingAccountStatement, createAccountDeposit, listAccountActivities]}	
			dataTableDefaultAction={listAccountTransactions}
			dataTableMultipleSelectActions={[createMultipleAccountDeposits, confirmSendingMultipleAccountStatements]}
			dataTableHandleTextOverflow={true}
			//dataTableCustomRowStyle={displayCreditOrDebit}	
		/>
		</CustomContainer>
	);
}

export default AccountStatements;
