import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Prompt from "../components/Prompt";
import DismissableFeedback from "../components/DismissableFeedback";
import MultipleAccountDepositsFormFields from "../components/MultipleAccountDepositsFormFields";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import AccountTransactionConfig from "../config/AccountTransactionConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveMultipleDepositsValidationSchema = Yup.object().shape({
	...AccountTransactionConfig.MultipleDepositsFormValidationRules
});

const SaveMultipleAccountDepositsModal = props => {

	const [_error, _setError] = useState(null);
	//const [isLoading, setIsLoading] = useState(true);
	//const [order, setAccountTransaction] = useState(null);
	//const [showSaveAccountModal, setShowSaveAccountModal] = useState(false);
	//const [newAccount, setNewAccount] = useState(null);	
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		/*if (props.items) {
			
		}*/
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!props.items) {
		return null
	}
			
	
	
	
	let validationSchema = saveMultipleDepositsValidationSchema;
	
	let initialValues = validationSchema.default();
	initialValues.remarks = t("account-transactions.save-multiple-deposits.form.remarks.default");

	initialValues.deposits = props.items.map((item, index) => {
		let deposit = {};
		deposit.accountNo = item.accountNo;
		deposit.customerDisplayName = item.customerDisplayName;
		deposit.amount = item.amount;
		deposit.ledgerNo =  item.ledgerNo;
		deposit.xactTypeCode = item.xactTypeCode;
		deposit.xactTypeCodeExt = item.xactTypeCodeExt;
				
		return deposit;
							
	});

	
	const saveMultipleAccountDeposits = (values) =>
		new Promise((resolve, reject) => {
			API.saveMultipleAccountTransactions(values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
		});
		
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-multiple-account-deposits"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-multiple-account-deposits">
  					<Trans i18nKey={"account-transactions.save-multiple-deposits.title"}>Save Multiple Account Deposits</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						let accountDeposits = values.deposits.map((item, index) => {
							let deposit = {};
							deposit.accountNo = item.accountNo;
							deposit.ledgerNo =  item.ledgerNo;
							deposit.xactTypeCode = item.xactTypeCode;
							deposit.xactTypeCodeExt = item.xactTypeCodeExt;
							deposit.amount = item.amount;
							
							deposit.eventDate = values.eventDate;
							deposit.remarks = values.remarks;
							deposit.utcOffset = values.utcOffset;
														
							return deposit;
							
						});

						saveMultipleAccountDeposits(accountDeposits)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemsSaved(t('account-transactions.save-multiple-deposits.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Multiple Account Deposits Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Multiple Account Deposits: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-multiple-deposits-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		      		           			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

								<MultipleAccountDepositsFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={props.onError}
									onSuccess={props.onSuccess}
									onWarning={props.onWarning}
									onInfo={props.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									origin={props.origin}
									i18nPrefix="account-transactions.save-multiple-deposits."
								/>

          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="account-transactions.save-multiple-deposits.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"account-transactions.save-multiple-deposits.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="account-transactions.save-multiple-deposits.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
		</Modal>
	);
}

export default SaveMultipleAccountDepositsModal;
