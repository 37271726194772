import React, { Fragment, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
//import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Image from 'react-bootstrap/Image';

//import DismissableFeedback from "../components/DismissableFeedback";

import ReactAudioPlayer from 'react-audio-player';
import LazyImage from "../components/LazyImage";
import PDFViewer from "../components/PDFViewer";

const MediaFileDetailModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();

//

	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-media-file-detail"
		    centered
		    animation={false}
		    >
				<Row className="text-center m-3">
					<Col>
						{(props.item.type === "IMAGE")
							? <LazyImage className="img-fluid" src={props.item.url} /> 
							: (props.item.type === "AUDIO")
								? <ReactAudioPlayer
  									src={props.item.url}
  									autoPlay
  									controls
									/>
								: (props.item.type === "PDF")
									? <PDFViewer src={props.item.url} /> 
									: <Trans i18nKey="components.media-file-detail-modal.type-not-implemented" values={{type: props.item.type}} />
						}
						<p><a href={props.item.url} target="_blank"><Trans i18nKey="components.media-file-detail-modal.open-new-window" /></a></p>
					</Col>
					
				</Row>
				
		   </Modal>
	);
}

export default MediaFileDetailModal;
