import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import { ValidationError } from "../helpers/custom-errors";

import SupplierConfig from "../config/SupplierConfig";

import { useConfig } from "../services/use-config";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape(SupplierConfig.FilterFormValidationRules);

const SupplierFilterForm = props => {
		
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		 return () => { isMounted = false };
		  
	}, []);
	
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	Object.keys(SupplierConfig.FilterFormValidationRules)
		.forEach((key) => {
				initialValues[key] = (props.parameters[key]) ? props.parameters[key] : defaultValues[key];
		});
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
				
					if (error instanceof ValidationError) {		
    									
    						log.info("Filtering Items Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Filtering Items: ", error.message);
    					}
    								
    					props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
	
			<Form className="form-filter-data" noValidate>
				<fieldset>
					<div className={"border rounded p-4"}>
						<FormGroup as={Row} controlId="formGridName">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"filter.label"}>Name</Trans>
    						</FormLabel>
						    <Col sm="8">
								<InputGroup >
								   <InputGroup.Prepend>
										<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
									</InputGroup.Prepend>
								   <FormControl type={'search'} name="filter" isInvalid={!(errors.filter == null)} value={values.filter} onChange={handleChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
					               <FormControlErrors errors={errors.filter} />
				               </InputGroup>
   							</Col>
						</FormGroup>
						
						<FormGroup as={Row} controlId="formGridMobilePhone" className="mb-1">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"mobilePhone.label"}>Mobile Phone</Trans>
    						</FormLabel>
							<Col sm="8">
								<PhoneInput
  									placeholder={t(props.i18nPrefix+"mobilePhone.placeholder")}
  									value={values.mobilePhone}
  									onChange={(value) => {setFieldValue("mobilePhone", (typeof value == 'undefined') ? "" : value);}}
  									isInvalid={!(errors.mobilePhone == null)}
  									defaultCountry="BR"
  									international
  									countryCallingCodeEditable={false}
  									name="mobilePhone"
  									inputComponent={FormControl}
  								/>
			    				{!(errors.mobilePhone == null) 
		          					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.mobilePhone} /></div>
									: null
								}	
							</Col>
							
							<Col sm="2">
								<Button type="submit" disabled={isSubmitting} onClick={() => {}}> 
									{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
								</Button>
							</Col>
						</FormGroup>
				
					</div>
				</fieldset>
			</Form>	
		)}
	</Formik>
	)

}

export default SupplierFilterForm;
