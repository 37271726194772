import React, {useState, useEffect, useRef, Fragment} from "react";
import {Trans } from 'react-i18next';
import log from 'loglevel';

import Container from 'react-bootstrap/Container'
import Pagination from 'react-bootstrap/Pagination'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import '../styles/PDFViewer.css';

const PDFViewerPagination = props => {
		
	if (!props.numPages || props.numPages === 1)
		return null;
	
	return (
		<Row>
        	<Col>
				<Pagination className="justify-content-center">
					<Pagination.Prev disabled={props.pageNumber <= 1} onClick={() => {props.updatePage(props.pageNumber-1);}}><FontAwesomeIcon icon={faAngleLeft} /> <Trans i18nKey="pagination.previous"/></Pagination.Prev>
					<Pagination.Item active><Trans i18nKey="pagination.displayingPage" values={{pageNumber: props.pageNumber, numPages: props.numPages}} /></Pagination.Item>
					<Pagination.Next disabled={props.pageNumber >= props.numPages} onClick={() => { props.updatePage(props.pageNumber+1);}}><Trans i18nKey="pagination.next"/> <FontAwesomeIcon icon={faAngleRight} /></Pagination.Next>
				</Pagination>
			</Col>
        </Row>
	);
}

const PDFViewer = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pdfDoc, setPdfDoc] = useState(true);
	const canvasRef = useRef(null);
    const renderTaskRef = useRef(null); // Ref to store the current render task
	const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    
    useEffect(() => {
    	let isCancelled = false;

    	(async function () {
      	// Import pdfjs-dist dynamically for client-side rendering
      		const pdfJS = await import('pdfjs-dist/build/pdf');
     
      		// Set up the worker
      		pdfJS.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfJS.version}/build/pdf.worker.min.mjs`;

      		// Load the PDF document
      		const pdf = await pdfJS.getDocument(props.src).promise;

      		// Get the first page
      		const page = await pdf.getPage(pageNumber);
      		const viewport = page.getViewport({ scale: scale });

      		// Prepare the canvas
     		const canvas = canvasRef.current;
      		const canvasContext = canvas.getContext('2d');
      		canvas.height = viewport.height;
      		canvas.width = viewport.width;

      		// Ensure no other render tasks are running
      		if (renderTaskRef.current) {
        		await renderTaskRef.current.promise;
      		}

      		// Render the page into the canvas
      		const renderContext = { canvasContext, viewport };
     		const renderTask = page.render(renderContext);

      		// Store the render task
      		renderTaskRef.current = renderTask;

      		// Wait for rendering to finish
     		try {
        		await renderTask.promise;
      		} catch (error) {
        		if (error.name === 'RenderingCancelledException') {
          			log.info("PDF document rendering canceled.");
        		} else {
          			console.error('Render error:', error);
          			log.error("Error rendering PDF document: ", error);
        		}
      		} finally {
				setPdfDoc(pdf);
				setNumPages(pdf.numPages)
				setIsLoading(false);
			}

      		if (!isCancelled) {
        		log.info("PDF document rendering completed.");
      		}
      		
      		
      		
    	})();

    	// Cleanup function to cancel the render task if the component unmounts
    	return () => {
      		isCancelled = true;
      		if (renderTaskRef.current) {
        		renderTaskRef.current.cancel();
      		}
    	};
  	}, []);
     
     const handleUpdatePage = async (num) => {
		
		const page = await pdfDoc.getPage(num);
		const viewport = page.getViewport({ scale: scale });

      	// Prepare the canvas
     	const canvas = canvasRef.current;
      	const canvasContext = canvas.getContext('2d');
      	canvas.height = viewport.height;
      	canvas.width = viewport.width;
      		
      	// Ensure no other render tasks are running
      	if (renderTaskRef.current) {
        	await renderTaskRef.current.promise;
      	}
      	
      	// Render the page into the canvas
      	const renderContext = { canvasContext, viewport };
     	const renderTask = page.render(renderContext);

      	// Store the render task
      	renderTaskRef.current = renderTask;

      	// Wait for rendering to finish
     	try {
        	await renderTask.promise;
      	} catch (error) {
        	if (error.name === 'RenderingCancelledException') {
          			log.info("PDF document rendering canceled.");
        		} else {
          			console.error('Render error:', error);
          			log.error("Error rendering PDF document: ", error);
        		}
      		} finally {
				setPageNumber(num);
			}
     }
  
	return (
	   <Container fluid>
	   	  <Spinner
  			className="mb-3"
  			style={(isLoading) ? {} : {display: "none"} }
  			animation="border"
		  />
		  <div className="pdfviewer_document" style={(isLoading) ? {display: "none"} : {}}>
      		<canvas ref={canvasRef}/>
      		<PDFViewerPagination 
        		pageNumber={pageNumber}
        		numPages={numPages}
        		updatePage={handleUpdatePage}
        	/>
        </div>
   	</Container>
	)
};


export default PDFViewer;