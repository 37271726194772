import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext   } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
import moment from "moment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
//import Tooltip from 'react-bootstrap/Tooltip'

import CustomContainer from "../components/CustomContainer";
import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ShowDialogModal from "../components/ShowDialogModal";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";


const dataTableActionModals = [
	{
        key: 'action-0',
        component: Filler,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: Filler,
		size: 'lg',
		multiple : true
      }
];

const Toolbar = props => {
	
	/*const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );*/
	
	return(
		<Fragment>
			<Row className="mb-3">
				<Col md={"auto"}>
					<ButtonToolbar>
  						<ButtonGroup className="mr-2" >
    						<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().subtract(1, 'months'))}}><FontAwesomeIcon icon={faAngleLeft} /></Button>
							<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().add(1, 'months'))}}><FontAwesomeIcon icon={faAngleRight} /></Button>
  						</ButtonGroup>
					</ButtonToolbar>
				</Col>
				<Col >
					<h3 className="d-flex justify-content-center"><Moment local format="MMMM/YYYY">{props.eventDate}</Moment></h3>
				</Col>
				<Col md={"auto"}>
				</Col>
			</Row>
		</Fragment>	
	);

}

const ManageOrderSheets = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [orderSheets, setOrderSheets] = useState(null);
	const [eventDate, setEventDate] = useState(moment(new Date()));
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	//let history = useNavigate();
	const config = useConfig();
	const { t } = useTranslation();
	let context = useOutletContext();
	
	/*let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});*/
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
		
		findOrderSheets({start: eventDate.startOf('month').format('YYYY-MM-DD'), end: eventDate.endOf('month').format('YYYY-MM-DD')})
			.catch(error => { 
		 		log.error("Error Loading Initial Orders Sheets: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }
	

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

    
  const sortDataTable = (values) => {
	 
	console.log(values);
	 findOrderSheets(values)
		.catch(error => { 
			log.error("Error Sorting Order Sheets List: ", error.message);
	 		context.onError(error);
		})
		
  }

const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;

		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

  const confirmOrderSheetRegistration = (item, actionIndex) => {
	  setSelectedDataTableItem(item);
	  if (item.totalPendingOrders > 0) showActionModal("action-0")
	  else showActionModal("action-1")
  }
  
  const confirmMultipleOrderSheetsRegistration = (items, actionIndex) => {
	  setSelectedDataTableItems(items);
	  showActionModal("multiple-action-0");
  }

  const registerOrderSheetItems = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.registerOrderSheetItems({dates: [item.eventDate]}, config.csrfToken).then(response => {
			//context.onSuccess(response.success);
			context.onSuccess(t('ordersheets.register-ordersheet-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Registering Order Sheet Items: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
  
  const registerMultipleOrderSheetItems = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.registerOrderSheetItems({dates: items.map(i => i.eventDate)}, config.csrfToken).then(response => {
			//context.onSuccess(response.success);
			context.onSuccess(t('ordersheets.register-multiple-ordersheets-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Registering Multiple Order Sheets Items: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
  
 const handleEventDateChange = (newEventDate) => {
		  
      let currentEventDate = eventDate;

	  setIsRefreshing(true);
	  setEventDate(newEventDate);

	  //If month has changed, we must update birthday
	  if (currentEventDate.month() !== newEventDate.month()) {
	  
	  	//List Orders with no new values
	  	findOrderSheets({start: newEventDate.startOf('month').format('YYYY-MM-DD'), end: newEventDate.endOf('month').format('YYYY-MM-DD')})
			.catch(error => { 
	 			log.error("Error Refreshing Order Sheets List: ", error.message);
	 			context.onError(error);
			}).finally(() => {
		 		setIsRefreshing(false);
			});	
	  } else {
		setIsRefreshing(false);
	  }
  }  
  
 const refreshPage = () => {
		  
	  //List Orders with no new values
	  findOrderSheets({})
		.catch(error => { 
	 		log.error("Error Refreshing Order Sheets List: ", error.message);
	 		context.onError(error);
		})
  }  

  const findOrderSheets = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
	
		API.findOrderSheets(mergeQueryParams(orderSheets, values)).then(response => {
			setOrderSheets(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const displayNoPendingRevenue = (item) => { 
		return (item.totalPendingOrders === 0) ? "inactive-row" : "";
  	}
   

   	if (isLoading) 
		return <Loader />
	
	if (!orderSheets) 
		return null;
		
	return (
		<CustomContainer>
			{isRefreshing ? <Loader show={isRefreshing}/> : null}
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="order-sheets-manage"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="order-sheets-manage"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
		
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem && dataTableActionModalsShowMap.get("action-0")) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-0")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("ordersheets.register-ordersheet-confirmation-modal.title")}
				bodyText={t("ordersheets.register-ordersheet-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("ordersheets.register-ordersheet-confirmation-modal.confirm")}
				cancelText={t("ordersheets.register-ordersheet-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={registerOrderSheetItems}
			/>}
			
			{(selectedDataTableItem && dataTableActionModalsShowMap.get("action-1")) && <ShowDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("ordersheets.no-pending-revenue-show-dialog-modal.title")}
				bodyText={t("ordersheets.no-pending-revenue-show-dialog-modal.body", {item: selectedDataTableItem})}
				closeText={t("ordersheets.no-pending-revenue-show-dialog-modal.close")}
				variant="info"
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-0")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("ordersheets.register-multiple-ordersheets-confirmation-modal.title")}
				bodyText={t("ordersheets.register-multiple-ordersheets-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("ordersheets.register-multiple-ordersheets-confirmation-modal.confirm")}
				cancelText={t("ordersheets.register-multiple-ordersheets-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={registerMultipleOrderSheetItems}
			/>}
			

		<Toolbar
			i18nPrefix="ordersheets.manage.toolbar."
			eventDate={eventDate}
			variant="outline-primary"
			onEventDateChange={handleEventDateChange}
		/>
		
		<DataTable
			i18nPrefix="ordersheets.manage.datatable."
			items={orderSheets.list}
			columns={["eventDate", "totalSalesVolume", "totalSales", "totalOrders", "totalPendingOrders", "totalAccounts"]} 
			sortableColumns={["eventDate"]}
			sortBy={orderSheets.sortBy}
        	order={orderSheets.order}
			customDisplayColumns={[["eventDate", "LocalDate"],["totalSales", "Currency"]]}
			showTotalRowColumns={["totalAccounts","totalPendingOrders", "totalOrders", "totalSalesVolume", "totalSales"]} 
			actions={[confirmOrderSheetRegistration]}
			defaultAction={confirmOrderSheetRegistration}
			multipleSelectActions={[confirmMultipleOrderSheetsRegistration]}
			onError={context.onError}
			sortDataTable={sortDataTable}
			customRowStyle={displayNoPendingRevenue}
			noItemsDisclaimer={true}
		/>
			
		</CustomContainer>
	);
}

export default ManageOrderSheets;
