import * as Yup from "yup";

const Subjects = [
	"USER",
	"ACCOUNT",
	"PRODUCT",
	"ORDER",
	"LEDGER",
	"ACCOUNT_TRANSACTION",
	"LEDGER_TRANSACTION",
	"CUSTOM_TAG",
	"SUPPLIER",
	"SUPPLY_SCHEME",
	"SUPPLIER_ORDER",
	"INVENTORY_COUNT",
	"MESSAGE"
	
]

const SubjectActions = {
	"USER":	[
		"LOGIN",
		"LOGOUT",
	 	"CHANGE_PASSWORD",
		"FORGOT_PASSWORD",
	 	"RESET_PASSWORD",
		"PASSWORD_UPDATE",
	 	"SELF_PASSWORD_UPDATE",
		"CREATE",
		"UPDATE",
	],
	"ACCOUNT":	[
		"CREATE",
		"UPDATE",
		"IMPORT"
	],
	"PRODUCT":	[
		"CREATE",
		"UPDATE",
	],
	"ORDER":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"LEDGER":	[
		"CREATE",
		"UPDATE",
	],
	"ACCOUNT_TRANSACTION":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"LEDGER_TRANSACTION":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"CUSTOM_TAG":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"SUPPLIER":	[
		"CREATE",
		"UPDATE",
	],
	"SUPPLY_SCHEME":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"SUPPLIER_ORDER":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"INVENTORY_COUNT":	[
		"CREATE",
		"UPDATE",
	],
	"MESSAGE":	[
		"CREATE",
		"UPDATE",
	],
}

const FilterFormValidationRules = {
	   subject: Yup.string()
			.oneOf([""].concat(Subjects), 'form.validation.string.enum')
			.default(() => ""),
	   action: Yup.string()
			.when('subject', (subject, schema) => {
			if (Object.keys(SubjectActions).includes(subject)) {
				return Yup.string()
					.oneOf(SubjectActions[subject], 'form.validation.string.enum');		
	        }
			return schema;
        })
			.default(() => ""),
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => ""),
	   start: Yup.string()
			.default(() => ""),
	   end: Yup.string()
			.default(() => ""),
};

const ActivityConfig = { Subjects, SubjectActions, FilterFormValidationRules };

export default ActivityConfig;