import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext, useNavigate  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SupplierOrderFilterForm from "../components/SupplierOrderFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ShowDialogModal from "../components/ShowDialogModal";

//import SaveSupplierOrderModal from "../components/SaveSupplierOrderModal";

import RegisterSupplierOrderExpenseTransactionModal from "../components/RegisterSupplierOrderExpenseTransactionModal";

import { formatCurrency, formatNumber } from "../helpers/commons";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";
import SupplierOrderConfig from '../config/SupplierOrderConfig';
import CustomContainer from './CustomContainer';

const dataTableActionModals = [
	 {
        key: 'action-1-a',
        component: RegisterSupplierOrderExpenseTransactionModal,
		size: 'lg',
		multiple : false
      },
      {
        key: 'action-1-b',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	 {
        key: 'action-2',
        component: Filler,
		size: 'lg',
		multiple : false
      } 
];

const SupplierOrders = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedSupplierOrders, setPagedSupplierOrders] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
	let navigate = useNavigate();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
				
		pageSupplierOrders({})
			.catch(error => { 
		 		log.error("Error Loading Initial SupplierOrders List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const updateSupplierOrder = (item, index) => {
	  navigate("/supplier-orders/update", {state : { item : item }});
	  //setSelectedDataTableItem(item);
	  //showActionModal(action)
  }
  
  const addSupplierOrder = () => {
	  navigate("/supplier-orders/create");
	  //let item = {};
	  //setSelectedDataTableItem(item);
	  //showActionModal("action-0")
  }

  const registerSupplierOrderExpenseTransaction = (item, index) => {
	  
	  //Check first if no transaction already registered
	  if (!item.transactionId) {
		  
		   let transaction = {};
	  	   transaction.supplierOrderId = item.id;
	  	   
	  	   if (item.totalAmount >= 0) {
	  	   		transaction.ledgerNo = "700";//CASH - ASSET Decrease = CREDIT
	  	   		transaction.ledgerDrNo = "500"; //EXPENSE Increase = DEBIT
	  	   		transaction.amount = item.totalAmount.toFixed(2);
	  	   } else {
				//If totalAmount is negative, we must invert ledgers
				transaction.ledgerNo = "500"; //EXPENSE Decrease = CREDIT
				transaction.ledgerDrNo = "700";//CASH - ASSET Increase = DEBIT
	  	   		transaction.amount = Math.abs(item.totalAmount).toFixed(2);
		   }
	       
	       
	       transaction.eventDate = item.eventDate;
	       
	       transaction.supplierDisplayName = item.supplierDisplayName;
	       
	       setSelectedDataTableItem(transaction);
	       showActionModal("action-1-a")
	   } else {
		  setSelectedDataTableItem(item);
		  showActionModal("action-1-b")
	   }
  }
  
  
    
  const updatePage = (values) => {
	  pageSupplierOrders(values)
		.catch(error => { 
			log.error("Error Paginating SupplierOrders List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageSupplierOrders(values)
		.catch(error => { 
			log.error("Error Sorting SupplierOrders List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.accountNo = currentPage.accountNo;
		  queryParams.products = currentPage.products.map(p => Object.assign({},{productId: p}));
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
		  queryParams.registered = Object.is(currentPage.registered, null) ? "": currentPage.registered;
		  queryParams.grouped = Object.is(currentPage.grouped, null) ? "true": currentPage.grouped;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List SupplierOrders with no new values
	  pageSupplierOrders({})
		.catch(error => { 
	 		log.error("Error Refreshing SupplierOrders List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageSupplierOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageSupplierOrders(mergeQueryParams(pagedSupplierOrders, values)).then(response => {
			setPagedSupplierOrders(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
 const exportSupplierOrders = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedSupplierOrders, values));
		//exportFilters.grouped = true;
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		
		//We export it itemized by products
		API.findSupplierOrders(exportFilters).then(response => {

			//let columns = ["id", "eventDate", "accountNo", "supplierDisplayName", "totalItems", "products", "freightCharge", "otherCharges", "totalAmount", "status", "whenModified", "whenCreated"];
			
			let columns = SupplierOrderConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.freightCharge = formatNumber(r.freightCharge, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.otherCharges = formatNumber(r.otherCharges, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.totalAmount = formatNumber(r.totalAmount, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.status =  t("supplier-orders.list.export.enums.status." + !Object.is(row.transactionId,null));
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("supplier-orders.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	const exportSupplierOrderItems = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedSupplierOrders, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		//We export it itemized by products
		API.findSupplierOrders(exportFilters).then(response => {

			//let columns = ["id", "eventDate", "accountNo", "supplierDisplayName", "productId", "productName", "purchaseType", "quantity", "unitPrice", "discountAmount", "totalAmount", "remarks", "status", "whenModified", "whenCreated"];
			
			let columns = SupplierOrderConfig.ExportItemsHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.id = row.supplierOrderId;
				row.purchaseType = t("supplier-orders.list.export.enums.purchaseType."+row.purchaseType);
				row.discountAmount = formatNumber(r.discountAmount, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.unitPrice = formatNumber(r.unitPrice, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.totalAmount = formatNumber(r.totalAmount, config.preferredLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2});
				row.status =  t("supplier-orders.list.export.enums.status." + !Object.is(row.transactionId,null));
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("supplier-orders.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	const confirmSupplierOrderDeletion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
   }
   
   const renderSupplierOrderStatus = (item) => {
	  return <Badge pill variant={item ? "info" : "danger"}><Trans i18nKey={"supplier-orders.list.datatable.enums.status."+item}/></Badge>
   }

	
   const deleteSupplierOrder = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteSupplierOrder(item.id, config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing SupplierOrder: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
  
  const displayPurchaseOrReturn = (item) => { 
		return (item.purchaseType === "PURCHASE_RETURN") ? "debit-row" : "credit-row";
  	}


   	if (isLoading) 
		return <Loader />
	
	if (!pagedSupplierOrders) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-2")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("supplier-orders.delete-order-confirmation-modal.title")}
				bodyText={t("supplier-orders.delete-order-confirmation-modal.body", {item: Object.assign({},selectedDataTableItem,{ totalAmount: formatCurrency(selectedDataTableItem.totalAmount, config.preferredLanguage, config.numberFormat.currency)})})}
				confirmText={t("supplier-orders.delete-order-confirmation-modal.confirm")}
				cancelText={t("supplier-orders.delete-order-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteSupplierOrder}
			/>}
			
			{(selectedDataTableItem && dataTableActionModalsShowMap.get("action-1-b")) && <ShowDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1-b")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("supplier-orders.expense-already-registered-show-dialog-modal.title")}
				bodyText={t("supplier-orders.expense-already-registered-show-dialog-modal.body", {item: selectedDataTableItem})}
				closeText={t("supplier-orders.expense-already-registered-show-dialog-modal.close")}
				variant="info"
			/>}
			
			{(Object.is(pagedSupplierOrders.grouped, null) || JSON.parse(pagedSupplierOrders.grouped)) ?
		<PagedList
			i18nPrefix="supplier-orders.list."
			page={pagedSupplierOrders}
			list={pagedSupplierOrders.list.map(i => 
					Object.assign({}, i, {status: !Object.is(i.transactionId,null), totalProducts: i.products.length}))
			}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={SupplierOrderFilterForm}
			filterParameters={{ filter: pagedSupplierOrders.filter, filteredColumn: pagedSupplierOrders.filteredColumn }}
			onFilter={pageSupplierOrders}
			onExport={exportSupplierOrders} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addSupplierOrder}
			onPage={updatePage}
			dataTableColumns={["eventDate", "supplierDisplayName", "totalItems", "products", "totalAmount", "status","whenModified"]} 
			dataTableSortableColumns={["eventDate", "whenModified"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["totalAmount", "Currency"],["status", renderSupplierOrderStatus],["whenModified", "ZonedDateTime"]]}
			dataTableActions={[updateSupplierOrder, registerSupplierOrderExpenseTransaction, confirmSupplierOrderDeletion]}	
			dataTableDefaultAction={updateSupplierOrder}
			//dataTableHandleTextOverflow={true}
		/> :
		<PagedList
			i18nPrefix="supplier-orders.list."
			page={pagedSupplierOrders}
			list={pagedSupplierOrders.list.map(i => 
					Object.assign({}, i, {status: !Object.is(i.transactionId,null), id: i.supplierOrderId, totalItems : (i.purchaseType === "PURCHASE_RETURN") ? -Math.abs(i.quantity): i.quantity, products:[i.productName], totalAmount : (i.purchaseType === "PURCHASE_RETURN") ? -Math.abs(i.totalAmount): i.totalAmount,}))
			}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={SupplierOrderFilterForm}
			filterParameters={{ filter: pagedSupplierOrders.filter, filteredColumn: pagedSupplierOrders.filteredColumn }}
			onFilter={pageSupplierOrders}
			onExport={exportSupplierOrderItems} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addSupplierOrder}
			onPage={updatePage}
			dataTableColumns={["eventDate", "supplierDisplayName", "totalItems", "products", "totalAmount", "status","whenModified"]} 
			dataTableSortableColumns={["eventDate", "whenModified"]}
			dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["unitPrice", "Currency"],["totalAmount", "Currency"],["status", renderSupplierOrderStatus],["whenModified", "ZonedDateTime"]]}
			dataTableActions={[updateSupplierOrder]}	
			dataTableDefaultAction={updateSupplierOrder}
			dataTableCustomRowStyle={displayPurchaseOrReturn}
			//dataTableHandleTextOverflow={true}
		/> 
		}
		</CustomContainer>
	);
}

export default SupplierOrders;
