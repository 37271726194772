import React, { useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";
//import Loader from "../components/Loader";

import AccountInputSearch from "../components/AccountInputSearch";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import * as Yup from "yup";

//import { ValidationError } from "../helpers/custom-errors";

import AccountTransactionConfig from "../config/AccountTransactionConfig";

import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

import API from "../services/backend-api";

import { Typeahead} from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(AccountTransactionConfig.FilterFormValidationRules);

const AccountTransactionFilterFormFields = props => {
	
	//const [isSearchingTag, setIsSearchingTag] = useState(false);
	//const [tagOptions, setTagOptions] = useState([]);
	const [ledgers, setLedgers] = useState([]);	
	const [customTags, setCustomTags] = useState([]);
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
	const typeaheadRef = useRef(null);
		
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 	
		
		//Fetch Ledgers
		API.findLedgers()
			.then(response => {
					if (isMounted) setLedgers(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Ledgers: ", error.message);
		 		props.onError(error);
			})
			
		//Fetch Custom Tags
		API.findCustomTags({}).then(response => {
				if (isMounted) setCustomTags(response.list);
			}).catch(error => {			
				log.error("Error Loading Custom Tags: ", error.message);
		 		props.onError(error);
			});	
		
		return () => { isMounted = false };
		  
	}, []);
	
	
	/*const createProductOption = (item) => {
        
        return Object.assign({}, {"id": item.id, "displayText": item.name})
    }*/

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(AccountTransactionConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 
	
	const handleAccountChange = (item) => {
		
        if (!item) {
			props.setFieldValue("accountNo", "");
        	return;
        } 

		props.setFieldValue("accountNo", item.accountNo);

    }

	/*const handleTagSearch = (query) => {
	    
	    findTags({filter: query})
	      .then(({ list }) => {
				
			  const options = list.map((e,index)=> {
					return ({
	    		  		id: e.id,
						name: e.name
	    	  		})
			  });  	 
		
	    	  setTagOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Tags Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find tags Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 });
	  }
	  
	  const findTags = (values) => {
		
		setIsSearchingTag(true);
		
		return new Promise((resolve, reject) => {
			API.findCustomTags(values)
			.then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 	setIsSearchingTag(false);
		 	});
		});
		
	}*/

	
	return (
		<fieldset>
			<div style={displayFiltersButton}>  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-right"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>
			<div className={"border rounded p-4"}>
				 <FormGroup as={Row} controlId="formGridAccount">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"accountNo.label"}>Account</Trans>
    				</FormLabel>
					<Col sm="7">
						<AccountInputSearch 
				    			i18nPrefix={props.i18nPrefix}
				    			onError={props.onError}
				    			isInvalid={!(props.errors.accountNo == null)}
				    			onChange={handleAccountChange}
				    	/>
						<FormControlErrors errors={props.errors.accountNo} />
   					</Col>
				</FormGroup>
						
				
				{displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridFilter">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"filter.label"}>Filter</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl type={'search'} name="filter" isInvalid={!(props.errors.filter == null)} value={props.values.filter} onChange={props.onChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
					</Col>
				</FormGroup>
				}
				
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridTransactionsTypesExt">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"types.label"}>Transaction Types</Trans>
    				</FormLabel>
					<Col sm="7">
						<Typeahead
      						multiple
      						id="transactionTypesExt"
							isInvalid={!(props.errors.transactionTypesExt == null)}
							labelKey={(option) => option.displayText }
      						onChange={(selected) => {
								props.setFieldValue("types", selected.map(item => item.id));
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={AccountTransactionConfig.TransactionTypes_Ext
									.map(item => Object.assign({}, {"id": item, "displayText": t(props.i18nPrefix+"types.options."+item)}))}
      						placeholder={t(props.i18nPrefix+"types.placeholder")}
							emptyLabel={t(props.i18nPrefix+"types.empty-label")}
      						ref={typeaheadRef}
      						selected={props.values.types
									.map(item => Object.assign({}, {"id": item, "displayText": t(props.i18nPrefix+"types.options."+item)}))}
    					/>
						<FormControlErrors errors={props.errors.types} />
					</Col>
					
				</FormGroup>
				}
				
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridLedger">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"ledgerNo.label"}>Ledger</Trans>
    				</FormLabel>
    				<Col sm="7">
						<FormControl as="select" name="ledgerNo" isInvalid={!(props.errors.ledgerNo == null)} value={props.values.ledgerNo} onChange={props.onChange} >
							<option value="">{t(props.i18nPrefix+"ledgerNo.blank-option")}</option>
		    				{ ledgers.map(item =>
		    					<option key={item.ledgerNo} value={item.ledgerNo+""}>{item.name}</option>
		    				)}
		    			</FormControl>
						<FormControlErrors errors={props.errors.ledgerNo} />
					</Col>
				</FormGroup>
				}
				
				
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridTagsExt">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"tags.label"}>Tags</Trans>
    				</FormLabel>
					<Col sm="7">
						<Typeahead
      						multiple
      						id="tags"
							isInvalid={!(props.errors.tags == null)}
							labelKey={(option) => option.name }
      						onChange={(selected) => {
        						props.setFieldValue("tags", selected);
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={customTags}
      						placeholder={t(props.i18nPrefix+"tags.placeholder")}
							emptyLabel={t(props.i18nPrefix+"tags.empty-label")}
      						ref={typeaheadRef}
      						selected={props.values.tags}
    					/>
						{/*<AsyncTypeahead
      						multiple
      						id="tags"
							isInvalid={!(props.errors.tags == null)}
							isLoading={isSearchingTag}
							labelKey="name"
							onSearch={handleTagSearch}
      						onChange={(selected) => {
        						props.setFieldValue("tags", selected);
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={tagOptions}
							placeholder={t(props.i18nPrefix+"tags.placeholder")}
							emptyLabel={t(props.i18nPrefix+"tags.no-matches-found")}
							searchText={t(props.i18nPrefix+'tags.searching')}
							promptText={t(props.i18nPrefix+'tags.prompt')}
						
							ref={typeaheadRef}
							renderMenuItemChildren={(option, props) => (
								<Highlighter search={props.text}>
						      	{option.name}
						    	</Highlighter>
							)}
						selected={props.values.tags}
    					/>
						*/}
						{/*<Typeahead
      						multiple
      						id="transactionTypesExt"
							isInvalid={!(props.errors.transactionTypesExt == null)}
							labelKey={(option) => option.displayText }
      						onChange={(selected) => {
								props.setFieldValue("types", selected.map(item => item.id));
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={AccountTransactionConfig.TransactionTypes_Ext
									.map(item => Object.assign({}, {"id": item, "displayText": t(props.i18nPrefix+"types.options."+item)}))}
      						placeholder={t(props.i18nPrefix+"types.placeholder")}
							emptyLabel={t(props.i18nPrefix+"types.empty-label")}
      						ref={typeaheadRef}
      						selected={props.values.types
									.map(item => Object.assign({}, {"id": item, "displayText": t(props.i18nPrefix+"types.options."+item)}))}
    					/>*/}
						<FormControlErrors errors={props.errors.tags} />
					</Col>
					

					
				</FormGroup>
				}
				
				
				<FormGroup as={Row} controlId="formGridDateRange" >
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.label"}>Date Range</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.start == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("start", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("start", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("start", "");
      							}
      						}}
      						date={props.values.start}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.start} />
					</Col>
					<FormLabel column sm="1" className="text-center">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.until"}>until</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.end == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("end", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("end", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("end", "");
      							}
      						}}
      						date={props.values.end}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.end} />
					</Col>
					<Col sm="3">
						<Button type="submit" disabled={props.isSubmitting}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
				
						
			</div>
		</fieldset>
	)

}

export default AccountTransactionFilterFormFields;
