import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Prompt from "../components/Prompt";
import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import LedgerTransactionFormFields from "../components/LedgerTransactionFormFields";
//import SubjectActivities from "../components/SubjectActivities";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LedgerTransactionConfig from "../config/LedgerTransactionConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveLedgerTransactionValidationSchema = Yup.object().shape({
	...LedgerTransactionConfig.FormValidationRules
});

const RegisterSupplierOrderExpenseTransactionModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if  (!props.item || !props.item.supplierOrderId) {
		return null
	} 
			
	let initialValues = {}, readOnlyFields = {}, validationSchema;
	
	let defaultValues = saveLedgerTransactionValidationSchema.default();
	
	validationSchema = saveLedgerTransactionValidationSchema;
	
	initialValues = defaultValues;
	
	// ledgerNo, ledgerDrNo and amount fields come from previous form 
	// and can´t be changed
	initialValues.ledgerNo = props.item.ledgerNo;
	initialValues.ledgerDrNo = props.item.ledgerDrNo;
	initialValues.amount = props.item.amount;
	initialValues.eventDate = props.item.eventDate;
	initialValues.remarks = t('supplier-orders.register-expense-transaction.form.remarks.default-value', { supplier: props.item.supplierDisplayName });
	
	//Protect certain fields for edition
	readOnlyFields.ledgerNo= true;
	readOnlyFields.ledgerDrNo= true;
	readOnlyFields.amount= true;
	
	const registerSupplierOrderExpenseTransaction = (values) =>
		new Promise((resolve, reject) => {
			API.registerSupplierOrderExpenseTransaction(props.item.supplierOrderId, values ,config.csrfToken).then(response => {
			resolve(response);
		}).catch(error => reject(error))
	});
		
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-register-expense-transaction"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-register-expense-transaction">
  					<Trans i18nKey={"supplier-orders.register-expense-transaction.title"}>Register Expense Transaction</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						registerSupplierOrderExpenseTransaction(values)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemSaved(t('supplier-orders.register-expense-transaction.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Ledger Transaction Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Ledger Transaction: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-order-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		      		           			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

								<LedgerTransactionFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={props.onError}
									onSuccess={props.onSuccess}
									onWarning={props.onWarning}
									onInfo={props.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={"create"}
									origin={props.origin}
									readOnlyFields={readOnlyFields}
									i18nPrefix="supplier-orders.register-expense-transaction."
								/>

						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="supplier-orders.register-expense-transaction.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"supplier-orders.register-expense-transaction.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="supplier-orders.register-expense-transaction.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Fragment>
			)}
		</Modal>
	);
}

export default RegisterSupplierOrderExpenseTransactionModal;
