import React,  { Fragment, useState, useEffect, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

//import Tooltip from 'react-bootstrap/Tooltip'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
import moment from "moment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'

import DateRangePickerModal from "../components/DateRangePickerModal";

import { useConfig } from "../services/use-config";

/*import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';*/


const DateRangeToolbar = props => {
	
	const [showDateRangePickerModal, setShowDateRangePickerModal] = useState(false);
	
	const config = useConfig();
	
	/*const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );*/
    
    //const [dateRange, setDateRange] = useState([null, null]);
    //const [startDate, endDate] = dateRange;
    
        {/*<Button className="d-flex justify-content-center" variant={props.variant} >
      <Trans i18nKey={props.i18nPrefix+"date-range-button"} >Date Range</Trans>
    </Button>*/}
    
    /*const CustomInput = forwardRef(({ value, onClick }, ref) => (

    		<ButtonToolbar className="float-right">
				<ToggleButtonGroup className="mr-2" type="radio" name="dateRangeMode" value={props.dateRangeMode} onChange={props.onDisplayModeChange}>
					<ToggleButton variant={props.variant} value="month">
						<Trans i18nKey={props.i18nPrefix+"month-button"} >Month</Trans>
					</ToggleButton>
					<ToggleButton variant={props.variant} value="year">
						<Trans i18nKey={props.i18nPrefix+"year-button"} >Year</Trans>
					</ToggleButton>
					<ToggleButton variant={props.variant} value="date-range" onClick={onClick} ref={ref}>
						<Trans i18nKey={props.i18nPrefix+"date-range-button"} >Date Range</Trans>
					</ToggleButton>
				</ToggleButtonGroup>
			</ButtonToolbar>
  ));
  
  const CustomInput2 = forwardRef(({ value, onClick }, ref) => (

    	<Button className="d-flex justify-content-center" variant={props.variant} onClick={onClick} ref={ref}>
      		{value ? value : <Trans i18nKey={props.i18nPrefix+"date-range-button"} >Select Date Range</Trans>}
    	</Button>
  ));*/
    
    //registerLocale('pt-BR', ptBR);
    
    
    const handleDateRangePickerChange = (startDate, endDate) => {
    	setShowDateRangePickerModal(false);
    	props.onDateRangeChange(moment(startDate, 'YYYY-MM-DD', true), moment(endDate, 'YYYY-MM-DD', true));
  	};

	const handleDateRangePickerModalHide = (e) => {
    	setShowDateRangePickerModal(false);
  	};
	
	return(
		<Fragment>
			{ (showDateRangePickerModal) ?
				<DateRangePickerModal
					show={showDateRangePickerModal}
        			onHide={handleDateRangePickerModalHide}
					format="DD/MM/YYYY"
					startDate={props.startDate} 
					endDate={props.endDate}
					onDateRangeChange={handleDateRangePickerChange} 
					locale={config.preferredLanguage}
					i18nPrefix={props.i18nPrefix+"date-range-picker."}
					size="lg"
				/>
				: null
			}
			<Row className="mb-3">
				<Col className="text-center">
					<Button variant={props.variant} onClick={()=> {setShowDateRangePickerModal(!showDateRangePickerModal); }}>
						<Trans i18nKey={props.i18nPrefix+"date-range"} >Date Range</Trans> <Moment local format="DD/MMM/YYYY">{props.startDate}</Moment> <Trans i18nKey={props.i18nPrefix+"to"} >to</Trans> <Moment local format="DD/MMM/YYYY">{props.endDate}</Moment>
					</Button>
				</Col>
				{/*<Col>
					<h3 className="d-flex justify-content-center"><Moment local format="DD/MMM/YYYY">{props.startDate}</Moment>-<Moment local format="DD/MMM/YYYY">{props.endDate}</Moment></h3>
				</Col>*;}
				{/*<Col>
					<DatePicker
      					selectsRange={true}
      					startDate={props.startDate}
      					endDate={props.endDate}
      					onChange={(update) => {
        					props.onDateRangeChange(update);
      					}}
      					locale="pt-BR"
      					dateFormat="yyyy-MM-dd"
      					withPortal
      					customInput={<CustomInput2 variant={props.variant} i18nPrefix={props.i18nPrefix}/>}
    				/>
					
				</Col>*/}
			</Row>
		</Fragment>	
	);

}

export default DateRangeToolbar;
