import * as Yup from "yup";
import moment from "moment";
import { Regex } from '../helpers/commons'
import ImportConfig from "../config/ImportConfig";

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});


const TransactionTypes_Ext = [
	"ADJ_CREDIT",
	"ADJ_DEBIT",
	"DEPOSIT",
	"WITHDRAWAL",
	"BAR_DEBIT"
]

const TransactionTypes_DE = [
	"CREDIT",
	"DEBIT"
]

//This is map between Ext x DE Transaction Types
//DEA Model relies on two entries: one is credit and the other is debit
const DEATransactionTreatments = [
	{
		key: "ADJ_CREDIT",
		isCredit: true,
		xactTypeCode: "DEBIT"
	},
	{
		key: "ADJ_DEBIT",
		isCredit: false,
		xactTypeCode: "CREDIT"
	},
	{
		key: "DEPOSIT",
		isCredit: true,
		xactTypeCode: "DEBIT"
	},
	{
		key: "WITHDRAWAL",
		isCredit: false,
		xactTypeCode: "CREDIT"
	},
	{
		key: "BAR_DEBIT",
		isCredit: false,
		xactTypeCode: "CREDIT"
	},
]


//This is map between Ext x DE Transaction Types
//DEA Model relies on two entries: one is credit and the other is debit
/*const DEATransactionTypesMap = {
	"ADJ_CREDIT": "DEBIT",
	"ADJ_DEBIT": "CREDIT",
	"DEPOSIT": "DEBIT",
	"WITHDRAWAL": "CREDIT",
	"BAR_DEBIT": "CREDIT",
}

const OperationTransactionType_ExtMap = {
	"CREDIT": [ "ADJ_CREDIT", "DEPOSIT"],
	"DEBIT": [ "ADJ_DEBIT", "WITHDRAWAL","BAR_DEBIT"],
	"NEUTRAL": []
}
*/

const FormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	accountNo: Yup.string()
		.required()
		.default(() => ""),
	ledgerNo: Yup.string()
		.required()
		.default(() => ""),
	xactTypeCodeExt: Yup.string()
		.oneOf([""].concat(TransactionTypes_Ext), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	xactTypeCode: Yup.string()
		//.oneOf([""].concat(TransactionTypes_DE), 'form.validation.string.enum')
		.when('xactTypeCodeExt', ([xactTypeCodeExt], schema) => {
			if(DEATransactionTreatments.map(i => i.key).includes(xactTypeCodeExt)) {
				return Yup.string()
					.oneOf(DEATransactionTreatments.filter( i => i.key === xactTypeCodeExt).map(i => i.xactTypeCode), 'form.validation.string.enum');		
	        }
			return schema;
        })
		.required()
   		.default(() => ""),
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'account-transactions.save.form.eventDate.validation.format'})
		.required()
		.default(() => ""),
	//eventDate: Yup.date()
	//	.typeError('orders.save.form.eventDate.validation.format')
	//	.max(new Date(), "orders.save.form.eventDate.validation.future")
	//	.required()
	//	.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	amount: Yup.string()
		.matches(Regex.CURRENCY, "account-transactions.save.form.amount.validation.format")
   		.default(() => ""),
	remarks: Yup.string()
   		.max(255, 'account-transactions.save.form.remarks.validation.maximumlength')
   		.default(""),
	tags: Yup.array()
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
			
}

const MultipleAccountsFormValidationRules = {
	accounts: Yup.array()
		.min(1, 'account-transactions.save.form.accounts.validation.required')
		.default(() => []),
	ledgerNo: Yup.string()
		.required()
		.default(() => ""),
	xactTypeCodeExt: Yup.string()
		.oneOf([""].concat(TransactionTypes_Ext), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	xactTypeCode: Yup.string()
		//.oneOf([""].concat(TransactionTypes_DE), 'form.validation.string.enum')
		.when('xactTypeCodeExt', ([xactTypeCodeExt], schema) => {
			if(DEATransactionTreatments.map(i => i.key).includes(xactTypeCodeExt)) {
				return Yup.string()
					.oneOf(DEATransactionTreatments.filter( i => i.key === xactTypeCodeExt).map(i => i.xactTypeCode), 'form.validation.string.enum');		
	        }
			return schema;
        })
		.required()
   		.default(() => ""),
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'account-transactions.save.form.eventDate.validation.format'})
		.required()
		.default(() => ""),
	//eventDate: Yup.date()
	//	.typeError('orders.save.form.eventDate.validation.format')
	//	.max(new Date(), "orders.save.form.eventDate.validation.future")
	//	.required()
	//	.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	amount: Yup.string()
		.matches(Regex.CURRENCY, "account-transactions.save.form.amount.validation.format")
   		.default(() => ""),
	remarks: Yup.string()
   		.max(255, 'account-transactions.save.form.remarks.validation.maximumlength')
   		.default(""),
	tags: Yup.array()
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
			
}

const MultipleDepositsFormValidationRules = {
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'account-transactions.save.form.eventDate.validation.format'})
		.required()
		.default(() => ""),
	//eventDate: Yup.date()
	//	.typeError('orders.save.form.eventDate.validation.format')
	//	.max(new Date(), "orders.save.form.eventDate.validation.future")
	//	.required()
	//	.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	remarks: Yup.string()
   		.max(255, 'account-transactions.save.form.remarks.validation.maximumlength')
   		.default(""),
	tags: Yup.array()
		.default(() => []),
	deposits: Yup.array()
		.min(1, 'account-transactions.save-multiple-deposits.form.deposits.validation.required')
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const MultipleTransactionsTagsFormValidationRules = {
	tags: Yup.array()
		.default(() => []),
	transactions: Yup.array()
		.min(1, 'account-transactions.save-multiple-transactions-tags.form.transactions.validation.required')
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const FilterFormValidationRules = {
	accountNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "remarks"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	ledgerNo: Yup.string()
		.default(() => ""),
	types: Yup.array()
		.default(() => []),
	tags: Yup.array()
		.default(() => []),
	
}

const AdvancedFilterFormFields = {
	filter: true,
	ledgerNo : true,
	tags: true
}

const ImportHeaders =[
	"id", "eventDate", "accountNo", "customerDisplayName", "xactTypeCodeExt", "amount", "ledgerNo", "ledgerName", "xactTypeCode", "remarks", "tags",
	/*"whenCreated", "whenModified"*/
]

const ExportHeaders =[
	"id", "eventDate", "accountNo", "customerDisplayName", "xactTypeCodeExt", "amount", "ledgerNo", "ledgerName", "xactTypeCode", "remarks", "tags", "whenModified", "whenCreated"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(FormValidationRules))
		.max(255, 'account-transactions.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const AccountTransactionConfig = { TransactionTypes_DE,  TransactionTypes_Ext, DEATransactionTreatments, FormValidationRules, 
	MultipleAccountsFormValidationRules, MultipleDepositsFormValidationRules, 
	MultipleTransactionsTagsFormValidationRules, FilterFormValidationRules, 
	AdvancedFilterFormFields, ImportHeaders, ExportHeaders, ImportFormValidationRules,
	ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions };

export default AccountTransactionConfig;