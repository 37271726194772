import React from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import DataTable from "../components/DataTable";

const ImportPreviewModal = props => {
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-import-preview"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-import-preview">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey={"components.import-preview-modal.title"}>Import Preview Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body>
  				<Container>
  					<Row>
  					<Col>
  						<DataTable 
							//i18nPrefix={props.i18nPrefix} 
							items={props.data}
							columns={props.headers}
							tooltipData={props.errors}
							customDisplayColumns={props.customDisplayColumns}
							tooltipTargetStyles={{color: "red"}}
						/>
					</Col>
				</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant="secondary" onClick={props.onHide}>
          			<Trans i18nKey={"components.import-preview-modal.close"}>Close</Trans>
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default ImportPreviewModal;
