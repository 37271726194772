import React, { useState, useEffect, Fragment} from 'react';
import { useNavigate, useLocation, Navigate, useOutletContext   } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faPlusCircle, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import Prompt from "../components/Prompt";
import Loader from "../components/Loader";
import InventoryCountFormFields from "../components/InventoryCountFormFields";
import SubjectActivities from "../components/SubjectActivities";
import FormControlHelper from "../components/FormControlHelper";

import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import ShowDialogModal from "../components/ShowDialogModal";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import InventoryCountConfig from "../config/InventoryCountConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveInventoryCountValidationSchema = Yup.object().shape({
	...InventoryCountConfig.FormValidationRules
});



const SaveInventoryCount = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [inventoryCount, setInventoryCount] = useState(null);

	const { t } = useTranslation();
	const location = useLocation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
	
	const config = useConfig();
	
	
	const lookupInventoryCount = (id) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
			
		API.lookupInventoryCount(id).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	

	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/inventory-counts/update' && location.state && location.state.item) {
			lookupInventoryCount(location.state.item.id)
			.then(response => {
					setInventoryCount(response);
			})
			.catch(error => { 
				log.error("Error Loading Inventory Count: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		
		return () => { isMounted = false };
		  
	}, [location.state]);

	if (location.pathname === '/inventory-counts/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate to="/inventory-counts" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!inventoryCount) {
			return null;
		}
	}
	
			
	let initialValues = {}, readOnlyFields = {}, action, actionIcon, validationSchema;
	
	let defaultValues = saveInventoryCountValidationSchema.default();
	
	validationSchema = saveInventoryCountValidationSchema;
	
	if (location.pathname === '/inventory-counts/update') {
		Object.keys(InventoryCountConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (inventoryCount[key]) ? inventoryCount[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
		
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
	}
	
	
	const saveInventoryCount = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateInventoryCount(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createInventoryCount(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
   
	
	return (
		<Container>
						
			<Row className="mb-3">
        		<Col md="auto">
        			<h3>
        				<Trans i18nKey={"inventory-counts.save.header-"+action}>Save Inventory Count</Trans>
        			</h3>
        			
        		</Col>
        	</Row>
        	<Row className="mb-3">
    			<Col>	      
    				<Formik
						initialValues={initialValues}
		      			validationSchema={validationSchema}
		      			validateOnChange={false}
		      			validateOnBlur={false}     
		      			onSubmit={(values, actions) => {
		      			
		      				//Clear Error
		      				_setError(null);

							saveInventoryCount(values)
    						.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
									navigate("/inventory-counts", { state: { success: t("inventory-counts.save.success")}});
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof ValidationError) {		
          						
				 					log.info("Save Inventory Count Attempt Failed: ", error.message);
          						
				 					context.onError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Inventory Count: ", error.message);
          	 					}
          	 					
          	 					context.onError(error);
    								
    						})
    			   		}}
		      		>
		      		{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, dirty}) => (	
		      		
		      			<Form className="form-save-supplier-order-modal" noValidate>		
		  				
  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>
		      				
		      				

							<InventoryCountFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={context.onError}
									onSuccess={context.onSuccess}
									onWarning={context.onWarning}
									onInfo={context.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={action}
									readOnlyFields={readOnlyFields}
									i18nPrefix="inventory-counts.save."
							/>

							<Row className="mt-3">    					   
    							<Col>
    								<Button variant="success" type="submit" disabled={isSubmitting}> 
    									{isSubmitting ? <Trans i18nKey="inventory-counts.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"inventory-counts.save.form.submit-"+action}>Submit</Trans></span>} 
    								</Button>

    								<Button variant="secondary" className="float-right" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="inventory-counts.save.cancel">Cancel</Trans></Button>
    					   		</Col>
    			    		</Row>   
	    			    
		      			</Form>	
						)}
					</Formik>
		        </Col>
	        </Row>
	        
			{(location.pathname === '/inventory-counts/update') && (
				<SubjectActivities 
		 			subject="INVENTORY_COUNT" 
		 			subjectId={location.state.item.id} 
		 			onError={context.onError}
		 		/>
			)}
	     </Container>

	);
}

export default SaveInventoryCount;
