import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import ProductSupplierFormFields from "../components/ProductSupplierFormFields";
import Prompt from "../components/Prompt";
import Loader from "../components/Loader";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AuthError, ValidationError } from "../helpers/custom-errors";
import { compareJSON } from "../helpers/commons";

import ProductSupplierConfig from "../config/ProductSupplierConfig";


setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

	
const saveProductSupplierValidationSchema = Yup.object().shape({
	...ProductSupplierConfig.FormValidationRules
});

const SaveProductSupplierModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [supplyScheme, setSupplyScheme] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.productId && props.item.accountNo) {
			
			API.lookupSupplyScheme(props.item.productId, props.item.accountNo)
				.then(response => {
					if (isMounted) setSupplyScheme(response);
				})
				.catch(error => { 
					log.error("Error Loading Product Supplier: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		return () => { isMounted = false };
		  
	}, []);
	
	
	/*if  (!props.item || !props.item.productId) {
		return null
	} else if (props.item.accountNo) {
		if (!isLoading && !supplyScheme) {
			return null;
		}
	}	*/
	
	if  (!props.item || !props.item.productId)
		return null
	
	if (props.item.accountNo) {
		
		if (isLoading) 
			return <Loader />
	
		if (!supplyScheme) 
			return null;
	}
		
	let initialValues = {}, readOnlyFields = {}, action, actionIcon, validationSchema;
	
	let defaultValues = saveProductSupplierValidationSchema.default();
	
	validationSchema = saveProductSupplierValidationSchema;
	
	console.log(supplyScheme);
	//if supplyScheme != null, then we have an update
	if (supplyScheme) {		
		Object.keys(ProductSupplierConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (supplyScheme[key]) ? supplyScheme[key] : defaultValues[key];
		});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//TODO: Check for consistency depending on Origin
		switch (props.origin) {
  			case "product-suppliers":	  
			default:
				initialValues.unitPrice = initialValues.unitPrice.toFixed(2);
				readOnlyFields.accountNo = true;
				break;
		}
	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faCheckCircle
		
		//Override with values from props depending on Origin
		switch (props.origin) {
			case "product-suppliers":
			default:
				initialValues.productId = props.item.productId;
				break;
		}

	}
	
	const saveProductSupplier = (values) =>
		new Promise((resolve, reject) => {
			if (props.item.accountNo) {
				API.updateSupplyScheme(props.item.productId, props.item.accountNo, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createSupplyScheme(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
	});
	
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-product-supplier"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-product-supplier">
  					<Trans i18nKey={"product-suppliers.save.title-"+action}>Save Product Supplier</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
		      			saveProductSupplier(values, config.csrfToken)
		      			.then(response => {
							actions.setSubmitting(false);
							props.onItemSaved(t('product-suppliers.save.success'));
						})
		      			.catch(error => { 
		      				
		      				actions.setSubmitting(false);
		      				
		      				if (error instanceof AuthError) {		
		    					auth.onUnathorized(error);
		    				} else if (error instanceof ValidationError) {		
          						
					 			log.info("Save custom Tag Attempt Failed: ", error.message);
          						
					 			_setError(error.message);
          						
					 			if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("Save custom Tag Error: ", error.message);
          						_setError(t('error.api.general'));
          					}

					 	})
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, dirty, setFieldValue}) => (	
		      		<Form className="form-save-custom-tag" noValidate>		
		      			<Modal.Body className="mb-3">
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	
				    		<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>
		      				
							<ProductSupplierFormFields
								values={values} 
								errors={errors} 
								onChange={handleChange}
								setFieldValue={setFieldValue}
								action={action}
								origin={props.origin}
								readOnlyFields={readOnlyFields}
								i18nPrefix="product-suppliers.save."
								onError={props.onError}
							/>
		
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="product-suppliers.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"product-suppliers.save.form.submit-"+action}>Submit</Trans></span>}
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><Trans i18nKey="product-suppliers.save.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	

		      )}
		      </Formik>
		   </Modal>
	);
}

export default SaveProductSupplierModal;
