import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faSearchPlus } from '@fortawesome/free-solid-svg-icons'

import Prompt from "../components/Prompt";
import CustomContainer from "../components/CustomContainer";
//import AccountActivitiesModal from "../components/AccountActivitiesModal";

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Loader from "../components/Loader";
import AccountFormFields from "../components/AccountFormFields";
import SubjectActivities from "../components/SubjectActivities";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import AccountConfig from "../config/AccountConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(AccountConfig.FormValidationRules);

const SaveAccount = props => {
	
	const [isLoading, setIsLoading] = useState(true);
	//const [isResetingAccessToken, setIsResetingAccessToken] = useState(false);
	const [account, setAccount] = useState(null);
	const [nextAccountNo, setNextAccountNo] = useState(null);
	const [showAccountActivitiesModal, setShowAccountActivitiesModal] = useState(false);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig()
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/accounts/update' && location.state && location.state.item) {
			API.lookupAccount(location.state.item.accountNo)
			.then(response => {
					setAccount(response);
			})
			.catch(error => { 
				log.error("Error Loading Account: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		} else {
			API.getNextAccountNo(config.csrfToken)
			   .then(response => {
					setNextAccountNo(response.accountNo);
			   }).catch(error => {
					log.error("Error Looking up Next Account No. Available: ", error.message);
					context.onError(error);
			   })
			   .finally(() => {
		 			if (isMounted) setIsLoading(false);
			});	
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (location.pathname === '/accounts/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate to="/accounts" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!account) {
			return null;
		}
	} else {
		if (isLoading) {
			return <Loader />
		}
	}
	
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	if (location.pathname === '/accounts/update') {
		Object.keys(AccountConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (account[key]) ? account[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
		//pre-populated accountNo with next accountNo available
		initialValues.accountNo = (nextAccountNo) ? nextAccountNo : defaultValues.accountNo;
	}
	
	const saveAccount = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/accounts/update') {
				API.updateAccount(location.state.item.accountNo, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createAccount(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	/*const resetAccountAccessToken = () => {
		setIsResetingAccessToken(true);
		
		API.resetAccountAccessToken(location.state.item.accountNo, config.csrfToken)
			.then(response => {
				history.push({pathname:"/accounts", success: t("accounts.save.success")});
			}).catch(error => {
				
				setIsResetingAccessToken(false);
				
				log.error("Error Reseting Account Access Token: ", error.message);
				
				context.onError(error);
				
			});	
	}*/
		
	return (
		<CustomContainer>
			{/*(showAccountActivitiesModal) && <AccountActivitiesModal
				item={Object.assign({}, location.state.item, {customerDisplayName : location.state.item.displayName})}
				show={showAccountActivitiesModal}
        		onHide={() => { setShowAccountActivitiesModal(false);}}
				size="lg"
				onError={context.onError}
			/>*/}
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"accounts.save.header-"+action}>Save Account</Trans> 
        			</h3>
        		</Col>
        	</Row>
        	<Row className="mb-3">
    			<Col >	      
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveAccount(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/accounts", { state: { success: t("accounts.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save Account Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving Account: ", error.message);
    								}
    								
    								context.onError(error);
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, setFieldError, validateField, touched}) => (	
		      	<Form className="form-account" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

						<AccountFormFields 
							values={values} 
							errors={errors} 
							onChange={handleChange} 
							onError={context.onError}
							setFieldValue={setFieldValue}
							action={action}
							validateField={validateField}
							setFieldError={setFieldError}
							touched={touched}
						/>
				    	
			    		
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="accounts.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"accounts.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    							
    							{/*(location.pathname === '/accounts/update') ?
    								<Button variant="warning" className="ml-3" onClick={() => { setShowAccountActivitiesModal(true);}}} ><FontAwesomeIcon icon={faSearchPlus} /> <Trans i18nKey="accounts.save.list-account-activities">List Account Activities</Trans></Button> 
    								: null
    							*/}

    							<Button as={Link} variant="secondary" className="float-right" to={"/accounts"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="accounts.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			    </Row>   
	    			    
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
			{(location.pathname === '/accounts/update') && (
				<SubjectActivities 
		 			subject="ACCOUNT" 
		 			subjectId={location.state.item.accountNo} 
		 			onError={context.onError}
		 		/>
			)}
	     </CustomContainer>
	);
}

export default SaveAccount;
