import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext   } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import CustomContainer from "../components/CustomContainer";

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
//import SingleFilterForm from "../components/SingleFilterForm";
import AccountFilterForm from "../components/AccountFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import AccountActivitiesModal from "../components/AccountActivitiesModal";

import API from "../services/backend-api";

import AccountConfig from "../config/AccountConfig";

//import { formatNumber  } from "../helpers/commons";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";

const dataTableActionModals = [
	 {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
      {
        key: 'action-2',
        component: AccountActivitiesModal,
		size: 'xl',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: Filler,
		size: 'lg',
		multiple : true
      }
    ];


const Accounts = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedAccounts, setPagedAccounts] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	let navigate = useNavigate();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageAccounts({})
			.catch(error => { 
		 		log.error("Error Loading Initial Accounts List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
  
  const confirmAccountAccessTokenReset = (item, index) => {

	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }

  const confirmMultipleAccountAccessTokensReset = (items, index) => {

	  setSelectedDataTableItems(items);
	  showActionModal("action-"+index)
  }

  const updateAccount = (item) => {
	  navigate("/accounts/update", { state: { item: item}});
  }
  
  const addAccount = () => {
	  navigate("/accounts/create");
  }
    
  const updatePage = (values) => {
	  pageAccounts(values)
		.catch(error => { 
			log.error("Error Paginating Accounts List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageAccounts(values)
		.catch(error => { 
			log.error("Error Sorting Accounts List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.mobilePhone = currentPage.mobilePhone; 
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Accounts with no new values
	  pageAccounts({})
		.catch(error => { 
	 		log.error("Error Refreshing Accounts List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageAccounts = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageAccounts(mergeQueryParams(pagedAccounts, values)).then(response => {
			setPagedAccounts(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	const exportAccounts = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedAccounts, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findAccounts(exportFilters, auth.isAdmin()).then(response => {

			let columns = AccountConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.active = t("accounts.list.export.enums.active."+row.active);
				row.entityType = t("accounts.list.export.enums.entityType."+row.entityType);
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("accounts.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	const resetAccountAccessToken = (item) => {
		
		//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.resetAccountAccessToken(item.id, config.csrfToken)
			.then(response => {
				context.onSuccess(t("accounts.save.success"));
			}).catch(error => {
				
				log.error("Error Reseting Account Access Token: ", error.message);
				
				context.onError(error);
				
			}).finally(() => {
				handleActionModalHide();
			});	
	}
	
	/*const resetMultipleAccountAccessTokens = (items) => {
		
	}*/
	

    
    const listAccountActivities = (item) => {
	  	
	  	let account = {};
		account.accountNo = item.accountNo;
		account.customerDisplayName = item.displayName;
		
		setSelectedDataTableItem(account);
	    showActionModal("action-2");
    }
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedAccounts) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="accounts"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="accounts"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("accounts.reset-access-token-confirmation-modal.title")}
				bodyText={t("accounts.reset-access-token-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("accounts.reset-access-token-confirmation-modal.confirm")}
				cancelText={t("accounts.reset-access-token-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={resetAccountAccessToken}
			/>}
			
			{/*(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("accounts.reset-multiple-access-tokens-confirmation-modal.title")}
				bodyText={t("accounts.reset-multiple-access-tokens-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("accounts.reset-multiple-access-tokens-confirmation-modal.confirm")}
				cancelText={t("accounts.reset-multiple-access-tokens-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={resetMultipleAccountAccessTokens}
			/>*/}
		<PagedList
			i18nPrefix="accounts.list."
			page={pagedAccounts}
			pageSizeOptions={[10,25,50,100]}
			//filterFormComponent={SingleFilterForm}
			filterFormComponent={AccountFilterForm}
			filterParameters={{ filter: pagedAccounts.filter, filteredColumn: pagedAccounts.filteredColumn }}
			onFilter={pageAccounts}
			onExport={auth.isAdmin() ? exportAccounts : null}
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addAccount}
			onPage={updatePage}
			dataTableColumns={["accountNo", "displayName", "birthDate", "lastOrderDate"]} 
			dataTableSortableColumns={["accountNo", "displayName", "birthDate"]}
			dataTableCustomDisplayColumns={[["birthDate", "LocalDate"], ["lastOrderDate", "LocalDate"]]}
			dataTableActions={auth.isAdmin() ? [updateAccount, confirmAccountAccessTokenReset, listAccountActivities] : [updateAccount]}
			dataTableDefaultAction={auth.isAdmin() ? updateAccount : updateAccount}
			dataTableCustomRowStyle={displayInactive}		
		/>
		</CustomContainer>
	);
}

export default Accounts;
