import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faSearchPlus } from '@fortawesome/free-solid-svg-icons'

import Prompt from "../components/Prompt";
import CustomContainer from "../components/CustomContainer";
//import SupplierActivitiesModal from "../components/SupplierActivitiesModal";

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Loader from "../components/Loader";
import SupplierFormFields from "../components/SupplierFormFields";
import SubjectActivities from "../components/SubjectActivities";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import SupplierConfig from "../config/SupplierConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(SupplierConfig.FormValidationRules);

const SaveSupplier = props => {
	
	const [isLoading, setIsLoading] = useState(true);
	//const [isResetingAccessToken, setIsResetingAccessToken] = useState(false);
	const [supplier, setSupplier] = useState(null);
//	const [showSupplierActivitiesModal, setShowSupplierActivitiesModal] = useState(false);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig()
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/suppliers/update' && location.state && location.state.item) {
			API.lookupSupplier(location.state.item.supplierNo)
			.then(response => {
					setSupplier(response);
			})
			.catch(error => { 
				log.error("Error Loading Supplier: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (location.pathname === '/suppliers/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate to="/suppliers" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!supplier) {
			return null;
		}
	}
	
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	if (location.pathname === '/suppliers/update') {
		Object.keys(SupplierConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (supplier[key]) ? supplier[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
	}
	
	const saveSupplier = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/suppliers/update') {
				API.updateSupplier(location.state.item.supplierNo, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createSupplier(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	/*const resetSupplierAccessToken = () => {
		setIsResetingAccessToken(true);
		
		API.resetSupplierAccessToken(location.state.item.supplierNo, config.csrfToken)
			.then(response => {
				history.push({pathname:"/suppliers", success: t("suppliers.save.success")});
			}).catch(error => {
				
				setIsResetingAccessToken(false);
				
				log.error("Error Reseting Supplier Access Token: ", error.message);
				
				context.onError(error);
				
			});	
	}*/
		
	return (
		<CustomContainer>
			{/*(showSupplierActivitiesModal) && <SupplierActivitiesModal
				item={Object.assign({}, location.state.item, {customerDisplayName : location.state.item.displayName})}
				show={showSupplierActivitiesModal}
        		onHide={() => { setShowSupplierActivitiesModal(false);}}
				size="lg"
				onError={context.onError}
			/>*/}
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"suppliers.save.header-"+action}>Save Supplier</Trans> 
        			</h3>
        		</Col>
        	</Row>
        	<Row className="mb-3">
    			<Col >	      
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveSupplier(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/suppliers", { state: { success: t("suppliers.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save Supplier Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving Supplier: ", error.message);
    								}
    								
    								context.onError(error);
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, setFieldError, validateField, touched}) => (	
		      	<Form className="form-supplier" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

						<SupplierFormFields 
							values={values} 
							errors={errors} 
							onChange={handleChange} 
							onError={context.onError}
							setFieldValue={setFieldValue}
							action={action}
							validateField={validateField}
							setFieldError={setFieldError}
							touched={touched}
						/>
				    	
			    		
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="suppliers.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"suppliers.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    							
    							{/*(location.pathname === '/suppliers/update') ?
    								<Button variant="warning" className="ml-3" onClick={() => { setShowSupplierActivitiesModal(true);}}} ><FontAwesomeIcon icon={faSearchPlus} /> <Trans i18nKey="suppliers.save.list-supplier-activities">List Supplier Activities</Trans></Button> 
    								: null
    							*/}

    							<Button as={Link} variant="secondary" className="float-right" to={"/suppliers"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="suppliers.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			    </Row>   
	    			    
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
			{(location.pathname === '/suppliers/update') && (
				<SubjectActivities 
		 			subject="SUPPLIER" 
		 			subjectId={location.state.item.supplierNo} 
		 			onError={context.onError}
		 		/>
			)}
	     </CustomContainer>
	);
}

export default SaveSupplier;
