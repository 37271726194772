import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";

import moment from "moment";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import { ValidationError } from "../helpers/custom-errors";

import MediaFileConfig from "../config/MediaFileConfig";

import { useConfig } from "../services/use-config";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape(MediaFileConfig.FilterFormValidationRules);

const MediaFileFilterForm = props => {
	
	const [subTypeOptions, setSubTypeOptions] = useState([]);
		
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const handleTypeChange = (e, setFieldValueFunction) => {
        
        setFieldValueFunction("type", e.target.value);
        
        if (!e.target.value) {
        	setSubTypeOptions([]);
        	setFieldValueFunction("subType", "");
        	return;
        }

		// Filter SubType Options
		setSubTypeOptions(MediaFileConfig.SubTypes[e.target.value]);
        
        //Reset SubType Status on every change
        setFieldValueFunction("subType", "");
         
    }
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	Object.keys(MediaFileConfig.FilterFormValidationRules)
		.forEach((key) => {
				initialValues[key] = (props.parameters[key]) ? props.parameters[key] : defaultValues[key];
		});
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
				
					if (error instanceof ValidationError) {		
    									
    						log.info("Filtering Items Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Filtering Items: ", error.message);
    					}
    								
    					props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
	
			<Form className="form-filter-data" noValidate>
				<fieldset>
					<div className={"border rounded p-4"}>
		      		    
						<FormGroup as={Row} controlId="formGridFilter">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"filter.label"}>Filter</Trans>
    						</FormLabel>
						    <Col sm="8">
							   <InputGroup >
								   <InputGroup.Prepend>
										<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
									</InputGroup.Prepend>
								   <FormControl type={'search'} className="text-uppercase" name="filter" isInvalid={!(errors.filter == null)} value={values.filter} onChange={handleChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
					               <FormControlErrors errors={errors.filter} />
				               </InputGroup>
   							</Col>
						</FormGroup>
						
						<FormGroup as={Row} controlId="formGridType">
							<FormLabel column sm="2">
      							<Trans i18nKey={props.i18nPrefix+"type.label"}>Type/SubType</Trans>
    						</FormLabel>
							<Col sm="4">
								<FormControl as="select" name="type" isInvalid={!(errors.type == null)} value={values.type} onChange={(e) => handleTypeChange(e, setFieldValue)} >
		    						<option  value="">{t(props.i18nPrefix+"type.options.blank")}</option>
		    						{ MediaFileConfig.Types.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"type.options."+item)}</option>
		    						)}
		    					</FormControl>
								<FormControlErrors errors={errors.subject} />
							</Col>
							<Col sm="4">
								<FormControl as="select" name="subType" disabled={!(values.type)} isInvalid={!(errors.subType == null)} value={values.subType} onChange={handleChange} >
		    						<option  value="">{t(props.i18nPrefix+"subType.options.blank")}</option>
		    						{ subTypeOptions.map(item =>
		    							<option key={item} className="text-uppercase" value={item}>{t(props.i18nPrefix+"subType.options."+item)}</option>
		    						)}
		    					</FormControl>
								<FormControlErrors errors={errors.action} />
							</Col>	
							<Col sm="2">
								<Button type="submit" disabled={isSubmitting} onClick={() => {}}> 
									{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
								</Button>
							</Col>
						</FormGroup>
				
					</div>
				</fieldset>
			</Form>	
		)}
	</Formik>
	)

}

export default MediaFileFilterForm;
