import React,  { Fragment, useState, useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

import FormControlErrors from "../components/FormControlErrors";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

import { AuthError, ValidationError } from "../helpers/custom-errors";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

const CustomTagsInputSearch = props => {

	const [isSearchingTag, setIsSearchingTag] = useState(false);
	const [options, setOptions] = useState([]);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	const auth = useAuth();

	
	useEffect(() => {
		let isMounted = true; 
						
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleTagSearch = (query) => {
	    
	    findTags({filter: query})
	      .then(({ list }) => {
				
			  const options = list.map((e,index)=> {
					return ({
	    		  		id: e.id,
						name: e.name
	    	  		})
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Tags Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find tags Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 });
	  }
	  
	  const findTags = (values) => {
		
		setIsSearchingTag(true);
		
		return new Promise((resolve, reject) => {
			API.findCustomTags(values)
			.then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 	setIsSearchingTag(false);
		 	});
		});
		
	}
	
	return(
		<AsyncTypeahead
      		multiple
      		id="tags"
			isInvalid={props.isInvalid}
			disabled={props.disabled} 
			isLoading={isSearchingTag}
			labelKey="name"
			onSearch={handleTagSearch}
			minLength={3}
      		onChange={(selected) => {
        			props.onChange(selected);
        			// Keep the menu open when making multiple selections.
        			typeaheadRef.current.toggleMenu();
      			}}
      		options={options}
			placeholder={t(props.i18nPrefix+"tags-input-search.placeholder")}
			emptyLabel={t(props.i18nPrefix+"tags-input-search.empty-label")}
			searchText={t(props.i18nPrefix+'tags-input-search.searching')}
			promptText={t(props.i18nPrefix+'tags-input-search.prompt')}
      		ref={typeaheadRef}
			renderMenuItemChildren={(option, props) => (
				<Highlighter search={props.text}>
					{option.name}
				</Highlighter>
			)}
			selected={props.tags}

    	/>
	) 
}


export default CustomTagsInputSearch;
