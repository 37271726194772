import React,  { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";
import DataTable from "../components/DataTable";


import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";


import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

const MultipleAccountDepositsFormFields = props => {

	const [ledgersMap, setLedgersMap] = useState(new Map());
	//const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Products
		API.findLedgers()
			.then(response => {
				if (isMounted) {
					let tempMap = new Map();
						
					response.list.forEach(ledger => { tempMap.set(ledger.ledgerNo, ledger.name); } );
						
					setLedgersMap(tempMap);
						
				}
			})
			.catch(error => { 
				log.error("Error Loading Ledgers: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		if (isMounted) {
		 			//setIsLoading(false);
		 		}
			}); 
				
		 return () => { isMounted = false };
		  
	}, []);
	

	return(
		<Fragment>	
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridEventDate">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventDate.label"}>Event Date</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("eventDate", "");
      						}
      					}}
      					date={props.values.eventDate}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors block={true} errors={props.errors.eventDate} />
				</FormGroup>
			
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>
			
			<DataTable
				i18nPrefix={props.i18nPrefix+"datatable."}
				items={props.values.deposits.map((item, index) => Object.assign({},item, {ledgerName: ledgersMap.get(item.ledgerNo)}))}
				columns={["accountNo", "customerDisplayName", "xactTypeCodeExt", "amount", "ledgerName", "xactTypeCode"]} 
				customDisplayColumns={[["amount", "Currency"], ["xactTypeCodeExt", "Enum"], ["xactTypeCode", "Enum"]]}
				//actions={[removeAccountDeposit]}
				onError={props.onError}
			/>
		
		</Fragment>
		
	) 
}


export default MultipleAccountDepositsFormFields;
