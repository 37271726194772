import * as Yup from "yup";

const Roles = [
	"admin",
	"waiter",
	"supplier"
]

const Permissions = [
	"order.export",
	"account.create",
	"transaction.create"
]

const GeneralFormValidationRules = {
  	firstName: Yup.string()
   		.max(100, 'users.save.form.firstName.validation.maximumlength')
   		.required()
   		.default(""),
   	lastName: Yup.string()
   		.max(100, 'users.save.form.lastName.validation.maximumlength')
   		.required()
   		.default(""),
   	displayName: Yup.string()
   		.max(100, 'users.save.form.displayName.validation.maximumlength')
   		.required()
   		.default(""),
   	email: Yup.string()
   		.email()
   		.required()
   		.default(""),
   	mobilePhone: Yup.string()
   		.matches("^\\+[1-9]\\d{1,14}$", 'users.save.form.mobilePhone.validation.format')
   		.required()
   		.default(""),
    active: Yup.boolean()
    	.default(() => true),
}

const AccessControlFormValidationRules = {
	roles: Yup.array()
		.min(1, 'users.save.form.roles.validation.required')
		.default(() => []),
	permissions: Yup.array()
		.default(() => [])
}

const UserConfig = { Roles, Permissions, GeneralFormValidationRules, AccessControlFormValidationRules };

export default UserConfig;