import React from "react";
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import logoHome from '../images/bar-do-tenis.png';

const Home = props => {
	
	const { t } = useTranslation();

	return (
		<Container fluid >
			<div className="p-3 text-center">
				<img 
        			src={logoHome}
        			height="264"
        			alt={t('app.logo')}
				/>
			</div>
		</Container>
	);
};

export default Home;