import React from "react";

import Button from 'react-bootstrap/Button';

import '../styles/RippleButton.css';


const RippleButton = props => {

	return (
		<Button style={props.style} className={(props.className)? "ripple " + props.className : "ripple"}  onClick={props.onClick}>{props.text}</Button>
	);
};

export default RippleButton;