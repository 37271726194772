import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext   } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import CustomContainer from "../components/CustomContainer";

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SingleFilterForm from "../components/SingleFilterForm";
import Filler from "../components/Filler";

import API from "../services/backend-api";

import LedgerConfig from "../config/LedgerConfig";


import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";

const dataTableActionModals = [
	 {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      }
    ];


const Ledgers = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedLedgers, setPagedLedgers] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	let navigate = useNavigate();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageLedgers({})
			.catch(error => { 
		 		log.error("Error Loading Initial Ledgers List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
  

  const updateLedger = (item) => {
	  navigate("/ledgers/update", { state: { item: item}});
  }
  
  const addLedger = () => {
	  navigate("/ledgers/create");
  }
    
  const updatePage = (values) => {
	  pageLedgers(values)
		.catch(error => { 
			log.error("Error Paginating Ledgers List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageLedgers(values)
		.catch(error => { 
			log.error("Error Sorting Ledgers List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Ledgers with no new values
	  pageLedgers({})
		.catch(error => { 
	 		log.error("Error Refreshing Ledgers List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageLedgers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageLedgers(mergeQueryParams(pagedLedgers, values)).then(response => {
			setPagedLedgers(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	const exportLedgers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedLedgers, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findLedgers(exportFilters, auth.isAdmin()).then(response => {

			let columns = LedgerConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.active = t("ledgers.list.export.enums.active."+row.active);
				row.entityType = t("ledgers.list.export.enums.entityType."+row.entityType);
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("ledgers.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	const resetLedgerAccessToken = (item) => {
		
		//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.resetLedgerAccessToken(item.id, config.csrfToken)
			.then(response => {
				context.onSuccess(t("ledgers.save.success"));
			}).catch(error => {
				
				log.error("Error Reseting Ledger Access Token: ", error.message);
				
				context.onError(error);
				
			}).finally(() => {
				handleActionModalHide();
			});	
	}
	
	/*const resetMultipleLedgerAccessTokens = (items) => {
		
	}*/
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedLedgers) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="ledgers"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="ledgers"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

		<PagedList
			i18nPrefix="ledgers.list."
			page={pagedLedgers}
			pageSizeOptions={[10,25,50]}
			filterFormComponent={SingleFilterForm}
			filterParameters={{ filter: pagedLedgers.filter, filteredColumn: pagedLedgers.filteredColumn }}
			onFilter={pageLedgers}
			onExport={exportLedgers}
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addLedger}
			onPage={updatePage}
			dataTableColumns={["ledgerNo", "name", "accountType", "remarks"]} 
			dataTableSortableColumns={["ledgerNo", "name"]}
			dataTableCustomDisplayColumns={[["accountType", "Enum"]]}
			dataTableActions={[updateLedger]}
			dataTableDefaultAction={updateLedger}
			dataTableCustomRowStyle={displayInactive}		
		/>
		</CustomContainer>
	);
}

export default Ledgers;
