import { faMask, faCogs, faBeer, faList, faFingerprint, faTruck, faLandmark, faComments, faFileAudio, faPhotoVideo, faStoreAlt} from '@fortawesome/free-solid-svg-icons'
import { faUserFriends, faUserCog, faGlassCheers, faFileImport, faCalculator, faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { faChartLine, faCoins, faTags, faFileInvoiceDollar, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

export const PrivateNavbarOptionsConfig = [
	{
		path: '/support',
		title: 'header.navigation-titles.support',
		icon: faMask,
		roles: [
			Roles.ROOT
		],
		children: [
			{
				path: '/users',
				title: 'header.navigation-titles.users',
				icon: faUserCog,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/ledgers',
				title: 'header.navigation-titles.ledgers',
				icon: faLandmark,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/media-files',
				title: 'header.navigation-titles.media-files',
				icon: faPhotoVideo,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/accounts/import',
				title: 'header.navigation-titles.accounts-import',
				icon: faFileImport,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/ledger-transactions/import',
				title: 'header.navigation-titles.ledger-transactions-import',
				icon: faFileInvoiceDollar,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/account-transactions/import',
				title: 'header.navigation-titles.account-transactions-import',
				icon: faCalculator,
				roles: [
					Roles.ROOT
				]
			},
			{
				path: '/activities',
				title: 'header.navigation-titles.activities',
				icon: faFingerprint,
				roles: [
					Roles.ROOT
				]
			}
		]
	},
	{
		path: '/admin',
		title: 'header.navigation-titles.admin',
		icon: faCogs,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		children: [
			{
				path: '/products',
				title: 'header.navigation-titles.products',
				icon: faBeer,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/suppliers',
				title: 'header.navigation-titles.suppliers',
				icon: faTruck,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/supplier-orders',
				title: 'header.navigation-titles.supplier-orders',
				icon: faStoreAlt,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/orders',
				title: 'header.navigation-titles.orders',
				icon: faGlassCheers,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/order-sheets/manage',
				title: 'header.navigation-titles.order-sheets-manage',
				icon: faList,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/account-transactions',
				title: 'header.navigation-titles.account-transactions',
				icon: faCalculator,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/account-statements',
				title: 'header.navigation-titles.account-statements',
				icon: faBalanceScale,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/ledger-transactions',
				title: 'header.navigation-titles.ledger-transactions',
				icon: faFileInvoiceDollar,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/inventory-counts',
				title: 'header.navigation-titles.inventory-counts',
				icon: faFileAlt,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/tags',
				title: 'header.navigation-titles.tags',
				icon: faTags,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			}
		]
	},
	{
		path: '/reports',
		title: 'header.navigation-titles.reports',
		icon: faChartLine,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		children: [
			{
				path: '/reports/product-sales',
				title: 'header.navigation-titles.product-sales',
				icon: faCoins,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/reports/inventory-counts',
				title: 'header.navigation-titles.inventory-counts-summary',
				icon: faCalculator,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/reports/accounting-summary',
				title: 'header.navigation-titles.accounting-summary',
				icon: faBalanceScale,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			}
		]
	},
	{
		path: '/waiter',
		title: 'header.navigation-titles.waiter',
		icon: faBeer,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.WAITER
		],
		children: [
			{
				path: '/order-sheets',
				title: 'header.navigation-titles.order-sheets',
				icon: faList,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.WAITER
				]
			},
			{
				path: '/accounts',
				title: 'header.navigation-titles.accounts',
				icon: faUserFriends,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				],
				permissions: [
					Permissions.ACCOUNT_CREATE
				]
			},
			{
				path: '/messages',
				title: 'header.navigation-titles.messages',
				icon: faComments,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/account-activities',
				title: 'header.navigation-titles.account-activities',
				icon: faFingerprint,
				roles: [
					Roles.ROOT
				]
			}
		]
	}
]