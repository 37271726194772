import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import MessageFilterForm from "../components/MessageFilterForm";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import CustomContainer from '../components/CustomContainer';

import MessageDetailModal from "../components/MessageDetailModal";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { jsonToCSV } from "react-papaparse";

import { formatCurrency, formatNumber  } from "../helpers/commons";

import MessageConfig from '../config/MessageConfig';

const dataTableActionModals = [
	{
        key: 'action-0',
        component: MessageDetailModal,
		size: 'xl',
		multiple : false
      }
];

const Messages = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedMessages, setPagedMessages] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		if (isMounted) resetModalsVisibility();
				
		pageMessages({})
			.catch(error => { 
		 		log.error("Error Loading Initial Messages List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const detailMessage = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }
  
  /*const addMessage = () => {
	  //history.push({ pathname: "/orders/create"});
	  let item = {};
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }*/

    
  const updatePage = (values) => {
	  pageMessages(values)
		.catch(error => { 
			log.error("Error Paginating Messages List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageMessages(values)
		.catch(error => { 
			log.error("Error Sorting Messages List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.accountNo = currentPage.accountNo;
		  queryParams.status = currentPage.status;
		  queryParams.direction = currentPage.direction;
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
		  
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Messages with no new values
	  pageMessages({})
		.catch(error => { 
	 		log.error("Error Refreshing Messages List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const pageMessages = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageMessages(mergeQueryParams(pagedMessages, values)).then(response => {
			setPagedMessages(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
 const exportMessages = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		let exportFilters = Object.assign({}, mergeQueryParams(pagedMessages, values));
		delete exportFilters.pageIndex;
		delete exportFilters.pageSize;
		
		API.findMessages(exportFilters).then(response => {
	
			let columns = MessageConfig.ExportHeaders;
			
			//Transform data before parse to csv format
			let data = response.list.map(r => {
				
				let row = Object.assign({}, r);
				
				row.status =  t("messages.list.export.enums.status." + r.status);
				
				return row;
			});
			
			let rows = jsonToCSV(data, {
  					quotes: true, 
  					header: false,
  					columns: columns
			});

			let headers = jsonToCSV({ fields: columns.map(c => t("messages.list.export.columns."+c)), data: [] }, {quotes: true});

			let result = headers + rows;
			
			resolve(result);
			
		}).catch(error => {			
			reject(error);
		});
	});
	
	const syncronizeMessageData = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.syncronizeMessageData(item.messageSid, config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Verifying Message Status: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }
	
/*	const confirmMessageDeletion = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
   }

    const confirmMultipleMessagesDeletion = (items, actionIndex) => {

	  setSelectedDataTableItems(items);
	  showActionModal("multiple-action-"+actionIndex)
    }
	
   const deleteMessage = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteMessage(item.id, auth.isAdmin(),config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Message: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleMessages = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleMessages(listOfItemsId, config.csrfToken).then(response => {
			context.onSuccess(t('messages.delete-multiple-orders-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Messages: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }*/
  
  const renderMessageStatus = (item) => {
	  
	  let variant;
	  
	  switch (item) {
		  
		case "FAILED":
		case "UNDELIVERED":
			variant = "danger";
			break;
		case "PARTIALLY_DELIVERED":
			variant = "warning";
			break;
		case "READ":
			variant = "success";
			break;
		case "CANCELED":
			variant = "secondary";
			break;
		case "QUEUED": 
		case "SENDING": 
		case "SENT": 
		case "DELIVERED":	
		case "RECEIVING":
		case "RECEIVED":
		case "ACCEPTED":
		case "SCHEDULED":
		default:
			variant = "info";
			break;
	 }
	  
	  return <Badge pill variant={variant}><Trans i18nKey={"messages.list.datatable.enums.status."+item}/></Badge>
   }

   	if (isLoading) 
		return <Loader />
	
	if (!pagedMessages) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="orders"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
		<PagedList
			i18nPrefix="messages.list."
			page={pagedMessages}
			list={pagedMessages.list}
			pageSizeOptions={[10,25,50,100]}
			filterFormComponent={MessageFilterForm}
			filterParameters={{ filter: pagedMessages.filter, filteredColumn: pagedMessages.filteredColumn }}
			onFilter={pageMessages} 
			onExport={exportMessages} 
			onError={context.onError}
			onSort={sortDataTable}
			//onAdd={addMessage}
			onPage={updatePage}
			dataTableColumns={["eventTime", "customerDisplayName", "direction", "status", "sender", "recipient"]} 
			dataTableSortableColumns={["eventTime", "direction", "channel"]}
			dataTableCustomDisplayColumns={[["eventTime", "ZonedDateTime"],["status", renderMessageStatus], ["direction", "Enum"]]}
			dataTableActions={[detailMessage, syncronizeMessageData]}	
			dataTableDefaultAction={detailMessage}
			dataTableMultipleSelectActions={[]}
			dataTableHandleTextOverflow={true}
		/>
		</CustomContainer>
	);
}

export default Messages;
