import React, {useState, Fragment} from "react";
import Spinner from 'react-bootstrap/Spinner'


const LazyImage = props => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	
	return (
		<Fragment>
			<Spinner
  				style={(isImageLoaded) ? {display: "none"} : {}}
  				animation="border"
			/>		
     		<img 
     			src={props.src}
     			onLoad={() => { setIsImageLoaded(true);}}
     			style={(!isImageLoaded) ? {display: "none"} : {}}
     			className={!isImageLoaded ? "" : props.className }
     			alt={props.alt}
     		/>
     	</Fragment>	);
};


export default LazyImage;