import * as Yup from "yup";


const FilterFormValidationRules = {
	accountNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "name"),
}

const AdvancedFilterFormFields = {
	filter: true
}

const ExportHeaders =[
	"accountNo", "customerDisplayName", "totalCredit", "totalDebit", "currentBalance"
]

const AccountStatementConfig = {  ExportHeaders, FilterFormValidationRules, AdvancedFilterFormFields};

export default AccountStatementConfig;