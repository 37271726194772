import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import DataTable from "../components/DataTable";

import { isArrayWithLength } from "../helpers/commons";

const ImportErrorsModal = props => {
	
	const { t } = useTranslation();
	
	let allErrors = [];
	
	//log.info(props.errors);
	if (isArrayWithLength(props.errors)) {
		
		props.errors.forEach((rowErrors, rowIndex) => {
			
			if (rowErrors) {
				Object.keys(rowErrors).forEach((key) => {
					allErrors.push({
						"row" : (rowIndex+1), 
						//"column": t(props.i18nPrefix+"form."+key+".label") + " (" + key + ")",
						"column": key, 
						"value": props.data[rowIndex][key], 
						"error" : t(rowErrors[key])
					});
				});
			}

		});
	}
			
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-import-errors"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-import-errors">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="components.import-errors-modal.title">Import Errors Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body>
  				<Container>
  					<Row className="mb-3">
        			<Col>
        			<h4>
        				{t("components.import-errors-modal.header", {count: allErrors.length})}
        			</h4>
        		</Col>
        	</Row>
					<Row>
  						<Col >
							<DataTable 
        						i18nPrefix="components.import-errors-modal.datatable."
								items={allErrors}
							/>
						</Col>
					</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant="secondary" onClick={props.onHide}>
          			<Trans i18nKey="components.import-preview-modal.close">Close</Trans>
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default ImportErrorsModal;
