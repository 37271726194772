import * as Yup from "yup";
import { Regex } from '../helpers/commons'

const FormValidationRules = {
	productId: Yup.string()
		.nullable()
		.default(() => ""),
	supplierNo: Yup.string()
		.required()
		.default(() => ""),
	supplierDisplayName: Yup.string()
		.default(() => ""),
	unitPrice: Yup.string()
		.matches(Regex.CURRENCY, "product-suppliers.save.form.unitPrice.validation.format")
   		.default(() => ""),		
}


const ProductSupplierConfig = { FormValidationRules};

export default ProductSupplierConfig;