import * as Yup from "yup";


const FilterFormValidationRules = {
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	tags: Yup.array()
		.default(() => []),
	
}

const AdvancedFilterFormFields = {
}


const LedgerStatementConfig = { FilterFormValidationRules, AdvancedFilterFormFields};

export default LedgerStatementConfig;