import React, { useState, useEffect, Fragment} from 'react';
//import PropTypes from 'prop-types';  
//import { Prompt } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Prompt from "../components/Prompt";
import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import LedgerTransactionFormFields from "../components/LedgerTransactionFormFields";
import SubjectActivities from "../components/SubjectActivities";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LedgerTransactionConfig from "../config/LedgerTransactionConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveLedgerTransactionValidationSchema = Yup.object().shape({
	...LedgerTransactionConfig.FormValidationRules
});

const SaveLedgerTransactionModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [ledgerTransaction, setLedgerTransaction] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.id) {
			
			API.lookupLedgerTransaction(props.item.id)
				.then(response => {
					setLedgerTransaction(response);
				})
				.catch(error => { 
					log.error("Error Loading Ledger Transaction: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if  (!props.item) {
		return null
	} else if (props.item.id) {
		if (!isLoading && !ledgerTransaction) {
			return null;
		}
	}	
			
	let initialValues = {}, action, actionIcon, validationSchema;
	
	let defaultValues = saveLedgerTransactionValidationSchema.default();
	
	validationSchema = saveLedgerTransactionValidationSchema;
	
	//if ledgerTransaction != null, then we have an update
	if (ledgerTransaction) {		
		Object.keys(LedgerTransactionConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (ledgerTransaction[key]) ? ledgerTransaction[key] : defaultValues[key];
		});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//TODO: Check for consistency depending on Origin
		switch (props.origin) {
  			case "ledger-transactions":
			default:
				break;
		}
	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faCheckCircle
		
		//Override with values from props depending on Origin
		switch (props.origin) {
			case "supplier-order":
				initialValues.ledgerNo = props.item.ledgerNo;
				initialValues.ledgerDrNo = props.item.ledgerDrNo;
				initialValues.amount = props.item.amount;
			default:
				break;
		}

	}
	
	const saveLedgerTransaction = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateLedgerTransaction(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createLedgerTransaction(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-ledger-transaction"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-ledger-transaction">
  					<Trans i18nKey={"ledger-transactions.save.title-"+action}>Save Ledger Transaction</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>
				<Formik
					initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						saveLedgerTransaction(values)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemSaved(t('ledger-transactions.save.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Ledger Transaction Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Ledger Transaction: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-ledger-transaction-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		      		           			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

								<LedgerTransactionFormFields 
									values={values} 
									errors={errors} 
									onChange={handleChange}
									dirty={dirty} 
									onError={props.onError}
									onSuccess={props.onSuccess}
									onWarning={props.onWarning}
									onInfo={props.onInfo}
									setFieldValue={setFieldValue}
									setFieldError={setFieldError}
									action={action}
									origin={props.origin}
									readOnlyFields={{}}
									i18nPrefix="ledger-transactions.save."
								/>

							{(action === "update") && (
		 						<SubjectActivities 
		 							subject="LEDGER_TRANSACTION" 
		 							subjectId={props.item.id} 
		 							onError={props.onError}
		 						/>
		 					)}
	
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="ledger-transactions.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"ledger-transactions.save.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="ledger-transactions.save.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			</Fragment>
			)}
		</Modal>
	);
}

export default SaveLedgerTransactionModal;
