import React,  { useState, useEffect } from 'react';
import { useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import log from 'loglevel';

//import Container from 'react-bootstrap/Container'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import CustomContainer from './CustomContainer';

import API from "../services/backend-api";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import InventoryCountsSummaryToolbar from "../components/InventoryCountsSummaryToolbar";

import { isArrayWithLength, formatPercent } from "../helpers/commons";

import Badge from 'react-bootstrap/Badge';

import { useConfig } from "../services/use-config";


const InventoryCountsSummaryReport = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [inventoryCounts, setInventoryCounts] = useState(null);
	const [summarizedInventoryCounts, setSummarizedInventoryCounts] = useState(null);
	const [displayMode, setDisplayMode] = useState("continuous");
	//const [eventDate, setEventDate] = useState(moment(new Date()));
	
    //const [startDate, setStartDate] = useState(null);
	//const [endDate, setEndDate] = useState(null);

	let context = useOutletContext();
	const { t } = useTranslation();
	const config = useConfig();
	
	
		
	useEffect(() => {
		let isMounted = true; 
		
		API.findInventoryCounts({"sortBy":"eventDate"})
			.then(response => {
				setInventoryCounts(response);

				if(isArrayWithLength(response.list)) {
					
					getContinuousInventoryCountSummary(response.list[0].id)
					
				}
				
			})
			.catch(error => { 
		 		log.error("Error Loading Inventory Counts List : ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);


  const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
  	)
  
  const handleInventoryCountRangeChange = (newInitialInventoryCount, newFinalInventoryCount) => {
		  
      //let currentEventDate = eventDate;
      
      console.log(newInitialInventoryCount, newFinalInventoryCount);

	  setIsRefreshing(true);
	  //setEventDate(newEventDate);
  
	  //Get InventoryCounts Summary
	  getPeriodicInventoryCountSummary(newInitialInventoryCount.id, newFinalInventoryCount.id)
		.catch(error => { 
	 		log.error("Error Refreshing Order Sheets List: ", error.message);
	 		context.onError(error);
		}).finally(() => {
		 		setIsRefreshing(false);
		});	
  } 
  
  const handleDisplayModeChange = (mode) => {
	 
 
	 if ((mode === "continuous" && isArrayWithLength(inventoryCounts.list)) || 
	 	((mode === "periodic" && isArrayWithLength(inventoryCounts.list) && inventoryCounts.list.length >= 2)))  {
		 
			 setIsRefreshing(true);
			 
			 
			 if (mode === "continuous") {
				  getContinuousInventoryCountSummary(inventoryCounts.list[0].id)
				  	.then(response => {
						setDisplayMode(mode);
					})
					.catch(error => { 
	 					log.error("Error Refreshing Continuous Inventory Count Summary: ", error.message);
	 					context.onError(error);
					}).finally(() => {
		 				setIsRefreshing(false);
					});	
			 } else {
				  getPeriodicInventoryCountSummary(inventoryCounts.list[1].id, inventoryCounts.list[0].id)
					.then(response => {
						setDisplayMode(mode);
					})
					.catch(error => { 
	 					log.error("Error Refreshing Periodic Inventory Count Summary: ", error.message);
	 					context.onError(error);
					}).finally(() => {
		 				setIsRefreshing(false);
				});	
			 }
		
		}

  }
  
 
  const getPeriodicInventoryCountSummary = (id1, id2) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.getPeriodicInventoryCountSummary(id1, id2).then(response => {
			setSummarizedInventoryCounts(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
  const getContinuousInventoryCountSummary = (id1) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.getContinuousInventoryCountSummary(id1).then(response => {
			setSummarizedInventoryCounts(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
  
	
 /* const calculateStockMismatch = (i) => {
		
		let mismatch = 0;
		
		mismatch += (i.initialStock) ? i.initialStock : 0;
		mismatch += (i.totalRestockVolume) ? i.totalRestockVolume : 0;
		mismatch -= (i.totalSalesVolume) ? i.totalSalesVolume : 0;
		mismatch -= (i.currentStock) ? i.currentStock : 0;
		
		return mismatch;
		
		
  }*/
  
  const handleDisplayStockCount = (item) => {
	  return (item == null) ? "-": item
  	}
  	
  const handleDisplayAudit = (item) => {
	  
	  let result, style, tooltip;
	  
	  if (item.initialStock == null || item.currentStock == null) {
		  result = "?";
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.missing-item-count");
		  style="warning";
	  } else {
		  
		  let stockMismatch = item.currentStock + item.totalSalesVolume - item.initialStock - item.totalRestockVolume ;
		  
		  if (stockMismatch == 0) {
			  result = "OK";
		  	  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.success");
		  	  style="success";
		  } else if (stockMismatch < 0) {
			  result = stockMismatch;
		  	  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.mismatch-losses", {count: Math.abs(stockMismatch)})
		  	  if (item.totalSalesVolume != 0) {
				 tooltip += ". " + t("inventory-counts.summary.datatable.tooltips.audit.mismatch-losses-inaccuracy", {inaccuracy: formatPercent(Math.abs(stockMismatch)/item.totalSalesVolume, config.preferredLanguage)})
			  }
		  	  style="danger";
		  } else if (stockMismatch > 0) {
			  result = "+" + stockMismatch;
		  	  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.mismatch-gains", {count: stockMismatch});
		  	  if (item.totalSalesVolume != 0) {
				 tooltip += ". " + t("inventory-counts.summary.datatable.tooltips.audit.mismatch-gains-inaccuracy", {inaccuracy: formatPercent(stockMismatch/item.totalSalesVolume, config.preferredLanguage)})
			  }
		  	  style="info";
		  }
		  
	  }
	  
	  return (<OverlayTrigger overlay={renderTooltip(tooltip)}>
					<Badge pill variant={style}>{result}</Badge>
			</OverlayTrigger>)
  	}
  	
  	
  	const handleDisplayAuditInventoryLevels = (item) => {
	  
	  let result, style, tooltip;
	  
	  if (!item.available) {
		  result = <FontAwesomeIcon icon={faTimes} />;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.product-unavailable")
		  style="secondary";
	  } else if (item.currentStock < item.minInventoryLevel) {
		  
		  result = item.currentStock - item.minInventoryLevel;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-bellow-min", {count: Math.abs(result)})
		  tooltip += " " + t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-optimal-gap", {count: item.optimalInventoryLevel - item.currentStock })
		  style="danger";
		  
	  }  else if (item.currentStock == item.minInventoryLevel) {
		  
		  result = item.currentStock - item.minInventoryLevel;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-equal-min", {count: Math.abs(result)})
		   tooltip += " " + t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-optimal-gap", {count: item.optimalInventoryLevel - item.currentStock })
		  style="danger";
		  
	  }  else if (item.currentStock < item.optimalInventoryLevel) {
		  
		  result = item.currentStock-item.optimalInventoryLevel;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-bellow-optimal", {count: Math.abs(result)})
		  style="warning"; 
			  
	  } else if (item.currentStock == item.optimalInventoryLevel) {
		  
		  result = item.optimalInventoryLevel - item.currentStock;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-equal-optimal", {count: Math.abs(result)})
		  style="warning"; 
			  
	  }  else if (item.currentStock < item.maxInventoryLevel) {
		  
		  result = "+"+(item.currentStock-item.optimalInventoryLevel);
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-above-optimal", {count: Math.abs(result)})
		  style="success"; 
	 } else if (item.currentStock == item.maxInventoryLevel) {
		  
		  result = item.currentStock - item.maxInventoryLevel;
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-equal-max", {count: Math.abs(result)})
		  style="info"; 
	 } else if (item.currentStock > item.maxInventoryLevel) {
		  
		  result = "+"+ (item.currentStock - item.maxInventoryLevel);
		  tooltip = t("inventory-counts.summary.datatable.tooltips.audit.inventory-levels-above-max", {count: Math.abs(result)})
		  style="info"; 
	 }
	  
	  return (<OverlayTrigger overlay={renderTooltip(tooltip)}>
					<Badge pill variant={style}>{result}</Badge>
			</OverlayTrigger>)
  	}
  
  /*const calculateCurrentStock = (i) => {
		
		let current = 0;
		
		current += (i.finalStock) ? i.finalStock : 0;
		current += (i.postRestockVolume) ? i.postRestockVolume : 0;
		current -= (i.postSalesVolume) ? i.postSalesVolume : 0;
		
		return current;
  }
  
  const getCalculatedData = (i) => {
		
		let result = {};
		
		result.stockMismatch = calculateStockMismatch(i);
		result.currentStock = calculateCurrentStock(i);
		
		return result;
  }*/


   	if (isLoading || isRefreshing) 
		return <Loader />
	
	if (!inventoryCounts) {
		return null;
	} else if(!isArrayWithLength(inventoryCounts.list) || (displayMode === "periodic" && inventoryCounts.list.length < 2)) {
		return (<CustomContainer>
					<Row><Col>
						<Trans i18nKey="inventory-counts.summary.insuficient-inventory-counts-warning">Insuficient Inventory Counts</Trans>
					</Col></Row>
				</CustomContainer>)
	}
	
	
	if (!summarizedInventoryCounts) 
		return null;
		
	return (
		<CustomContainer>
			{isRefreshing ? <Loader show={isRefreshing}/> : null}

		<InventoryCountsSummaryToolbar
			i18nPrefix="inventory-counts.summary.toolbar."
			displayMode={displayMode}
			inventoryCounts={inventoryCounts.list}
			initialCount={summarizedInventoryCounts.initialCount}
			finalCount={summarizedInventoryCounts.finalCount}
			variant="outline-primary"
			onChange={handleInventoryCountRangeChange}
			onDisplayModeChange={handleDisplayModeChange}
		/>
		
		{(displayMode === "continuous") ? 
			<DataTable
				i18nPrefix="inventory-counts.summary.datatable."
				items={summarizedInventoryCounts.list
					.filter(i => (i.initialStock || i.totalRestockVolume || i.totalSalesVolume || i.currentStock))
					.map( i => Object.assign({}, i,  {"auditInventoryLevels": i, "currentStockRevenue": i.currentStock * i.sellingPrice } )
				
			)}
				columns={["productName", "initialStock", "totalRestockVolume", "totalSalesVolume", "currentStock", "currentStockRevenue", "auditInventoryLevels"]} 
				showTotalRowColumns={["initialStock", "totalRestockVolume", "totalSalesVolume", "currentStock", "currentStockRevenue"]} 
				customDisplayColumns={[["currentStockRevenue", "Currency"], ["auditInventoryLevels", handleDisplayAuditInventoryLevels]]}
				onError={context.onError}
				noItemsDisclaimer={true}
			/>
			:
			<DataTable
				i18nPrefix="inventory-counts.summary.datatable."
				items={summarizedInventoryCounts.list
					.filter(i => (i.initialStock || i.totalRestockVolume || i.totalSalesVolume || i.currentStock))
					.map( i => Object.assign({}, i, {"finalStock": i.currentStock, "audit" : i})
				
			)}
				columns={["productName", "initialStock", "totalRestockVolume", "totalSalesVolume", "finalStock", "audit"]} 
				showTotalRowColumns={["initialStock", "finalStock", "totalRestockVolume", "totalSalesVolume"]} 
				customDisplayColumns={[["audit", handleDisplayAudit],["initialStock", handleDisplayStockCount], ["finalStock", handleDisplayStockCount]]}
				onError={context.onError}
				noItemsDisclaimer={true}
			/>
		}
		
		</CustomContainer>
	);
}

export default InventoryCountsSummaryReport;
