import React from "react";
import { Trans, useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import FormControlErrors from "../components/FormControlErrors";

import { parseInputDate} from "../helpers/commons";

//import { useConfig } from "../services/use-config";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import '../styles/Loader.css';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	start: Yup.string()
		.isValidLocalDate({ message: 'form.validation.string.localdate'})
		.default(() => ""),
	end: Yup.string()
		.isValidLocalDate({ message:  'form.validation.string.localdate'})
		.default(() => "")
});

//loading component for suspense fallback
const DateRangePickerModal = props => {
	
	//const config = useConfig();
	
	let initialValues = {};
	initialValues.start = props.startDate;
	initialValues.end = props.endDate;
	
	return (
		<Modal 
			show={props.show}
			aria-labelledby="contained-modal-daterangepicker"
		    centered
			size={props.size}
			animation={false}
			className="daterangepicker-modal"
			onHide={props.onHide}
			>
			<Modal.Header closeButton>
  				<Modal.Title id="contained-modal-daterangepicker">
  					<Trans i18nKey={props.i18nPrefix+"title"}>Select Date Range</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnChange={false}
				validateOnBlur={false}     
				onSubmit={(values, actions) => {
					props.onDateRangeChange(values.start, values.end)
			 	
			 		actions.setSubmitting(false);
				}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, submitForm}) => (	
	
		<Form className="form-filter-data" noValidate>

			<Modal.Body >
				<FormGroup as={Row} controlId="formGridDateRange" >
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.start"}>Date Range</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(errors.start == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									setFieldValue("start", moment(e.date).format("YYYY-MM-DD"));
      								else
      									setFieldValue("start", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								setFieldValue("start", "");
      							}
      						}}
      						date={values.start}
      						parseInputDate={parseInputDate}
      						locale={props.locale}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={errors.start} />
					</Col>
					<FormLabel column sm="2" className="text-center">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.end"}>until</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(errors.end == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									setFieldValue("end", moment(e.date).format("YYYY-MM-DD"));
      								else
      									setFieldValue("end", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								setFieldValue("end", "");
      							}
      						}}
      						date={values.end}
      						parseInputDate={parseInputDate}
      						locale={props.locale}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={errors.end} />
					</Col>
					</FormGroup>
				</Modal.Body>
          		<Modal.Footer>			 
          			<Button variant="success" type="submit" disabled={isSubmitting}> 
          				{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"applying"}>Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={props.i18nPrefix+"apply"}>Apply</Trans></span>} 
          			</Button>
          			<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey={props.i18nPrefix+"cancel"}>Cancel</Trans></Button>	
          		</Modal.Footer>
			
		</Form>	
		)}
	</Formik>
			 
		</Modal>
	);
};

// Specifies the default values for props:
DateRangePickerModal.defaultProps = {
  size: "lg"
};


export default DateRangePickerModal;