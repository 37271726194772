import React, { Fragment, useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faGlassCheers, faMoneyBillAlt, faInfoCircle, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import FormControlErrors from "../components/FormControlErrors";
import DataTable from "../components/DataTable";

import { useConfig } from "../services/use-config";

import logoHeader from '../images/bar-do-tenis-logo-horizontal.png';
import pixQrCode from '../images/qrcode-pix.jpeg';

import { ValidationError } from "../helpers/custom-errors";

import API from "../services/backend-api";

import queryString from 'query-string'

import VerificationInput from "react-verification-input";

import AccountConfig from "../config/AccountConfig";

import { isArrayWithLength } from "../helpers/commons";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';

import '../styles/AccountPage.css';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(AccountConfig.VerificationFormValidationRules);


const accountTransactionConfig = {
	
	"ADJ_CREDIT" : {
        contentStyle: { background: 'rgb(1, 158, 145)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(1, 158, 145)' },
		iconStyle: { background: 'rgb(1, 158, 145)', color: '#fff' },
		icon: faMoneyBillAlt
      },
	"DEPOSIT" : {
        contentStyle: { background: 'rgb(1, 158, 145)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(1, 158, 145)' },
		iconStyle: { background: 'rgb(1, 158, 145)', color: '#fff' },
		icon: faMoneyBillAlt
      },
	"ADJ_DEBIT" : {
        contentStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(233, 30, 99)' },
		iconStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
		icon: faMoneyBillAlt
      },
	"BAR_DEBIT" : {
        contentStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(233, 30, 99)' },
		iconStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
		icon: faGlassCheers
      },
	 "WITHDRAWAL" : {
        contentStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
        contentArrowStyle: { borderRight: '7px solid  rgb(233, 30, 99)' },
		iconStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
		icon: faMoneyBillAlt
     }
};

const MissingToken = props => {
	return (
		<Fragment>
			<Row className="mb-3">
        		<Col>
        			<h5>
        				<Trans i18nKey={props.i18nPrefix+"missingToken.header"}>Missing Token</Trans>
        			</h5>
        		</Col>
        	</Row>
			<Row className="mb-3">
        		<Col>
        			<Trans i18nKey={props.i18nPrefix+"missingToken.text"}>Missing Token Text</Trans>
        		</Col>
        	</Row>
		</Fragment>
		
	)
}

const AccountVerificationForm = props => {
	
	const { t } = useTranslation();
	
	let initialValues = validationSchema.default();
	
	if (props.accessToken) {
		initialValues["accessToken"] = props.accessToken;
	}
	
	const verifyAccountAccess = (values) =>
		new Promise((resolve, reject) => {
		
			//Clear Error
 			props.onError(null);
		
			API.verifyAccountAccess(values).then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});
	
	
	return (
		<Formik
    		initialValues={initialValues}
    		validationSchema={validationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error
    			props.onError(null);
				
    			verifyAccountAccess(values)
    				.then((response) => {
    					actions.setSubmitting(false);
						props.onVerified(Object.assign({}, response, values));
    				})
    				.catch(error => { 
    								
    					actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Verifying Account Access Attempt Failed: ", error.message);
		
    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}	
    	              		}
							
    					} else {
    						log.error("Error Verifying Account Access: ", error.message);
    					}
	
						props.onError(error);
    							
						
    				})
			}}
		    >
		    {({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		    	<Form className="form-customer-verification" noValidate>
					<Row className="mb-3">
        				<Col>
        					<FontAwesomeIcon icon={faLock} /> 
        				</Col>
        			</Row>
					<Row className="mb-3">
        				<Col>
        					<h5>
        						{t(props.i18nPrefix+"header")}
        					</h5>
        				</Col>
        			</Row>
					<Row className="mb-3">
        				<Col>
        					<Trans i18nKey={props.i18nPrefix+"form.verificationCode.text-1"}>Enter Verification Code</Trans>
        				</Col>
        			</Row>
					<Row className="mb-3">
        				<Col>
        					<Trans i18nKey={props.i18nPrefix+"form.verificationCode.text-2"}>Enter Verification Code Example</Trans>
        				</Col>
        			</Row>
					<Row>		
						<FormGroup as={Col} className="mb-1" controlId="formVerificationCode">
							<VerificationInput 
								validChars="0-9"
  								inputProps={{type: "tel"}}
								length={4}
								placeholder="_"
								classNames={{
    								container: "",
									character: (errors.verificationCode) ? "form-control border-danger" : "form-control"
  								}}
								autoFocus={true}
								onChange={(value) => {setFieldValue("verificationCode", value)}}
							/> 
						</FormGroup>
						<FormControlErrors errors={errors.verificationCode} />
					</Row>
					<Row>
						<Col>
						<Button type="submit" disabled={isSubmitting || values.verificationCode.length < 4} > 
							{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"form.submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"form.submit"}>Submit</Trans>} 
						</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
	)

}

const AccountTransactionDetailModal = props => {
	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [accountTransactionDetail, setAccountTransactionDetail] = useState(null);
	
	const config = useConfig();
	
	let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});

	useEffect(() => {
		let isMounted = true; 
		
		
		if (props.item && props.item.xactTypeCodeExt === "BAR_DEBIT") {
			
			API.findAccountStatementTransactionOrders(props.item.id, {accessToken: props.accessToken, verificationCode: props.verificationCode})
				.then(response => {
					
					let transactionDetail = {};
					transactionDetail.id = props.item.id;
					transactionDetail.accountNo = props.item.accountNo;
					transactionDetail.customerDisplayName = props.item.customerDisplayName;
					transactionDetail.eventDate = props.item.eventDate;
					transactionDetail.remarks = props.item.remarks;
					transactionDetail.orders = [];
					transactionDetail.aggregattedOrders = {};
					transactionDetail.customerBill = 0;
					
					//Filter only opened orders = without transactionId
				   response.list.forEach((order, index) => {
				   		
						transactionDetail.orders.push(order);
						transactionDetail.customerBill += order.quantity*order.unitPrice;
						
						if (!transactionDetail.aggregattedOrders[order.product]) {
							let newProduct = {};
							newProduct.quantity = order.quantity;
							newProduct.description = order.product;
							newProduct.unitPrice = order.unitPrice;
							newProduct.total = order.quantity*order.unitPrice;
							
							transactionDetail.aggregattedOrders[order.product] = newProduct;
							
						} else {
							
							transactionDetail.aggregattedOrders[order.product].quantity += order.quantity;
							transactionDetail.aggregattedOrders[order.product].total += order.quantity*order.unitPrice;
						}
						
				    });
	
					setAccountTransactionDetail(transactionDetail);
					
				}).catch(error => {			
					log.error("Error Loading Account Transaction Orders: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
			
		} else {
			setIsLoading(false);
		}
		 
		return () => { isMounted = false };
		  
	}, []);
	
	if  (!props.item) {
		return null
	} else if (props.item.id) {
		if (!isLoading && !accountTransactionDetail && props.item.xactTypeCodeExt === "BAR_DEBIT") {
			return null;
		}
	}	
	
	const buildFooterRow = (barTab) => {
		return (<tr className="font-weight-bold" style={{border: "1px"}}>
			<td colSpan="3" className="text-right"><Trans i18nKey="ordersheets.account-bar-tab-modal.datatable.columns.total" />=</td>
			<td>{currency.format(barTab.customerBill)}</td>
		</tr>)	
	};
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-account-transaction-detail"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-account-transaction-detail">
  					<Trans i18nKey={props.i18nPrefix+"title."+props.item.xactTypeCodeExt}>Transaction Detail Title</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3">
					 <Row>
						<Col className="text-center">
							<Spinner animation="border" />
						</Col>
					</Row>
				</Modal.Body>
			) : (
			<Modal.Body >
  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
						
				{(props.item.xactTypeCodeExt === "BAR_DEBIT") ?
					<DataTable
							i18nPrefix={props.i18nPrefix+"datatable."}
							items={Object.values(accountTransactionDetail.aggregattedOrders)}
							columns={["quantity", "description", "unitPrice", "total"]} 
							customDisplayColumns={[["unitPrice", "Currency"],["total", "Currency"]]}
							customFooterRow={buildFooterRow(accountTransactionDetail)}
							noItemsDisclaimer={true}
							//onError={props.onError}
					/>
					: <p>{(props.item.remarks) ? props.item.remarks : <Trans i18nKey={props.i18nPrefix+"no-remarks"} />}</p>
				}
		  	</Modal.Body>
			)}
          	<Modal.Footer>			      		
          		<Button variant="secondary" onClick={props.onHide}>
  					 <Trans i18nKey={props.i18nPrefix+"close"}>Close</Trans>
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

/*const AccountStatementTransactions = props => {
   
	const { t } = useTranslation();
	const config = useConfig();
	
	let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});
	
	
	useEffect(() => {
		let isMounted = true; 		
			
		return () => { isMounted = false };
		  
	},[]);
	
	
	const elements = props.transactions.map((el,index) => {
		
		return (
			<VerticalTimelineElement
    			key={index}
				className="vertical-timeline-element--work"
    			contentStyle={accountTransactionConfig[el.xactTypeCodeExt].contentStyle}
    			contentArrowStyle={accountTransactionConfig[el.xactTypeCodeExt].contentArrowStyle}
    			iconStyle={accountTransactionConfig[el.xactTypeCodeExt].iconStyle}
    			icon={<FontAwesomeIcon icon={accountTransactionConfig[el.xactTypeCodeExt].icon} /> }
  			>
					<h6 className="vertical-timeline-element-title"><Moment local format="L">{el.eventDate}</Moment></h6>
					<p><Trans i18nKey={props.i18nPrefix+"types.options."+el.xactTypeCodeExt} /></p>
					<p>{currency.format(el.amount)}</p>
  			</VerticalTimelineElement>
		)	
	})

	return (
		<VerticalTimeline layout="1-column-left">
			{elements}    			
		</VerticalTimeline>
	)
}*/

const AccountStatement = props => {
    const [showTransactionDetail, setShowTransactionDetail] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isPaginating, setIsPaginating] = useState(false);
	const [pagedAccountStatementTransactions, setPagedAccountStatementTransactions] = useState(null);
	const [allAccountTransactions, setAllAccountTransactions] = useState([]);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});
	
	
	useEffect(() => {
		let isMounted = true; 
		
		pageAccountStatementTransactions()
			.then(response => {
				
				const transactions = Array(response.totalCount).fill().map((e,index)=> {
			  		if (response.list[index])
			  			return (response.list[index])
			  		else
			   			return ({})
			  });  	 
				
				if (isMounted) {
					setPagedAccountStatementTransactions(response);
					setAllAccountTransactions(transactions);
				}
				
			})
			.catch(error => { 
		 		log.error("Error Loading Initial Account Statement Transactions List: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		
			
		return () => { isMounted = false };
		  
	},[props.accountStatement]);
	
	const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		
		  queryParams.start = currentPage.start;
		  queryParams.end = currentPage.end;
		  queryParams.types = currentPage.types;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }

      //Finally, accessToken and verificationCode
	  queryParams.accessToken = props.accountStatement.accessToken;
	  queryParams.verificationCode = props.accountStatement.verificationCode;
	  
	  return queryParams;
	  
  	}

	const pageAccountStatementTransactions = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
 			props.onError(null);
		
			API.pageAccountStatementTransactions(mergeQueryParams(pagedAccountStatementTransactions, values)).then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedAccountStatementTransactions) 
		return null;
		
		
	const isDebtor = () => {
		return (parseFloat(props.accountStatement.currentBalance) < 0)
	}
	
	const handleCloseTransactionDetail = () => {
		setShowTransactionDetail(false);
		setSelectedTransaction(null);
	}
  	const handleShowTransactionDetail = (item) => {
		setShowTransactionDetail(true);
		setSelectedTransaction(item);
		
	}
	
	const handlePagination = (values) => {
		
		//const pageIndex = Math.ceil(number/pageSize) - 1;
	
		setIsPaginating(true);
		
		pageAccountStatementTransactions(values)
	      .then(response => {
			  
			  let updatedTransactions = allAccountTransactions;
			  
			  response.list.forEach((item, index) => {
			  		updatedTransactions[response.pageSize*response.pageIndex + index] = item;
			  });
		 	 
			  setPagedAccountStatementTransactions(response);
			  setAllAccountTransactions(updatedTransactions);
	    	  
	       })
		  .catch(error => { 
		 		log.error("Error Paginating Account Statement Transactions: ", error.message);
		 		props.onError(error);
			})
	      .finally(() => {
		 		setIsPaginating(false);
		  });
		
	}

	return (
		<Fragment>
			{(selectedTransaction) && <AccountTransactionDetailModal
				item={selectedTransaction}
				show={showTransactionDetail}
        		onHide={handleCloseTransactionDetail}
				accessToken={props.accountStatement.accessToken}
	  			verificationCode={props.accountStatement.verificationCode}
				size="lg"
				i18nPrefix={props.i18nPrefix+"transaction-detail-modal."}
			/>}
			<Row className="mb-3">
        		<Col>
        			<h5>
        				{t(props.i18nPrefix+"header", {customerDisplayName: props.accountStatement.customerDisplayName})}
        			</h5>
        		</Col>
        	</Row>
			<Row className="mb-5">
        		<Col>
        			<Card className="text-white" style={isDebtor() ? {backgroundColor: "#2196f3"} : {backgroundColor: "#2196f3"}}>
						<Card.Body>
							<Card.Text><Trans i18nKey={props.i18nPrefix+"currentBalance.label"} /></Card.Text>
							<Card.Title>{currency.format(props.accountStatement.currentBalance)}</Card.Title>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			
			{ isArrayWithLength(pagedAccountStatementTransactions.list) ?
				<Fragment>
				<Row>
        		<Col>
        			<h5>
        				{t(props.i18nPrefix+"transactions.label")}
        			</h5>
        		</Col>
        		</Row>
				<Row>
        			<Col>
						<VerticalTimeline layout="1-column-left" lineColor="#ffffff">
						 {allAccountTransactions.map((item,index) => {
							if (item.id)  
								return (<VerticalTimelineElement
    								key={index}
									className="vertical-timeline-element--work"
									onTimelineElementClick={() => {handleShowTransactionDetail(item)}}
    								contentStyle={accountTransactionConfig[item.xactTypeCodeExt].contentStyle}
    								contentArrowStyle={accountTransactionConfig[item.xactTypeCodeExt].contentArrowStyle}
    								iconStyle={accountTransactionConfig[item.xactTypeCodeExt].iconStyle}
    								icon={<FontAwesomeIcon size="lg" icon={accountTransactionConfig[item.xactTypeCodeExt].icon} /> }
  								>
									<h6 className="vertical-timeline-element-title"><Moment local format="DD/MM/YYYY (ddd)">{item.eventDate}</Moment></h6>
									<p><Trans i18nKey={props.i18nPrefix+"transactions.types.options."+item.xactTypeCodeExt} /></p>
									<p>{currency.format(item.amount)}</p>
									{(item.xactTypeCodeExt === "BAR_DEBIT" || item.remarks)
										? <Button className="text-white" variant="link"><FontAwesomeIcon size="lg" icon={faInfoCircle} /></Button>
										: null
									}
  								</VerticalTimelineElement>)
							else return null;
						})}
						{/*(pagedAccountStatementTransactions.hasNext) ?
							<VerticalTimelineElement
									iconClassName="vertical-timeline-element-icon--button"
									//className="vertical-timeline-element--work"
									iconOnClick={() => { handlePagination({pageIndex: pagedAccountStatementTransactions.pageIndex+1})}}
    								iconStyle={accountTransactionConfig["LOAD_MORE"].iconStyle}
    								icon={<FontAwesomeIcon size="2x" icon={accountTransactionConfig["LOAD_MORE"].icon} /> }
  								>
								</VerticalTimelineElement>
							: null
						*/}
						</VerticalTimeline>
						{(pagedAccountStatementTransactions.hasNext) ?
							<Button
								style={{background: '#3f51b5', borderColor: '#3f51b5'}}
								size="lg"
								block
								disabled={isPaginating}
								onClick={() => { handlePagination({pageIndex: pagedAccountStatementTransactions.pageIndex+1})}}
  							>
								{(isPaginating) 
									? <Spinner animation="border" /> 
									:
									<Fragment>
										<FontAwesomeIcon size="lg" icon={faArrowDown} />&nbsp;
										<Trans i18nKey={props.i18nPrefix+"transactions.load-more-button"} />&nbsp;
										<FontAwesomeIcon size="lg" icon={faArrowDown} />
									</Fragment>
								}
							</Button>
							: null
						}        				
					</Col>
				</Row>
				</Fragment>
				: null
			}
			{/*<Row>
				<Col>
					<Image src={pixQrCode}/>
				</Col>
			</Row>*/}
		</Fragment>
	)
}

const AccountPage = props => {
   const [_error, _setError] = useState(null);
   const [_success, _setSuccess] = useState(null);
   const [_warning, _setWarning] = useState(null);
   const [_info, _setInfo] = useState(null);
   const [accountStatement, setAccountStatement] = useState(null);

  let location = useLocation();
  const { t } = useTranslation();
  
  //let { token } = useParams();

  let queryParams = queryString.parse(location.search);

  useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			//Clear Error/Success every time location changes
			_setError(null);
			_setSuccess(null);
			_setWarning(null);
			_setInfo(null);
		}
			
		return () => { isMounted = false };
		  
	}, [location]);

  const onError = (error) => { 
	 if (!error) {
		 _setError(null);
		 return;
	 }

	 if (error instanceof ValidationError) {	
		_setError(error.message);
	 } else {
		_setError(t('error.api.general'));
	}
 };
 
 const onSuccess = (success) => {
	_setSuccess(success);
 };

const onWarning = (warning) => {
	_setWarning(warning);
 };

const onInfo = (info) => {
	_setInfo(info);
 };

  
  const handleAccountVerified = (data) => {
		setAccountStatement(data);
   }
		

  return (
	<Fragment>
		{/*<Header 
			onError={onError}
			onSuccess={onSuccess}
			onWarning={onWarning}
			onInfo={onInfo}
		/>*/}
		
		<Container fluid style={{maxWidth:"375px", padding:"0px"}} >
			<Card className="text-center" style={{/*border:"0px"*/}}>
      			<Card.Header style={{backgroundColor: "#CB5223"}}>
					<Image src={logoHeader} height="45"/>
				</Card.Header>
      			<Card.Body>
        			      
			<DismissableFeedback feedback={_error} onClose={() => onError(null)} type="danger" />
			<DismissableFeedback feedback={_success} onClose={() => onSuccess(null)} type="success" />
			<DismissableFeedback feedback={_warning} onClose={() => onWarning(null)} type="warning" />
			<DismissableFeedback feedback={_info} onClose={() => onInfo(null)} type="info" />

			{ (!queryParams || !queryParams.token) ?
				<MissingToken i18nPrefix={"accounts.verify."}/>
				: 
				(!accountStatement) ? 
				< AccountVerificationForm
					i18nPrefix={"accounts.verify."}
					onVerified={handleAccountVerified}
					accessToken={queryParams.token}
					onError={onError}
				 />
				:  
				<AccountStatement 
					i18nPrefix={"accounts.statement."}
					accountStatement={accountStatement}
					queryParams={queryParams}
					onError={onError}
				/>
			}
			
			</Card.Body>

			{/* (accountStatement) ?
				<Card.Footer className="text-muted">Extrato</Card.Footer>
				: null
			*/}
    	</Card>
		</Container>
	</Fragment>
  );
}

export default AccountPage;