import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import SaveInventoryCountLineItemModal from "../components/SaveInventoryCountLineItemModal";

import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import InventoryCountConfig from "../config/InventoryCountConfig";

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { useConfig } from "../services/use-config";

import { parseInputDate, isArrayWithLength, currencySymbol, formatCurrency } from "../helpers/commons";
import DataTable from './DataTable';


const InventoryCountFormFields = props => {
	
	const [saveLineItemModalShow, setSaveLineItemModalShow] = useState(false);
	const [deleteLineItemModalShow, setDeleteLineItemModalShow] = useState(false);
	const [selectedLineItem, setSelectedLineItem] = useState(null);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	
	
	const newLineItem = () => {
	  setSelectedLineItem(null);
	  setSaveLineItemModalShow(true);
    }

 	const updateLineItem = (item) => {
	  setSelectedLineItem(item);
	  setSaveLineItemModalShow(true);
 	}
 	
 	const confirmLineItemRemoval = (item, index) => {
	  setSelectedLineItem(item);
	  setDeleteLineItemModalShow(true);
  }

    
    const handleLineItemSaved = (action, item) => {
	  setSaveLineItemModalShow(false);
	  
	  let lineItems = props.values.items;
	  
	  if (action === "create") {
		  lineItems.push(item);
	  } else {
		  let foundIndex = lineItems.findIndex((i) => i.productId == item.productId);
		  
		  if (foundIndex > -1)
		  	lineItems.splice(foundIndex, 1, item);
	  }
		  
	  props.setFieldValue("items", lineItems);
	  props.setFieldError("items", null);
	  
  }
  

  
 
  const deleteLineItem = (item) => {
		
		setDeleteLineItemModalShow(false);
		
		let lineItems = props.values.items;
		
		let foundIndex = lineItems.findIndex((i) => i.productId == item.productId);
		  
		lineItems.splice(foundIndex, 1);
		
		props.setFieldValue("items", lineItems);
		props.setFieldError("items", null);
		
  }


	return(
		<Fragment>	
		
			{(saveLineItemModalShow) && <SaveInventoryCountLineItemModal
				show={saveLineItemModalShow}
	        	onHide={() => setSaveLineItemModalShow(false)}
				size="lg"
				item={selectedLineItem}
				lineItems={props.values.items}
				onItemSaved={handleLineItemSaved}
		      />
			}
			{(selectedLineItem) && <ConfirmationDialogModal
				item={selectedLineItem}
				show={deleteLineItemModalShow}
        		onHide={() => setDeleteLineItemModalShow(false)}
				size="lg"
				title={t("inventory-counts.delete-item-confirmation-modal.title")}
				bodyText={t("inventory-counts.delete-item-confirmation-modal.body", {item: selectedLineItem})}
				confirmText={t("inventory-counts.delete-item-confirmation-modal.confirm")}
				cancelText={t("inventory-counts.delete-item-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteLineItem}
			/>}
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridEventDate">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventDate.label"}>Event Date</Trans> *</FormLabel>
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
								if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("eventDate", "");
      							}
      						}}
      						date={props.values.eventDate}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
					<FormControlErrors block={true} errors={props.errors.eventDate} />
				</FormGroup>
				
				<FormGroup as={Col} md={3} controlId="formGridEventTime">
				   <FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventTime.label"}>Select Event Time</Trans> * <FormControlHelper text={props.i18nPrefix+"form.eventTime.helper"}/></FormLabel>
					<FormControl as="select" readOnly={props.readOnlyFields["eventTime"]} name="eventTime" isInvalid={!(props.errors.eventTime == null)} value={props.values.eventTime} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.eventTime.options.blank")}</option>
		    			{ InventoryCountConfig.InventoryCountTimeOptions.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.eventTime.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors block={true} errors={props.errors.eventTime} />
				</FormGroup>

				<FormGroup as={Col} md={6} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>

			<Row>
				<FormGroup as={Col} controlId="formGridItems" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.items.label"}>Items</Trans></FormLabel>
					<fieldset>
						<div className={(props.errors.items == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							<Row className="align-items-center">
							 	<Col>
							 		<DataTable
        								items={props.values.items} 
        								i18nPrefix={props.i18nPrefix+"form.items.datatable."}
        								columns={["productId", "productName", "quantity", "unitPrice", "totalAmount"]} 
        								customDisplayColumns={[["unitPrice", "Currency"],["totalAmount", "Currency"]]}
        								actions={[updateLineItem, confirmLineItemRemoval]}
        								defaultAction={updateLineItem}
        								
        								//noItemsDisclaimer={true}
        							/>
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" onClick={newLineItem}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.items.add-button"}>Add Item</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.items} />
				</FormGroup>
		   </Row>
		
		</Fragment>
		
	) 
}


export default InventoryCountFormFields;
