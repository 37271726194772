
const Actions = [
	  "CREATE",
 	  "UPDATE"
 ];

const DelimiterOptions = [
	  {
        key: 'auto-detect',
		value: ""
      },
      {
        key: 'comma',
		value: ','
      },
	  {
        key: 'semicolon',
		value: ';'
      },
      {
        key: 'tab',
		value: '	'
      },
      {
        key: 'pipe',
		value: '|'
      },
	  
 ];

const FirstRowHeaderOptions = [
	  {
        key: 'true',
		value: true
      }/*,
      {
        key: 'false',
		value: false
      }*/
 ];

const ColumnMappingStrategyOptions = [
	  {
        key: 'position'
      }/*,
      {
        key: 'name'
      }*/
 ];




const ImportConfig = { Actions, DelimiterOptions, FirstRowHeaderOptions, ColumnMappingStrategyOptions };

export default ImportConfig;