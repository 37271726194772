import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faExchangeAlt} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';

import FormControlErrors from "../components/FormControlErrors";

import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate, isArrayWithLength, currencySymbol, formatCurrency } from "../helpers/commons";

import NumericInput from 'react-numeric-input';

import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";


const InventoryCountLineItemFormFields = props => {

	const [products, setProducts] = useState([]);	
	const [selectedProducts, setSelectedProducts] = useState([]);
	
	const typeaheadRef = useRef(null);
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Products
		if (!isArrayWithLength(props.products)) {
			
			//Filter available products only for non-admin users
			let filter = {};
			
			API.findProducts(filter)
				.then(response => {
					if (isMounted) {
						setProducts(response.list);
						if (props.values.productId) {
							let found = response.list.find((item) => (item.productId === props.values.productId))
							if (found) setSelectedProducts(new Array(1).fill(found));
						}
					}
				})
				.catch(error => { 
					log.error("Error Loading Products: ", error.message);
		 			props.onError(error);
				})
		} else {
			setProducts(props.products);
			
			if (props.values.productId) {
				let found = props.products.find((item) => (item.productId === props.values.productId))
				if (found) setSelectedProducts(new Array(1).fill(found));
			}
		}
			
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleProductChange = (products) => {
			
		if(isArrayWithLength(products)) { 
			setSelectedProducts(products);
			props.setFieldValue("productId", products[0].productId);
			props.setFieldValue("productName", products[0].name);
			
		} else { 
			setSelectedProducts([]);
			props.setFieldValue("productId", "");
			props.setFieldValue("productName", "");
		}
    }
    
    
    const handleQuantityChange = (valueAsNumber) => {
	
		props.setFieldValue("quantity",valueAsNumber);
		
	}
    

	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Row>
				<Col>
				<Highlighter search={properties.text}>
            		{option.name}
         		</Highlighter>,
          		<div>
            		<small>
              			<Trans i18nKey={props.i18nPrefix+'form.productId.menu-item-details'} values={{item: Object.assign({}, option, { sellingPriceFormatted: formatCurrency(option.sellingPrice, config.preferredLanguage, config.numberFormat.currency)})}}/>
            		</small>
          		</div>
				</Col>
				{(props.mockups && props.mockups.get(option.productId)) ?
					<Col md={4} className="text-center"><Image src={props.mockups.get(option.productId)} height="50px"/></Col>
					: null
				}
				</Row>
        	</Fragment>
    	)
    }

   
	return(
		<Fragment>	
			<Row>
				
				<FormGroup as={Col} md={9} controlId="formGridProduct">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.productId.label"}>Product</Trans> *</FormLabel>
					{/*<FormControl as="select" disabled={props.readOnlyFields["productId"]} name="productId" isInvalid={!(props.errors.productId == null)} value={props.values.productId} onChange={handleProductChange} >
						<option value="">{t(props.i18nPrefix+"form.productId.blank-option")}</option>
		    			{ products.map(item =>
		    				<option key={item.productId} value={item.productId+""}>{item.name}</option>
		    			)}
		    		</FormControl>*/}
					<Typeahead
      						id="productId"
							clearButton
							isInvalid={!(props.errors.productId == null)}
							labelKey={(option) => option.name }
      						onChange={handleProductChange}
      						options={products.filter(p => (!props.lineItems || !props.lineItems.some(li => li.productId === p.productId)))}
      						placeholder={t(props.i18nPrefix+"form.productId.placeholder")}
      						ref={typeaheadRef}
      						selected={selectedProducts}
							renderMenuItemChildren={renderMenuItemChildren}
							disabled={props.readOnlyFields["productId"]} 
    					/>
					<FormControlErrors errors={props.errors.productId} />
				 </FormGroup>
			
				 <FormGroup as={Col} md={3} controlId="formGridQuantity">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.quantity.label"}>Quantity</Trans> *</FormLabel>
					<NumericInput className={!(props.errors.quantity == null) ? "form-control is-invalid" : "form-control" } strict={true} min={0} name="quantity" value={props.values.quantity} onChange={handleQuantityChange} />
					<FormControlErrors block={true} errors={props.errors.quantity} />
				</FormGroup>
				
		</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>

		
		</Fragment>
		
	) 
}


export default InventoryCountLineItemFormFields;
