import * as Yup from "yup";
import moment from "moment";
import { Regex } from '../helpers/commons'
import ImportConfig from "../config/ImportConfig";

Yup.addMethod(Yup.string, "isValidLocalDate", function(anyArgsYouNeed) {
    const { message } = anyArgsYouNeed;
    return this.test("isValidLocalDate", message, function(value) {
        const { path, createError } = this;
		//const { maxDate } = anyArgsYouNeed;
		
        return (value === "")
			   || moment(value, 'YYYY-MM-DD', true).isValid() 
			   || createError({ path, message: message });
    });
});

const FormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	ledgerNo: Yup.string()
		.required()
		.default(() => ""),
	ledgerDrNo: Yup.string()
		.required()
		.default(() => ""),
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'ledger-transactions.save.form.eventDate.validation.format'})
		.required()
		.default(() => ""),
	amount: Yup.string()
		.matches(Regex.CURRENCY, "ledger-transactions.save.form.amount.validation.format")
   		.default(() => ""),
	remarks: Yup.string()
   		.max(255, 'ledger-transactions.save.form.remarks.validation.maximumlength')
   		.default(""),
	tags: Yup.array()
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
			
}


const MultipleRechargesFormValidationRules = {
	eventDate: Yup.string()
		.isValidLocalDate({ message: 'ledger-transactions.save.form.eventDate.validation.format'})
		.required()
		.default(() => ""),
	remarks: Yup.string()
   		.max(255, 'ledger-transactions.save.form.remarks.validation.maximumlength')
   		.default(""),
	tags: Yup.array()
		.default(() => []),
	recharges: Yup.array()
		.min(1, 'ledger-transactions.save-multiple-recharges.form.recharges.validation.required')
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const MultipleTransactionsTagsFormValidationRules = {
	tags: Yup.array()
		.default(() => []),
	transactions: Yup.array()
		.min(1, 'ledger-transactions.save-multiple-transactions-tags.form.transactions.validation.required')
		.default(() => []),
	utcOffset: Yup.number()
			.default(() => moment().utcOffset()),	
}

const FilterFormValidationRules = {
	ledgerNo: Yup.string()
		.default(() => ""),
	ledgerDrNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "remarks"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	tags: Yup.array()
		.default(() => []),
	
}

const AdvancedFilterFormFields = {
	filter: true,
	ledgerDrNo : true,
	tags: true
}


const ImportHeaders =[
	"id", "eventDate", "ledgerNo", "ledgerName", "ledgerDrNo", "ledgerDrName", "amount", "remarks", "tags",
	/*"whenCreated", "whenModified"*/
]

const ExportHeaders =[
	"id", "eventDate", "ledgerNo", "ledgerName", "ledgerDrNo", "ledgerDrName", "amount", "remarks", "tags", "whenModified", "whenCreated"
]

const ImportActions = ImportConfig.Actions;
const ImportDelimiterOptions = ImportConfig.DelimiterOptions;
const ImportFirstRowHeaderOptions = ImportConfig.FirstRowHeaderOptions;
const ImportColumnMappingStrategyOptions = ImportConfig.ColumnMappingStrategyOptions;

const ImportFormValidationRules = {
	   action: Yup.string()
		.oneOf([""].concat(ImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(FormValidationRules))
		//.unique('ledger-transactions.import.form.data.validation.duplicated-id', "id")
		.max(255, 'ledger-transactions.import.form.data.validation.maximum-size' )
		.default(() => []),
}

const LedgerTransactionConfig = { FormValidationRules, MultipleRechargesFormValidationRules, 
	MultipleTransactionsTagsFormValidationRules, FilterFormValidationRules, 
	AdvancedFilterFormFields, ImportHeaders, ExportHeaders, ImportFormValidationRules,
	ImportActions, ImportDelimiterOptions, ImportFirstRowHeaderOptions, ImportColumnMappingStrategyOptions};

export default LedgerTransactionConfig;