import * as Yup from "yup";
import { Regex } from '../helpers/commons'


const MessageStatus =  [
		"QUEUED",
		"SENDING",
		"SENT",
		"FAILED",
		"DELIVERED",
		"UNDELIVERED",
		"RECEIVING",
		"RECEIVED",
		"ACCEPTED",
		"SCHEDULED",
		"READ",
		"PARTIALLY_DELIVERED",
		"CANCELED"
	]
	
const Participants =  [
	"participant",
	"sender",
	"recipient"
]
	
const Channels = [
	"WHATSAPP",
	"SMS"
]

const Directions = [
	"INBOUND",
	"OUTBOUND"
]

const ContentTypes = [
	"MEDIA_URL",
	"BODY",
	"CONTENT_SID"
]

/*const FormValidationRules = {
   direction: Yup.string()
		.oneOf([""].concat(Directions), 'form.validation.string.enum')
		.required()
   		.default(() => "OUTBOUND"),
   channel: Yup.string()
		.oneOf([""].concat(Channels), 'form.validation.string.enum')
		.required()
   		.default(() => "WHATSAPP"),
   sender: Yup.string()
		.matches(Regex.MOBILE_PHONE, { message: 'messages.save.form.sender.validation.format', excludeEmptyString: true })
   		.default(""),
   recipient: Yup.string()
		.matches(Regex.MOBILE_PHONE, { message: 'messages.save.form.recipient.validation.format', excludeEmptyString: true })
   		.required()
   		.default(""),
   contentType: Yup.string()
		.oneOf([""].concat(ContentTypes), 'form.validation.string.enum')
		.required()
   		.default(() => "BODY"),
   content: Yup.string()
   		.max(255, 'messages.save.form.content.validation.maximumlength')
   		.default(""),
   contentVariables: Yup.object()
   		.default({}),
}*/


const FormValidationRules = {
   accountNo: Yup.string()
		.required()
   		.default(() => ""),
   contentType: Yup.string()
		.oneOf([""].concat(ContentTypes), 'form.validation.string.enum')
		.required()
   		.default(() => "BODY"),
   content: Yup.string()
   		.required()
   		.max(255, 'messages.save.form.content.validation.maximumlength')
   		.default("")
}

const FilterFormValidationRules = {
	accountNo: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
	    .oneOf([""].concat(Participants), 'form.validation.string.enum')
		.default(() => "participant"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	status: Yup.array()
		.default(() => []),
	direction: Yup.string()
	    .oneOf([""].concat(Directions), 'form.validation.string.enum')
		.default(() => ""),
	
}

const AdvancedFilterFormFields = {
	filter: true,
	status : true
}

const ExportHeaders =[
	"messageSid", "eventTime", "accountNo", "customerDisplayName", "direction", "status", "channel", "sender", "recipient", "provider", "subject", "whenModified"
]

const MessageConfig = { MessageStatus, Participants, Directions, Channels, ContentTypes, FormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields, ExportHeaders};

export default MessageConfig;