import * as Yup from "yup";

const AccountTypes = [
	"ASSET",
	"LIABILITY",
	"REVENUE",
	"EXPENSE",
	"GAIN",
	"LOSSES",
	"CAPITAL",
	"DRAWING"
]

const ActiveOptions = [
	true,
	false
]

const FormValidationRules = {
   ledgerNo: Yup.string()
   		.matches("^[1-9]{1}\\d{2}$", 'ledgers.save.form.ledgerNo.validation.format')
   		.required()
   		.default(() => ""),
   name: Yup.string()
   		.max(100, 'ledgers.save.form.name.validation.maximumlength')
   		.required()
   		.default(() => ""),
   accountType: Yup.string()
		.oneOf([""].concat(AccountTypes), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
   remarks: Yup.string()
   		.max(255, 'ledgers.save.form.remarks.validation.maximumlength')
   		.default(""),
   active: Yup.boolean()
   		.default(() => true)
}


const ExportHeaders =[
	"ledgerNo", "name", "accountType", "remarks", "active", "whenCreated", "whenModified"
]

const LedgerConfig = { AccountTypes, ActiveOptions, FormValidationRules, 
					ExportHeaders};

export default LedgerConfig;