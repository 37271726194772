import React,  { Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";
import UserPasswordFormControls from "../components/UserPasswordFormControls";
import DataTable from "../components/DataTable";

import UserConfig from "../config/UserConfig";

const GeneralSection = props => {
	
	const { t } = useTranslation();
	
	return(
		<Fragment>
			<Row>
				<FormGroup as={Col} controlId="formGridFirstName">
					<FormLabel><Trans i18nKey="users.save.form.firstName.label">First Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="firstName" isInvalid={!(props.errors.firstName == null)} value={props.values.firstName} onChange={props.onChange} placeholder={t('users.save.form.firstName.placeholder')} />
				    <FormControlErrors errors={props.errors.firstName} />
				</FormGroup>
				<FormGroup as={Col} controlId="formGridLastName">
				    <FormLabel><Trans i18nKey="users.save.form.lastName.label">Last Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="lastName" isInvalid={!(props.errors.lastName == null)} value={props.values.lastName} onChange={props.onChange} placeholder={t('users.save.form.lastName.placeholder')} />
				    <FormControlErrors errors={props.errors.lastName} />
				</FormGroup>
				<FormGroup as={Col} controlId="formGridDisplayName">
					<FormLabel><Trans i18nKey="users.save.form.displayName.label">Display Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="displayName" isInvalid={!(props.errors.displayName == null)} value={props.values.displayName} onChange={props.onChange} placeholder={t('users.save.form.displayName.placeholder')} />
				    <FormControlErrors errors={props.errors.lastName} />
				</FormGroup>

			</Row>
			<Row>     		
				<FormGroup as={Col} md={8} controlId="formGrid-XPTO-1">
				    <FormLabel><Trans i18nKey="users.save.form.email.label">Email</Trans> *</FormLabel>
				    <FormControl className="text-lowercase" type={'text'} name="email" isInvalid={!(props.errors.email == null)} value={props.values.email} onChange={props.onChange} placeholder={t('users.save.form.email.placeholder')} />
				    <FormControlErrors errors={props.errors.email} />
				</FormGroup>

				<FormGroup as={Col} controlId="formGridMobilePhone">
			    	<FormLabel ><Trans i18nKey="users.save.form.mobilePhone.label">Mobile Phone</Trans> *</FormLabel>
					<PhoneInput
  						placeholder={t('users.save.form.mobilePhone.placeholder')}
  						value={props.values.mobilePhone}
  						onChange={(value) => {props.setFieldValue("mobilePhone", value);}}
  						isInvalid={!(props.errors.mobilePhone == null)}
  						defaultCountry="BR"
  						international
  						countryCallingCodeEditable={false}
  						name="mobilePhone"
  						inputComponent={FormControl}
  					/>
			    	{!(props.errors.mobilePhone == null) 
		          		? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.mobilePhone} /></div>
						: null
					}	
			    </FormGroup>
			</Row>

			{(props.action === 'create')
    			? <UserPasswordFormControls values={props.values} errors={props.errors} handleChange={props.onChange} /> 
    			: <Row>    					   
	    			<FormGroup as={Col} controlId="formGridActive">
	    				<FormLabel ><Trans i18nKey="users.save.form.active.label">Active</Trans></FormLabel>
	    				<Switch 
	    					name="active"
	    					value={props.values.active}
	    					checked={JSON.parse(props.values.active)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
    		}
		
		</Fragment>
		
	) 
}

const AccessControlSection = props => {

	const { t } = useTranslation();
	
	return(
		<Fragment>
			<Row>
				<FormGroup as={Col} controlId="formGridRoles">
					<FormLabel><Trans i18nKey="users.save.form.roles.label">Roles</Trans> *</FormLabel>
    	       		<fieldset className={!(props.errors.roles == null) ? "is-invalid" : ""}>
    	       	 		<div className={!(props.errors.roles == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
    	       	 			{ UserConfig.Roles.map(item => 
								<div className="form-check mb-1" key={item}>
									<FormCheck 
        								className="text-uppercase"
        								type="checkbox"
        								name="roles"
        								id={item}
        								value={item}
        								label={t("users.save.form.roles.options."+item)}
        								onChange={props.onChange}
        								checked={props.values.roles.includes(item)}
      								/>
                				</div>
			    			)}
        				</div>
  					</fieldset>
				    <FormControlErrors errors={props.errors.roles} />	
				</FormGroup>
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridPermissions">
					<FormLabel><Trans i18nKey="users.save.form.permissions.label">Permissions</Trans></FormLabel>
    	       		<fieldset className={!(props.errors.permissions == null) ? "form-group is-invalid" : "form-group"}>
    	       	 		<div className={!(props.errors.permissions == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
    	       	 			{ UserConfig.Permissions.map(item => 
								<div className="form-check mb-1" key={item}>
									<FormCheck 
        								className="text-uppercase"
        								type="checkbox"
        								name="permissions"
        								id={item}
        								value={item}
        								label={t("users.save.form.permissions.options."+item)}
        								onChange={props.onChange}
        								checked={props.values.permissions.includes(item)}
      								/>
                				</div>
			    			)}
        				</div>
  					</fieldset>
				    <FormControlErrors errors={props.errors.permissions} />	
				</FormGroup>
			</Row>
		</Fragment>
	) 

}


const WorkgroupsSection = props => {

	let history = useNavigate();
	
	const goToWorkgroup = (workgroup) => {
		 history.push({ pathname: "/workgroups/update", item: workgroup});
    }
    
	return(
		<Fragment>
			<Row>
        		<Col>
        			<DataTable 
        				items={props.values.workgroups} 
        				i18nPrefix="users.save.workgroups-datatable." 
        				columns={["name", "headquarters", "type","region"]}
        				customDisplayColumns={[["type", "Enum"], ["region", "Enum"]]} 
        				actions={[goToWorkgroup]} 
        			/>
        		</Col>
        	</Row>
		</Fragment>
	) 

}


const UserFormFields = { GeneralSection, AccessControlSection, WorkgroupsSection };
		
export default UserFormFields;
