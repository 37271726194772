import * as Yup from "yup";

const Types = [
	"AUDIO",
	"IMAGE"
]

const SubTypes = {
	"AUDIO":	[
		"MEME"
	],
	"IMAGE":	[
		"MOCKUP"
	]
}

const FilterFormValidationRules = {
	   type: Yup.string()
			.oneOf([""].concat(Types), 'form.validation.string.enum')
			.default(() => ""),
	   subType: Yup.string()
			.when('type', (type, schema) => {
			if (Object.keys(SubTypes).includes(type)) {
				return Yup.string()
					.oneOf(SubTypes[type], 'form.validation.string.enum');		
	        }
			return schema;
        })
			.default(() => ""),
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => "file"),
};

const MediaFileConfig = { Types, SubTypes, FilterFormValidationRules };

export default MediaFileConfig;