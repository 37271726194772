import React from 'react';
import { Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const SendAccountStatementModal = props => {
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-send-account-statement-confirmation-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-send-account-statement-confirmation-modal">
					<Trans i18nKey={"account-statements.send-account-statement-confirmation-modal.title"}>Send Account Statement Title</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="pt-0 pb-0">
  				<Container className="p-2">
  					<Row className="align-items-center">
  					<Col md={1} sm={2} xs={2} className="text-center p-1">
  						<h1 className={"text-"+props.variant}><FontAwesomeIcon icon={faQuestionCircle} /></h1>
					</Col>
		  			<Col className="ml-3 text-justified align-middle"><Trans i18nKey={"account-statements.send-account-statement-confirmation-modal.body"} values={{ item: props.item}}>Send Account Statement Body</Trans></Col>
				</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant="success" type="submit" onClick={() => { props.onSend(props.item, 'WHATSAPP_WEB') ; } }> 
  					<Trans i18nKey={"account-statements.send-account-statement-confirmation-modal.confirm-whatsapp-web"}>Whatsapp Web</Trans>
          		</Button>
          		<Button variant="warning" type="submit" onClick={() => { props.onSend(props.item, 'WHATSAPP_API') ; } }> 
          			<Trans i18nKey={"account-statements.send-account-statement-confirmation-modal.confirm-whatsapp-api"}>Whatsapp API</Trans>
          		</Button>
          		<Button variant="secondary" onClick={props.onHide}>
          			<Trans i18nKey={"account-statements.send-account-statement-confirmation-modal.cancel"}>Cancelar</Trans>
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default SendAccountStatementModal;
