import React,  { Fragment, useState, useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
//import FormCheck from 'react-bootstrap/FormCheck';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

//import LedgerInputSearch from "../components/LedgerInputSearch";

import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

//import LedgerTransactionConfig from "../config/LedgerTransactionConfig";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { parseInputDate, currencySymbol } from "../helpers/commons";

import { AuthError, ValidationError } from "../helpers/custom-errors";


import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";


const LedgerTransactionFormFields = props => {

	const [isSearchingTag, setIsSearchingTag] = useState(false);
	const [ledgers, setLedgers] = useState([]);	
	const [options, setOptions] = useState([]);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	const config = useConfig();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Ledgers
		API.findLedgers()
			.then(response => {
					if (isMounted) setLedgers(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Ledgers: ", error.message);
		 		props.onError(error);
			})
				
		 return () => { isMounted = false };
		  
	}, []);
	

	const handleTagSearch = (query) => {
	    
	    findTags({filter: query})
	      .then(({ list }) => {
				
			  const options = list.map((e,index)=> {
					return ({
	    		  		id: e.id,
						name: e.name
	    	  		})
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Tags Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find tags Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 });
	  }
	  
	  const findTags = (values) => {
		
		setIsSearchingTag(true);
		
		return new Promise((resolve, reject) => {
			API.findCustomTags(values)
			.then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 	setIsSearchingTag(false);
		 	});
		});
		
	}

	return(
		<Fragment>	
			
			<Row>
				
				<FormGroup as={Col} md={4} controlId="formGridEventDate">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventDate.label"}>Event Date</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("eventDate", "");
      						}
      					}}
      					date={props.values.eventDate}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors block={true} errors={props.errors.eventDate} />
				</FormGroup>
				
				<FormGroup as={Col} md={4} controlId="formGridLedger">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.ledgerNo.label"}>Ledger</Trans> *</FormLabel>
					<FormControl as="select" name="ledgerNo" disabled={props.readOnlyFields["ledgerNo"]} isInvalid={!(props.errors.ledgerNo == null)} value={props.values.ledgerNo} onChange={props.onChange} >
						<option value="">{t(props.i18nPrefix+"form.ledgerNo.blank-option")}</option>
		    			{ ledgers.map(item =>
		    				<option key={item.ledgerNo} value={item.ledgerNo+""}>{(!item.active) ? t(props.i18nPrefix+"form.ledgerNo.inactive-option", {option: item.name}) : item.name}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.ledgerNo} />
				 </FormGroup>
				
				<FormGroup as={Col} md={4} controlId="formGridAmount">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.amount.label"}>Amount</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="amount" readOnly={props.readOnlyFields["amount"]} isInvalid={!(props.errors.amount == null)} value={props.values.amount} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.amount} />
					</InputGroup>
				</FormGroup>
				
			</Row>
			

			
			<Row>
				
				<FormGroup as={Col} md={4} controlId="formGridLedgerDr">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.ledgerDrNo.label"}>Ledger Dr</Trans> *</FormLabel>
					<FormControl as="select" name="ledgerDrNo" disabled={props.readOnlyFields["ledgerDrNo"]} isInvalid={!(props.errors.ledgerDrNo == null)} value={props.values.ledgerDrNo} onChange={props.onChange} >
						<option value="">{t(props.i18nPrefix+"form.ledgerDrNo.blank-option")}</option>
		    			{ ledgers.map(item =>
		    				<option key={item.ledgerNo} value={item.ledgerNo+""}>{(!item.active) ? t(props.i18nPrefix+"form.ledgerDrNo.inactive-option", {option: item.name}) : item.name} </option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.ledgerDrNo} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridTags">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.tags.label"}>Tags</Trans> <FormControlHelper text={props.i18nPrefix+"form.tags.helper"}/></FormLabel>
					<AsyncTypeahead
      					multiple
      					id="tags"
						isInvalid={!(props.errors.tags == null)}
						isLoading={isSearchingTag}
						labelKey="name"
						onSearch={handleTagSearch}
      					onChange={(selected) => {
        					props.setFieldValue("tags", selected);
        					// Keep the menu open when making multiple selections.
        					typeaheadRef.current.toggleMenu();
      					}}
      					options={options}
						placeholder={t(props.i18nPrefix+"form.tags.placeholder")}
						emptyLabel={t(props.i18nPrefix+"form.tags.empty-label")}
      					ref={typeaheadRef}
						renderMenuItemChildren={(option, props) => (
							<Highlighter search={props.text}>
						      {option.name}
						    </Highlighter>
						)}
						selected={props.values.tags}

    			/>
				<FormControlErrors block={true} errors={props.errors.tags} />
			</FormGroup>
			
			</Row>
		
		</Fragment>
		
	) 
}


export default LedgerTransactionFormFields;
