import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlHelper from "../components/FormControlHelper";
import FormControlErrors from "../components/FormControlErrors";
import DismissableFeedback from "../components/DismissableFeedback";
import MessageConfig from "../config/MessageConfig";

import { ValidationError } from "../helpers/custom-errors";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";


setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 
const validationSchema = Yup.object().shape(MessageConfig.FormValidationRules);

const SendMessageModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	const config = useConfig()
	
	let initialValues = validationSchema.default();
	
	initialValues.accountNo = props.accountNo
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-send-message"
		    centered
		    animation={false}
		    >
				<Row className="text-center m-3">
					<Col>
						<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						_setError(null);
					
							API.sendMessage(values, config.csrfToken)
    							.then((response) => {
    								actions.setSubmitting(false);
									props.onMessageSent(response.success);
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Send Message Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Sending Message: ", error.message);
    								}
    								
    								_setError(error.message);
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, setFieldError, validateField, touched}) => (	
		      	<Form className="form-send-message" noValidate>

					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />
						
					<Row>
						<FormGroup as={Col} controlId="formGridContent">
							<FormLabel><Trans i18nKey="components.send-message-modal.form.content.label">Message</Trans> * <FormControlHelper text={"components.send-message-modal.form.content.helper"}/></FormLabel>
							<FormControl as="textarea" rows={3} name="content" isInvalid={!(errors.content == null)} value={values.content} onChange={handleChange} placeholder={t('components.send-message-modal.form.content.placeholder')} />
							<FormControlErrors errors={errors.content} />
						</FormGroup>
					</Row>
				    	
			    		
	    			<Row >    					   
    					<Col>
    						<Button variant="success" className="float-right" type="submit" disabled={isSubmitting}> 
    							{isSubmitting ? <Trans i18nKey="components.send-message-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheck} /> <Trans i18nKey={"components.send-message-modal.form.submit"}>Submit</Trans></span>} 
    						</Button>
    							
    						{/*<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="components.send-message-modal.cancel">Cancel</Trans></Button> */}

    					</Col>
    			    </Row>   
	    			    
		      	</Form>	
		      )}
		      </Formik>
					</Col>
					
				</Row>
		   </Modal>
	);
}

export default SendMessageModal;
