import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
//import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import DataPagination from "../components/DataPagination";

import API from "../services/backend-api";

import { isArrayWithLength } from "../helpers/commons";

import { useConfig } from "../services/use-config";

import AccountTransactionConfig from "../config/AccountTransactionConfig";


const AccountTransactionsModal = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [accountTransactions, setAccountTransactions] = useState(null);
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.accountNo) {
			
			let filter = {};
			filter.pageSize = 10;
			filter.sortBy = "eventDate";
			filter.order = "desc";
			filter.accountNo = props.item.accountNo;
			
			findAccountTransactions(filter)
				.catch(error => { 
					log.error("Error Loading Initial Account Transactions List: ", error.message);
					props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});

		}
		
		 
		 return () => { isMounted = false };
		  
	}, []);


  
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  findAccountTransactions(values)
		.catch(error => { 
			log.error("Error Sorting Account Transactions List: ", error.message);
	 		props.onError(error);
		})
		
  }
  
  const updatePage = (values) => {
	  findAccountTransactions(values)
		.catch(error => { 
			log.error("Error Paginating Transactions List: ", error.message);
	 		context.onError(error);
		})
		
  }
    
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.accountNo = currentPage.accountNo;   
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List Account Transactions with no new values
	  findAccountTransactions({})
		.catch(error => { 
	 		log.error("Error Refreshing Account Transactions List: ", error.message);
	 		props.onError(error);
		})	
  }  
 
  const displayCreditOrDebit = (item) => { 
		//return (AccountTransactionConfig.TransactionTypeOperationMap["DEBIT"].includes(item.xactTypeCodeExt)) ? "debit-row" : "credit-row";
		
		let found = AccountTransactionConfig.DEATransactionTreatments.find( i => i.key === item.xactTypeCodeExt);
		
		return (found && found.isCredit) ? "credit-row" : "debit-row";
  	}

  const findAccountTransactions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		props.onError(null);
		
		API.pageAccountTransactions(mergeQueryParams(accountTransactions, values)).then(response => {
			setAccountTransactions(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const handleDisplayTags = (items) => {
	  
	  return (isArrayWithLength(items) ? items.map((item,index) => <Badge key={index} pill variant="info">{item}</Badge>) : "-")
  	}
	

	if  (!props.item) {
		return null
	} else if (props.item.productId) {
		if (!isLoading && !accountTransactions) {
			return null;
		} 
	}	
		
	if (isLoading) 
		return <Loader />

	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-account-transactions-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-account-transactions-modal">
  					<Trans i18nKey={"account-statements.list-account-transactions-modal.title"} values={{account: props.item}}>Account Transactons</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body className="mb-3">
 			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Fragment>	
        		<Row>
        			<Col>
        				<DataTable 
        					items={accountTransactions.list} 
        					i18nPrefix="account-statements.list-account-transactions-modal.datatable." 
        					columns={["eventDate", "xactTypeCodeExt", "amount", "tags", "remarks", "whenModified"]} 
							sortableColumns={["eventDate", "amount", "whenModified"]}
							customDisplayColumns={[["eventDate", "LocalDate"],["xactTypeCodeExt", "Enum"],["tags", handleDisplayTags], ["amount", "Currency"],["whenModified", "ZonedDateTime"]]}
        					sortBy={accountTransactions.sortBy}
        					order={accountTransactions.order}
        					sortDataTable={sortDataTable}
        					handleTextOverflow={true}
							customRowStyle={displayCreditOrDebit}
        					noItemsDisclaimer={true}
        				/>
        			</Col>
        		</Row>
        		<Row>
        		<Col>
        			<DataPagination className="justify-content-end"
        				totalCount={accountTransactions.totalCount}
        				pageIndex={accountTransactions.pageIndex}
        				pageSize={accountTransactions.pageSize}
						pageSizeOptions={[10,20]}
        				totalPageCount={accountTransactions.totalPageCount}
        				hasPrev={accountTransactions.hasPrev}
        				hasNext={accountTransactions.hasNext}
        				updatePage={updatePage} />
        		</Col>
        	</Row>
				</Fragment>
			)}
			</Modal.Body>
			<Modal.Footer>			 
          		<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="products.list-supplier-orders-modal.close">Close</Trans></Button>	
          	</Modal.Footer>
		</Modal>
	);

}

export default AccountTransactionsModal;
