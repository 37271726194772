import React,  { Fragment, useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import FormControlErrors from "../components/FormControlErrors";

import SupplierInputSearch from "../components/SupplierInputSearch";

import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt';

import SaveSupplierOrderLineItemModal from "../components/SaveSupplierOrderLineItemModal";

import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { useConfig } from "../services/use-config";

import { parseInputDate, isArrayWithLength, currencySymbol, formatCurrency } from "../helpers/commons";
import DataTable from './DataTable';


const SupplierOrderFormFields = props => {
	
	const [saveLineItemModalShow, setSaveLineItemModalShow] = useState(false);
	const [deleteLineItemModalShow, setDeleteLineItemModalShow] = useState(false);
	const [selectedLineItem, setSelectedLineItem] = useState(null);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
			
		 return () => { isMounted = false };
		  
	}, []);
	
	
	const newLineItem = () => {
	  setSelectedLineItem(null);
	  setSaveLineItemModalShow(true);
    }

 	const updateLineItem = (item) => {
	  setSelectedLineItem(item);
	  setSaveLineItemModalShow(true);
 	}
 	
 	const confirmLineItemRemoval = (item, index) => {
	  setSelectedLineItem(item);
	  setDeleteLineItemModalShow(true);
  }

    const handleSupplierChange = (item) => {
		
        if (!item) {
			props.setFieldValue("supplierNo", "");
        	return;
        } 

		props.setFieldValue("supplierNo", item.supplierNo);

    }
    
    const handleLineItemSaved = (action, item) => {
	  setSaveLineItemModalShow(false);
	  
	  let lineItems = props.values.items;
	  
	  if (action === "create") {
		  lineItems.push(item);
	  } else {
		  let foundIndex = lineItems.findIndex((i) => i.productId == item.productId);
		  
		  if (foundIndex > -1)
		  	lineItems.splice(foundIndex, 1, item);
	  }
		  
	  props.setFieldValue("items", lineItems);
	  props.setFieldError("items", null);
	  
	  calculateTotalAmountValue(lineItems, props.values.freightCharge, props.values.otherCharges)
  }
  
  const calculateTotalAmountValue = (items, freightCharge, otherCharges) => { 
	  
	  let totalAmount = 0;
	  
	  items.forEach((item) => {
		  if (item.purchaseType === "PURCHASE_RETURN") {
			  totalAmount -= (parseFloat(item.totalAmount) ? parseFloat(item.totalAmount) : 0 );
		  } else {
		  	  totalAmount += (parseFloat(item.totalAmount) ? parseFloat(item.totalAmount) : 0 );
		  }
	});
	  	  
	  totalAmount+= parseFloat(freightCharge) ? parseFloat(freightCharge) : 0;

	  totalAmount+= parseFloat(otherCharges) ? parseFloat(otherCharges) : 0;
	  
	  props.setFieldValue("totalAmount", totalAmount.toFixed(2));
	  
  }
  
  const handleFreightChargeChange = (e) => {
	
		props.onChange(e);
		//props.setFieldValue("freightCharge", (e.target.value) ? e.target.value : "");
		
		calculateTotalAmountValue(props.values.items, e.target.value, props.values.otherCharges); 
	}
	
	const handleOtherChargesChange = (e) => {
	
		props.onChange(e);
		
		calculateTotalAmountValue(props.values.items, props.values.freightCharge, e.target.value); 
	}
	
  
  
  const deleteLineItem = (item) => {
		
		setDeleteLineItemModalShow(false);
		
		let lineItems = props.values.items;
		
		let foundIndex = lineItems.findIndex((i) => i.productId == item.productId);
		  
		lineItems.splice(foundIndex, 1);
		
		props.setFieldValue("items", lineItems);
		props.setFieldError("items", null);
		
		calculateTotalAmountValue(lineItems)
		
  }
  
    const displayPurchaseOrReturn = (item) => { 
		return (item.purchaseType === "PURCHASE_RETURN") ? "debit-row" : "credit-row";
  	}


	return(
		<Fragment>	
		
			{(saveLineItemModalShow) && <SaveSupplierOrderLineItemModal
				show={saveLineItemModalShow}
	        	onHide={() => setSaveLineItemModalShow(false)}
				size="lg"
				item={selectedLineItem}
				lineItems={props.values.items}
				supplierNo={props.values.supplierNo}
				onItemSaved={handleLineItemSaved}
				readOnly={props.registered}
		      />
			}
			{(selectedLineItem) && <ConfirmationDialogModal
				item={selectedLineItem}
				show={deleteLineItemModalShow}
        		onHide={() => setDeleteLineItemModalShow(false)}
				size="lg"
				title={t("supplier-orders.delete-item-confirmation-modal.title")}
				bodyText={t("supplier-orders.delete-item-confirmation-modal.body", {item: selectedLineItem})}
				confirmText={t("supplier-orders.delete-item-confirmation-modal.confirm")}
				cancelText={t("supplier-orders.delete-item-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteLineItem}
			/>}
			<Row>
				<FormGroup as={Col} md={3} controlId="formGridEventDate">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.eventDate.label"}>Event Date</Trans> *</FormLabel>
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
								if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("eventDate", "");
      							}
      						}}
      						date={props.values.eventDate}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
      						readOnly={props.registered}
    					/>
					<FormControlErrors block={true} errors={props.errors.eventDate} />
				</FormGroup>
				
				<FormGroup as={Col} md={6} controlId="formGridSupplier">
				   <FormLabel><Trans i18nKey={props.i18nPrefix+"form.supplierNo.label"}>Select Supplier</Trans> *</FormLabel>
					{props.readOnlyFields["supplierNo"] ? 
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl type={'text'} readOnly name="supplierDisplayName" value={props.values.customerDisplayName} />
						</InputGroup>
						:	
						<SupplierInputSearch 
				    		i18nPrefix={props.i18nPrefix+"form."}
				    		onError={props.onError}
				    		isInvalid={!(props.errors.supplierNo == null)}
				    		onChange={handleSupplierChange}
							supplierNo={props.values.supplierNo}
							disabled={props.registered}
				    	/>
					}
					<FormControlErrors block={true} errors={props.errors.supplierNo} />
				</FormGroup>
				
				<FormGroup as={Col} md={3} controlId="formGridTotalAmount">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.totalAmount.label"}>Total Amount</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="totalAmount" disabled={true} isInvalid={!(props.errors.totalAmount == null)} value={props.values.totalAmount} onChange={props.onChange} />
						<FormControlErrors errors={props.errors.totalAmount} />
					</InputGroup>
				</FormGroup>
				
			</Row>
			
			<Row>
				
				 <FormGroup as={Col} md={3} controlId="formGridFreightCharge">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.freightCharge.label"}>Freight Charge</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="freightCharge" disabled={props.registered} isInvalid={!(props.errors.freightCharge == null)} value={props.values.freightCharge} onChange={handleFreightChargeChange} />
						<FormControlErrors errors={props.errors.freightCharge} />
					</InputGroup>
				</FormGroup>
				
				<FormGroup as={Col} md={3} controlId="formGridOtherCharges">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.otherCharges.label"}>Other Charges</Trans> *</FormLabel>
				    <InputGroup>
						<InputGroup.Prepend>
     						<InputGroup.Text>{currencySymbol(config.preferredLanguage, config.numberFormat.currency)}</InputGroup.Text>
    					 </InputGroup.Prepend>
						<FormControl type={'text'} name="otherCharges" disabled={props.registered} isInvalid={!(props.errors.otherCharges == null)} value={props.values.otherCharges} onChange={handleOtherChargesChange} />
						<FormControlErrors errors={props.errors.otherCharges} />
					</InputGroup>
				</FormGroup>

				<FormGroup as={Col} controlId="formGridRemarks">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.remarks.label"}>Remarks</Trans></FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="remarks" disabled={props.registered} isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.remarks.placeholder")} />
				    <FormControlErrors errors={props.errors.remarks} />
				</FormGroup>
			</Row>

			<Row>
				<FormGroup as={Col} controlId="formGridItems" className="mb-1">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.items.label"}>Items</Trans></FormLabel>
					<fieldset>
						<div className={(props.errors.items == null) ? "border rounded p-4" : "border border-danger rounded p-4"}>
							<Row className="align-items-center">
							 	<Col>
							 		<DataTable
        								items={props.values.items} 
        								i18nPrefix={props.i18nPrefix+"form.items.datatable."}
        								columns={["productId", "productName", "purchaseType", "quantity", "unitPrice", "discountAmount","totalAmount"]} 
        								customDisplayColumns={[["unitPrice", "Currency"],["purchaseType", "Enum"],["discountAmount", "Currency"],["totalAmount", "Currency"]]}
        								actions={props.registered ? [updateLineItem] : [updateLineItem, confirmLineItemRemoval]}
        								defaultAction={updateLineItem}
        								customRowStyle={displayPurchaseOrReturn}	
        								//noItemsDisclaimer={true}
        							/>
        						</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button variant="primary" disabled={props.registered} onClick={newLineItem}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"form.items.add-button"}>Add Item</Trans></Button>
							 	</Col>
							</Row>
					</div>
				</fieldset>
				<FormControlErrors block={true} errors={props.errors.items} />
				</FormGroup>
		   </Row>
		
		</Fragment>
		
	) 
}


export default SupplierOrderFormFields;
