import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

//import ImportConfig from "../config/ImportConfig";
import LedgerTransactionConfig from "../config/LedgerTransactionConfig";
import LedgerTransactionsImportFormFields from "../components/LedgerTransactionsImportFormFields";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { ValidationError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";
import CustomContainer from './CustomContainer';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape(LedgerTransactionConfig.ImportFormValidationRules);
	

const LedgerTransactionsImport = props => {

	const config = useConfig();
	const { t } = useTranslation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
	
	const importLedgerTransactions = (values) =>
		new Promise((resolve, reject) => {
			API.importLedgerTransactions(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
		});
	
	
	let initialValues = validationSchema.default();
	
	return (
		<Formik
    		initialValues={initialValues}
    		validationSchema={validationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error/Success
    			context.onError(null);
    			context.onSuccess(null);
				
				importLedgerTransactions(values)
    				.then((response) => {
    					navigate("/ledger-transactions", { state: { success: t("ledger-transactions.import.success")}});
    				})
    				.catch(error => { 
    								
    					actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Import Ledger Transactions Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					if (key === "data") {
											try {
        										actions.setFieldError(key, JSON.parse(message));
    										} catch (e) {
        										actions.setFieldError(key, message);
    										}
											
										} else {
											actions.setFieldError(key, message);
										}
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Importing Ledger Transactions: ", error.message);
    					}
    								
    					context.onError(error);
    								
    				})

				}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, validateField}) => (	
		      	
		      	<CustomContainer>
		    		<Row className="mb-3">
		            	<Col>
		            		<h3>
		            			<Trans i18nKey={"ledger-transactions.import.header"} >Import LedgerTransactions</Trans> 
		            		</h3>
		            	</Col>
		      
		            </Row>
		            <Row className="mb-3">
		        		<Col>
		        			<Form className="form-ledger-transactions-import" noValidate>

		      				<LedgerTransactionsImportFormFields 
								i18nPrefix="ledger-transactions.import."
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={context.onError}
								onSuccess={context.onSuccess}
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								validateField={validateField}
								isSubmitting={isSubmitting}
							/>
						
					   		<Row className="mt-3">    					   
    							<Col>  
    								<Button variant="success" type="submit" disabled={isSubmitting || !isArrayWithLength(values.data)} > 
    									{isSubmitting ? <Trans i18nKey="ledger-transactions.import.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faFileUpload} /> <Trans i18nKey={"ledger-transactions.import.form.submit"}>Submit</Trans></span>} 
    								</Button>

    					   		</Col>
    			       		</Row>   
    			       
    			    </Form>	
		      	 </Col>
			     </Row>
			  </CustomContainer>
		      )}
		 </Formik>
	);
}

export default LedgerTransactionsImport;
