import React, { useState, useEffect, useRef   } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';

import FormControlErrors from "../components/FormControlErrors";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import * as Yup from "yup";

import LedgerStatementConfig from "../config/LedgerStatementConfig";

import { parseInputDate } from "../helpers/commons";

import { useConfig } from "../services/use-config";

import API from "../services/backend-api";

import { Typeahead} from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";


const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(LedgerStatementConfig.FilterFormValidationRules);

const LedgerStatementFilterFormFields = props => {
	const [customTags, setCustomTags] = useState([]);
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();
		
	useEffect(() => {
		let isMounted = true; 	
			
		//Fetch Custom Tags
		API.findCustomTags({}).then(response => {
				if (isMounted) setCustomTags(response.list);
			}).catch(error => {			
				log.error("Error Loading Custom Tags: ", error.message);
		 		props.onError(error);
			});	
		
		return () => { isMounted = false };
		  
	}, []);
	

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(LedgerStatementConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 

	
	return (
		<fieldset>
			{/*<div style={displayFiltersButton}>  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-right"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>*/}
			<div className={"border rounded p-4"}>
				 
				 <FormGroup as={Row} controlId="formGridTags">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"tags.label"}>Tags</Trans>
    				</FormLabel>
					<Col sm="7">
						<Typeahead
      						multiple
      						id="tags"
							isInvalid={!(props.errors.tags == null)}
							labelKey={(option) => option.name }
      						onChange={(selected) => {
        						props.setFieldValue("tags", selected);
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={customTags}
      						placeholder={t(props.i18nPrefix+"tags.placeholder")}
							emptyLabel={t(props.i18nPrefix+"tags.empty-label")}
      						ref={typeaheadRef}
      						selected={props.values.tags}
    					/>
						<FormControlErrors errors={props.errors.tags} />
					</Col>
				</FormGroup>
				
				<FormGroup as={Row} controlId="formGridDateRange" >
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.label"}>Date Range</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.start == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("start", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("start", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("start", "");
      							}
      						}}
      						date={props.values.start}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.start} />
					</Col>
					<FormLabel column sm="1" className="text-center">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.until"}>until</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.end == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("end", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("end", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("end", "");
      							}
      						}}
      						date={props.values.end}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.end} />
					</Col>
					<Col sm="3">
						<Button type="submit" disabled={props.isSubmitting}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
						
			</div>
		</fieldset>
	)

}

export default LedgerStatementFilterFormFields;
