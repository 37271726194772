import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18n from './i18n';
import App from './pages/App';
//import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

//Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

//Importing the Fontawesome CSS
import 'font-awesome/css/font-awesome.css';

//Strict Mode is usefull during development and it does not apply in production
ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
