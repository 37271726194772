import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import Loader from "../components/Loader";

import PagedList from "../components/PagedList";
import ActivityDetailModal from "../components/ActivityDetailModal";
import ActivityFilterForm from "../components/ActivityFilterForm";

import API from "../services/backend-api";

const Activities = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedActivities, setPagedActivities] = useState(null);
	const [activityDetailModalShow, setActivityDetailModalShow] = useState(false);
	const [selectedActivity, setSelectedActivity] = useState(null);
	
	const location = useLocation();
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted && location.success)
			context.onSuccess(location.success)
		
		let filter = {};
		filter.pageSize = 10;
		filter.sortBy = "eventTime";
		filter.order = "desc";
		filter.filteredColumn = "userId";
		filter.subject = "";
		filter.action = "";
		
		pageActivities(filter)
			.catch(error => { 
		 		log.error("Error Loading Initial Activities List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  
  const detailActivity = (activity) => {
	  setSelectedActivity(activity);
	  setActivityDetailModalShow(true);
  }
  
  const handleActivityDetailModalHide = () => {
	  setSelectedActivity(null); 
	  setActivityDetailModalShow(false);
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageActivities(values)
		.catch(error => { 
			log.error("Error Sorting Activities List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.subject = currentPage.subject; 
		  queryParams.action = currentPage.action; 
		  
		  queryParams.start = currentPage.start; 
		  queryParams.end = currentPage.end; 
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
const updatePage = (values) => {
	  pageActivities(values)
		.catch(error => { 
			log.error("Error Paginating Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const refreshPage = () => {
	  
	  let currentParams = {};
	  if (pagedActivities) {
		  currentParams.pageIndex = pagedActivities.pageIndex;
		  currentParams.pageSize = pagedActivities.pageSize;
		  currentParams.sortBy = pagedActivities.sortBy;
		  currentParams.order = pagedActivities.order;
		  currentParams.filteredColumn = pagedActivities.filteredColumn;
		  currentParams.filter = pagedActivities.filter;  
		    
	  }
		  
	  pageActivities(currentParams)
		.catch(error => { 
	 		log.error("Error Refreshing Activities List: ", error.message);
	 		context.onError(error);
		})	
  }  
 

  const pageActivities = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageActivities(mergeQueryParams(pagedActivities, values)).then(response => {
			setPagedActivities(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedActivities) 
		return null;
		
	return (
		<Fragment>
			{(selectedActivity) && <ActivityDetailModal
				show={activityDetailModalShow}
				onHide={handleActivityDetailModalHide}
				size="lg"
				activity={selectedActivity}
				onError={context.onError}
				displaySubjectItemButton={true}
			/>}
			<PagedList
				i18nPrefix="activities.list."
				page={pagedActivities}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={ActivityFilterForm}
				filterParameters={{ filter: pagedActivities.filter, filteredColumn: pagedActivities.filteredColumn }}
				onFilter={pageActivities} 
				onError={context.onError}
				onSort={sortDataTable}
				onPage={updatePage}
				dataTableColumns={["eventTime", "user", "subject", "action"]}
				dataTableSortableColumns={["eventTime"]}
				dataTableCustomDisplayColumns={[["eventTime", "ZonedDateTime"]]}
				dataTableActions={[detailActivity]}		
				dataTableDefaultAction={detailActivity}
			/>
		</Fragment>
	);
}

export default Activities;
