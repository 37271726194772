import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faEllipsisV, faTimesCircle, faChevronCircleDown, faChevronCircleUp, faImage, faFile, faFileAudio, faFileVideo, faReply, faFilePdf } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Loader from "../components/Loader";

import DismissableFeedback from "../components/DismissableFeedback";
//import DataTable from "../components/DataTable";

import Moment from 'react-moment';

import JSONPretty from 'react-json-pretty';

import API from "../services/backend-api";

import { isArrayWithLength } from "../helpers/commons";
import AccountSubjectActivities from './AccountSubjectActivities';
import MediaFileDetailModal from "../components/MediaFileDetailModal";

const MessageDetailModal = props => {

	const [displayDetail, setDisplayDetail] = useState(false);
	//const [displayMessageActivities, setDisplayMessageActivities] = useState(false);
	const [_error, _setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [messageContent, setMessageContent] = useState(null);
	//const [isLoadingMessageContent, setIsLoadingMessageContent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	//const [messageCallbackStatuses, setMessageCallbackStatuses] = useState(null);
	const [mediaFileDetailModalShow, setMediaFileDetailModalShow] = useState(false);
	const [selectedMediaFile, setSelectedMediaFile] = useState(null);
	
	const { t } = useTranslation();
//	let history = useNavigate();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.messageSid) {
			
		  getMessageData(props.item.messageSid)
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
		   });
			
		}
		
		 return () => { isMounted = false };
		  
	}, []);
	
	
  const lookupMessage = (id) =>
	new Promise((resolve, reject) => {
		API.lookupMessage(id)
			.then(response => {
				resolve(response);
			})
			.catch(error => { 
				reject(error);
			})
 });
	

 const loadMessageContent = (id) => 
   new Promise((resolve, reject) => {  
	  API.getMessageContent(id)
		.then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		})
 });
	
  const getMessageData = (id) => {
		
	return Promise.all([lookupMessage(id),loadMessageContent(id)])
		.then((responses) => {	
			setMessage(responses[0]);
			setMessageContent(responses[1]);
		})
		.catch(error => {			
			log.error("Error Loading Message Data: ", error.message);
			_setError(error);
		});
	}
	
	const detailMediaFile = (mediaType, messageSid, mediaSid) => {
	  
	  let media = {};
	  media.type = mediaType;
	  media.url = API.getMessageContentMediaUrl(messageSid, mediaSid);
	  
	  setSelectedMediaFile(media);
	  setMediaFileDetailModalShow(true);
	      
  }
  
    const handleMediaFileDetailModalHide = () => {
	  setSelectedMediaFile(null); 
	  setMediaFileDetailModalShow(false);
  }
  
      const renderMessageStatus = (item) => {
	  
	  let variant;
	  
	  switch (item) {
		  
		case "FAILED":
		case "UNDELIVERED":
			variant = "danger";
			break;
		case "PARTIALLY_DELIVERED":
			variant = "warning";
			break;
		case "READ":
			variant = "success";
			break;
		case "CANCELED":
			variant = "secondary";
			break;
		case "QUEUED": 
		case "SENDING": 
		case "SENT": 
		case "DELIVERED":	
		case "RECEIVING":
		case "RECEIVED":
		case "ACCEPTED":
		case "SCHEDULED":
		default:
			variant = "info";
			break;
	 }
	  
	  return <Badge pill variant={variant}><Trans i18nKey={"messages.message-detail-modal.datatable.enums.status."+item}/></Badge>
   }
	
	
	
	if (isLoading) {
		return <Loader />
	} else if (!message) {
		return null;
	}
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-message-detail"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >

		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-message-detail">
  					<Trans i18nKey="messages.message-detail-modal.title">Message Detail</Trans>
  				</Modal.Title>
  			</Modal.Header>

		    <Modal.Body className="mb-3">
		  		<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				{(selectedMediaFile) && <MediaFileDetailModal
					show={mediaFileDetailModalShow}
					onHide={handleMediaFileDetailModalHide}
					size="md"
					item={selectedMediaFile}
					onError={_setError}
				/>} 	
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.eventTime">DateTime</Trans>:
					</Col>
					<Col md={10}>
						<Moment local format="L LTS Z">{message.eventTime}</Moment>
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.account">Account</Trans>:
					</Col>
					<Col md={10}>
						{message.customerDisplayName + " [" + message.accountNo +"]"}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.subject">Subject</Trans>:
					</Col>
					<Col md={10}>
						<Trans i18nKey={"messages.message-detail-modal.datatable.enums.subject."+message.subject}/>
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.status">Status</Trans>:
					</Col>
					<Col md={10}>
						{renderMessageStatus(message.status)}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.sender">Sender</Trans>:
					</Col>
					<Col>
						{message.sender}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.recipient">Recipient</Trans>:
					</Col>
					<Col md={10}>
						{message.recipient}
					</Col>
				</Row>
				
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.direction">Direction</Trans>:
					</Col>
					<Col md={10}>
						<Trans i18nKey={"messages.message-detail-modal.datatable.enums.direction."+message.direction}/>
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.channel">Channel</Trans>:
					</Col>
					<Col md={10}>
						{message.channel} 
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.messageSid">Message Sid</Trans>:
					</Col>
					<Col md={10}> 
						{message.messageSid} 
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.provider">Provider</Trans>:
					</Col>
					<Col md={10}> 
						{message.provider}
						<Button size="sm" className="pb-0 pt-0" variant="link" >
							<FontAwesomeIcon icon={(displayDetail) ? faChevronCircleUp : faChevronCircleDown} color="green" onClick={() => { setDisplayDetail(!displayDetail);}}/>
						</Button>
    					{(displayDetail) && (<JSONPretty data={message.detail}/>)}
					</Col>
				</Row>
				
				<Row>
					<Col md={2}>
						<Trans i18nKey="messages.message-detail-modal.datatable.content">Content</Trans>:
					</Col>
					<Col md={10}> 
						{ (messageContent && isArrayWithLength(Object.keys(messageContent))) ?
							Object.keys(messageContent).map( key => {
								
								if (isArrayWithLength(messageContent[key])) {
									return messageContent[key].map((item, index) => {	
									 	if (key === "text")
									 	 	return (<p key={index}>{item}</p>);
									 	else if (key.split("/")[0] === "image")
									 		return (<p key={index}><FontAwesomeIcon size="2xl" icon={faImage} onClick={() => { detailMediaFile("IMAGE", message.messageSid, item);}}/></p>);
									 	else if (key.split("/")[0] === "audio")
									 		return (<p key={index}><FontAwesomeIcon size="2xl" icon={faFileAudio} onClick={() => { detailMediaFile("AUDIO", message.messageSid, item);}}/></p>);
									 	else if (key.split("/")[0] === "video")
									 		return (<p key={index}><FontAwesomeIcon size="2xl" icon={faFileVideo} onClick={() => { detailMediaFile("VIDEO", message.messageSid, item);}}/></p>);
									 	else if (key === "application/pdf")
									 		return (<p key={index}><FontAwesomeIcon size="2xl" icon={faFilePdf} onClick={() => { detailMediaFile("PDF", message.messageSid, item);}}/></p>);
									 	else 
									 		return (<p key={index}><FontAwesomeIcon size="2xl" icon={faFile} onClick={() => { detailMediaFile("OTHER", message.messageSid, item);}}/></p>);
									});
								} else {
									return null
								}
							})
							: null
						}
					</Col>
				</Row>
				
				
				<Row className="mt-3">
					<Col>
						<AccountSubjectActivities 
		 					subject="MESSAGE" 
		 					subjectId={message.messageSid} 
		 					onError={_setError}
		 				/>
					</Col>
				</Row>
				
          	</Modal.Body>
            
			<Modal.Footer>		      		
          			<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="messages.message-detail-modal.close">Close</Trans></Button>	
          	</Modal.Footer>

		   </Modal>
	);
}

export default MessageDetailModal;
