import React,  { Fragment, useState, useEffect } from 'react';
import { useNavigate, Navigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Loader from "../components/Loader";
import Filler from "../components/Filler";
import DataTable from "../components/DataTable";
import SaveProductSupplierModal from "../components/SaveProductSupplierModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import CustomContainer from './CustomContainer';


const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveProductSupplierModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      }
];

const ProductSuppliers = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [productSuppliers, setProductSuppliers] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	const config = useConfig();
	const { t } = useTranslation();
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
		
		if (location.state && location.state.item) {
			findProductSuppliers({"filteredColumn": "productId", "filter": location.state.item.productId})
				.catch(error => { 
					log.error("Error Loading Initial ProductSuppliers List: ", error.message);
					context.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});

		}
		
		 
		 return () => { isMounted = false };
		  
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }
  
  const confirmSupplierRemoval = (item, index) => {

	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }

  
  const addProductSupplier = () => {
	 let item = {};
	 item.productId = location.state.item.productId;
	 setSelectedDataTableItem(item);
	 showActionModal("action-0")
  }
    
  const updateSupplier = (item, index) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-"+index)
  }
  
  const sortDataTable = (values) => {
	  findProductSuppliers(values)
		.catch(error => { 
			log.error("Error Sorting ProductSuppliers List: ", error.message);
	 		context.onError(error);
		})
		
  }
    
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
 const refreshPage = () => {
		  
	  //List ProductSuppliers with no new values
	  findProductSuppliers({})
		.catch(error => { 
	 		log.error("Error Refreshing ProductSuppliers List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const findProductSuppliers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.findSupplySchemes(mergeQueryParams(productSuppliers, values)).then(response => {
			setProductSuppliers(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const deleteProductSupplier = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteSupplyScheme(item.productId, item.accountNo,config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Product Supplier: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

	
	if (!location.state || !location.state.item) {
		return (<Navigate to="/products" />)
	}
	
	if (isLoading) 
		return <Loader />
	
	if (!productSuppliers) 
		return null;
		
	return (
		<CustomContainer>
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="product-suppliers"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="product-suppliers"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("product-suppliers.delete-supplier-confirmation-modal.title")}
				bodyText={t("product-suppliers.delete-supplier-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("product-suppliers.delete-supplier-confirmation-modal.confirm")}
				cancelText={t("product-suppliers.delete-supplier-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteProductSupplier}
			/>}
		
		
		<Row className="mb-3">
			
        		<Col>
        			<h3><Trans i18nKey="product-suppliers.list.header" values={{product: location.state.item}} /></h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col sm={6} xl={7}>
    				<h4>{t("product-suppliers.list.header-count", {count: productSuppliers.list.length})}</h4>
    			</Col>
    			<Col sm={6} xl={5}>
				<div className="d-flex justify-content-end">
					<Button variant="outline-success" onClick={addProductSupplier}><FontAwesomeIcon icon={faUserPlus} /> <Trans i18nKey="product-suppliers.list.add-button" /></Button>
				</div>
			</Col>
    		</Row>
        	<Row>
        		<Col>
        			<DataTable 
        				items={productSuppliers.list} 
        				i18nPrefix="product-suppliers.list.datatable." 
        				columns={["supplierDisplayName", "unitPrice", "lastOrder"]} 
        				sortableColumns={["unitPrice"]}
        				sortBy={productSuppliers.sortBy}
        				order={productSuppliers.order}
        				sortDataTable={sortDataTable}
        				customDisplayColumns={[["unitPrice", "Currency"],["lastOrder", "LocalDate"]]}
        				actions={[updateSupplier, confirmSupplierRemoval]}
        			/>
        		</Col>
        	</Row>
		    <Row>    					   
				<Col>
					<Button variant="secondary" className="float-right" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="product-suppliers.list.back">Back</Trans></Button>
				</Col>
			</Row>  
		</CustomContainer>
	);
}

export default ProductSuppliers;
