import React,  { Fragment, useState, useEffect } from 'react';
import { useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import log from 'loglevel';

//import Container from 'react-bootstrap/Container'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
//import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
//import AccountingSummaryToolbar from "../components/AccountingSummaryToolbar";
import CustomContainer from './CustomContainer';
import LedgerStatementFilterForm from "../components/LedgerStatementFilterForm";

//import moment from "moment";

import { formatCurrency } from "../helpers/commons";


import API from "../services/backend-api";

import { useConfig } from "../services/use-config";




const AccountingSummaryReport = props => {
	const [isLoading, setIsLoading] = useState(true);
//	const [isRefreshing, setIsRefreshing] = useState(false);
	const [ledgerStatements, setLedgerStatements] = useState(null);
	//const [accountStatementsSummary, setAccountStatementsSummary] = useState(null);
	
	//const [eventDate, setEventDate] = useState(moment(new Date()));
	
    //const [startDate, setStartDate] = useState(null);
	//const [endDate, setEndDate] = useState(null);

	let context = useOutletContext();
	const config = useConfig();
	const { t } = useTranslation();
		
	useEffect(() => {
		let isMounted = true; 
		
		//let eventDate = moment(new Date());
		//findLedgerStatements({start: eventDate.startOf('month').format('YYYY-MM-DD'), end: eventDate.endOf('month').format('YYYY-MM-DD')})
		
		getAccountingSummary({}).finally(() => {
		 		if (isMounted) setIsLoading(false);
		});
		
		return () => { isMounted = false };
		  
	}, []);


  
 /* const handleDateRangeChange = (newStartDate, newEndDate) => {
		  
      //let currentEventDate = eventDate;

	  setIsRefreshing(true);
	  //setEventDate(newEventDate);
  
	  	//Get Ledger Statements
	  	findLedgerStatements({start: moment(newStartDate).format('YYYY-MM-DD'), end: moment(newEndDate).format('YYYY-MM-DD')})
			.catch(error => { 
	 			log.error("Error Refreshing Ledger Statements: ", error.message);
	 			context.onError(error);
			}).finally(() => {
		 		setIsRefreshing(false);
			});	
  } */
  
  const mergeQueryParams = (currentValues, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentValues) {
		  queryParams.sortBy = currentValues.sortBy;
		  queryParams.order = currentValues.order;
		  queryParams.filter = currentValues.filter;
		  queryParams.filteredColumn = currentValues.filteredColumn;
		
		  queryParams.start = currentValues.start;
		  queryParams.end = currentValues.end;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const handleDisplayTotalCredit = (item) => {
	  
	  let result, style, tooltip;
	  
	  if (!item.ltTotalCredit && !item.atTotalCredit) {
		  return "-";
	  }
	  
	  result = (!item.ltTotalCredit && !item.atTotalCredit) ? "-" : formatCurrency(item.ltTotalCredit + item.atTotalCredit, config.locale, config.numberFormat.currency);
	  
	  tooltip = t("accounting.summary.datatable.tooltips.totalCredit.details", {
		  	ltTotalCredit: formatCurrency(item.ltTotalCredit, config.locale, config.numberFormat.currency),
	  		atTotalCredit: formatCurrency(item.atTotalCredit, config.locale, config.numberFormat.currency)});
	  		
	  //style="success";
	  //style="danger";
	  style="info";  	  		  
			
	return (<OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
					<span>{result}</span>
			</OverlayTrigger>)
	  
	  
  }
  
  
  const handleDisplayTotalDebit = (item) => {
	  
	  let result, style, tooltip;
	  
	  if (!item.ltTotalDebit && !item.atTotalDebit) {
		  return "-";
	  }
	  
	  result = (!item.ltTotalDebit && !item.atTotalDebit) ? "-" : formatCurrency(item.ltTotalDebit + item.atTotalDebit, config.locale, config.numberFormat.currency);
	  
	  tooltip = t("accounting.summary.datatable.tooltips.totalDebit.details", {
		  	ltTotalDebit: formatCurrency(item.ltTotalDebit, config.locale, config.numberFormat.currency),
	  		atTotalDebit: formatCurrency(item.atTotalDebit, config.locale, config.numberFormat.currency)});
	  		
	  //style="success";
	  //style="danger";
	  style="info";  	  		  
			
	return (<OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
					<span>{result}</span>
			</OverlayTrigger>)
	  
	  
  }

  const findLedgerStatements = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		API.findLedgerStatements(values).then(response => {
			//setLedgerStatements(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
  const getAccountStatementsSummary = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.getAccountStatementsSummary(values).then(response => {
			//setAccountStatementsSummary(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
  const getAccountingSummary = (values) => {
	
	let mergedParams = mergeQueryParams(ledgerStatements, values);
	
	return Promise.all([findLedgerStatements(mergedParams),getAccountStatementsSummary(mergedParams)])
		.then((responses) => {
			
			let compoundStatements = responses[0].list;
			
			let accountsReceivable = {};
			accountsReceivable.ledgerNo = "7XX";
			accountsReceivable.ledgerName = "Accounts Receivable (*)";
			accountsReceivable.accountType = "ASSET";
			accountsReceivable.active = true;
			accountsReceivable.ltTotalCredit = 0;
			accountsReceivable.ltTotalDebit = 0;
			accountsReceivable.atTotalCredit = 0;
			accountsReceivable.atTotalDebit = responses[1].totalAccountsReceivable;
			accountsReceivable.currentBalance = 0-responses[1].totalAccountsReceivable;
			
			
			let accountsPayable = {};
			accountsPayable.ledgerNo = "9XX";
			accountsPayable.ledgerName = "Accounts Payable (*)";
			accountsPayable.accountType = "LIABILITY";
			accountsPayable.active = true;
			accountsPayable.ltTotalCredit = 0;
			accountsPayable.ltTotalDebit = 0;
			accountsPayable.atTotalCredit = responses[1].totalAccountsPayable;
			accountsPayable.atTotalDebit = 0;
			accountsPayable.currentBalance = responses[1].totalAccountsPayable;
						
			compoundStatements.push(accountsReceivable);
			compoundStatements.push(accountsPayable);
			
			let compoundResponse = responses[0];
			compoundResponse.list = compoundStatements
				.filter(item => (item.ltTotalCredit || item.ltTotalDebit || item.atTotalCredit || item.atTotalDebit ))
				.sort((a,b) => {
				 return (a.accountType < b.accountType) ? -1 :  (a.accountType > b.accountType) ? 1 : 0;
				})
			
			setLedgerStatements(compoundResponse);
		})
		.catch(error => {			
			log.error("Error Loading Ledger and/or Account Statements: ", error.message);
			context.onError(error);
		});
	}
	
	/*new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageAccountTransactions(mergeQueryParams(pagedAccountTransactions, values)).then(response => {
			setPagedAccountTransactions(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});*/


	const displayInactive = (item) => { 
		return (item.active) ? "" : "inactive-row"	
  	}

   	if (isLoading) 
		return <Loader />
	
	if (!ledgerStatements) 
		return null;
		
	return (
		<CustomContainer>
			{/*isRefreshing ? <Loader show={isRefreshing}/> : null*/}

		{/*<AccountingSummaryToolbar
			i18nPrefix="accounting.summary.toolbar."
			//eventDate={eventDate}
			startDate={ledgerStatements.start}
			endDate={ledgerStatements.end}
			variant="outline-primary"
			onDateRangeChange={handleDateRangeChange}
		/>*/}
		
		<Row className="mb-3"><Col>
    		<LedgerStatementFilterForm
				i18nPrefix={"accounting.summary.form."}
				parameters={{ filter: "", filteredColumn: "" }}
				onError={context.onError}
				onSubmit={getAccountingSummary}
			/>
		</Col></Row>
		
		<DataTable
			i18nPrefix="accounting.summary.datatable."
			items={ledgerStatements.list.map( i => Object.assign({}, i, {"totalCredit": i, "totalDebit": i}))}
			columns={["ledgerNo", "ledgerName", "accountType", "totalDebit", "totalCredit", "currentBalance"]} 
			showTotalRowColumns={["currentBalance"]} 
			customDisplayColumns={[["totalCredit", handleDisplayTotalCredit], ["totalDebit", handleDisplayTotalDebit], ["currentBalance", "Currency"]]}
			customRowStyle={displayInactive}
			onError={context.onError}
			noItemsDisclaimer={true}
		/>
		
		</CustomContainer>
	);
}

export default AccountingSummaryReport;
